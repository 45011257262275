import styled from "styled-components";
import { Wrapper } from "@ax/components/FieldsBehavior/style";

export const EditorWrapper = styled.div<{ error: boolean | undefined; disabled?: boolean }>`
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  .fr-box.fr-basic {
    ol,
    ul {
      padding-inline-start: 20px;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    ol {
      list-style: decimal;
    }
    ul {
      list-style: disc;
    }
    p {
      ${(p) => p.theme.textStyle.fieldContent};
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
    p:first-child {
      margin-block-start: 0;
    }
    a {
      color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : `blue`)};
      text-decoration: underline;
    }
    h1 {
      font-size: 22px;
      font-weight: 800;
    }
    h2 {
      font-size: 20px;
      font-weight: 400;
    }
    h3 {
      font-size: 18px;
      font-weight: 700;
    }
    h4 {
      font-size: 17px;
      font-weight: 600;
    }
    .fr-toolbar {
      border-color: ${(p) =>
        p.error === true ? p.theme.color.error : p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.uiLine};
      border-radius: 4px 4px 0 0;
      z-index: 1;

      .fr-btn-grp {
        margin: 0 10px;
      }
    }
    .fr-second-toolbar {
      border-color: ${(p) =>
        p.error === true ? p.theme.color.error : p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.uiLine};
      border-radius: 0 0 4px 4px;
    }
    .fr-wrapper {
      border-left: 1px solid
        ${(p) =>
          p.error === true
            ? p.theme.color.error
            : p.disabled
            ? p.theme.color.interactiveDisabled
            : p.theme.color.uiLine};
      border-right: 1px solid
        ${(p) =>
          p.error === true
            ? p.theme.color.error
            : p.disabled
            ? p.theme.color.interactiveDisabled
            : p.theme.color.uiLine};
    }
    .fr-disabled {
      color: ${(p) => p.theme.color.interactiveDisabled};
    }
    ${Wrapper}:focus-within & {
      .fr-toolbar,
      .fr-second-toolbar {
        border-color: ${(p) =>
          p.error === true
            ? p.theme.color.error
            : p.disabled
            ? p.theme.color.interactiveDisabled
            : p.theme.color.interactive01};
      }
      .fr-wrapper {
        border-left: 1px solid
          ${(p) =>
            p.error === true
              ? p.theme.color.error
              : p.disabled
              ? p.theme.color.interactiveDisabled
              : p.theme.color.interactive01};
        border-right: 1px solid
          ${(p) =>
            p.error === true
              ? p.theme.color.error
              : p.disabled
              ? p.theme.color.interactiveDisabled
              : p.theme.color.interactive01};
      }
    }
  }
`;
