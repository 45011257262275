import React, { useEffect, useCallback, useState, useRef, useLayoutEffect } from "react";
import { connect } from "react-redux";

import { structuredDataActions } from "@ax/containers/StructuredData";
import { appActions } from "@ax/containers/App";
import { IRootState, IStructuredData, IStructuredDataContent, IGetStructuredDataParams, IDataPack } from "@ax/types";
import { useBulkSelection, useModal, usePermission, useToast } from "@ax/hooks";
import { MainWrapper, TableList, ErrorToast, Toast, EmptyState } from "@ax/components";

import { DeleteModal } from "./atoms";
import { getAllLangCategoriesIds } from "./utils";
import CategoryItem from "./CategoryItem";
import CategoryPanel from "./CategoryPanel";
import CategoryNav from "./CategoryNav";
import BulkHeader from "./BulkHeader";

import * as S from "./style";

const CategoriesList = (props: IProps): JSX.Element => {
  const {
    currentSiteID,
    currentStructuredData,
    currentDataContent,
    getStructuredDataContents,
    resetCategoryValues,
    lang,
    siteLanguages,
    globalLangs,
    setLanguage,
    totalItems,
    categories,
    setSelectedCategory,
    activatedDataPacks,
    deleteDataContent,
    restoreDataContent,
    setHistoryPush,
    resetCurrentData,
  } = props;

  const itemsPerPage = 50;
  const [page, setPage] = useState(1);
  const [isScrolling, setIsScrolling] = useState(false);
  const [deletedItem, setDeletedItem] = useState<number | number[] | null>(null);
  const [deleteAllVersions, setDeleteAllVersions] = useState(false);
  const { isVisible, toggleToast, setIsVisible } = useToast();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const tableRef = useRef<HTMLDivElement>(null);

  const allowedToCreateSiteCategory = usePermission("categories.createSiteTaxonomies");
  const allowedToCreateGlobalCategory = usePermission("global.globalData.createTaxonomies");
  const allowedToDeleteSiteCategory = usePermission("categories.deleteSiteTaxonomies");
  const allowedToDeleteGlobalCategory = usePermission("global.globalData.deleteTaxonomies");
  const allowedToDeleteTaxonomy = currentSiteID ? allowedToDeleteSiteCategory : allowedToDeleteGlobalCategory;
  const allowedToCreateTaxonomy = currentSiteID ? allowedToCreateSiteCategory : allowedToCreateGlobalCategory;

  const scope = currentSiteID ? "site" : "global";
  const currentCategories = categories[scope];

  const catIds = currentDataContent && currentDataContent.map((cat: any) => cat.id);

  const {
    resetBulkSelection,
    selectedItems,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
  } = useBulkSelection(catIds);

  const getParams = useCallback(() => {
    return {
      page,
      itemsPerPage,
      pagination: true,
      deleted: false,
      include_draft: false,
    };
  }, [page]);

  const getContents = useCallback(
    async (id: string) => {
      const params: any = getParams();
      params.dataID = id;
      setSelectedCategory(id, scope);
      await getStructuredDataContents(params, currentSiteID);
    },
    [getStructuredDataContents, currentSiteID, getParams, setSelectedCategory, scope]
  );

  useEffect(() => {
    currentStructuredData && getContents(currentStructuredData.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, currentStructuredData, page]);

  useLayoutEffect(() => {
    return () => {
      resetCurrentData();
    };
  }, []);

  const handleClick = (dataID: string) => {
    setSelectedCategory(dataID, scope);
  };

  const { isOpen, toggleModal } = useModal();

  const openModal = () => {
    resetCategoryValues();
    toggleModal();
  };

  const rightButtonProps = allowedToCreateTaxonomy
    ? {
        label: "New",
        action: openModal,
      }
    : undefined;

  const bulkDelete = async () => {
    const idsToBeDeleted = getAllLangCategoriesIds(currentDataContent, selectedItems, deleteAllVersions);
    const deleted = await deleteDataContent(idsToBeDeleted);
    if (deleted) {
      unselectAllItems();
      setDeletedItem(idsToBeDeleted);
      isDeleteOpen && toggleDeleteModal();
      toggleToast();
    }
  };

  const handleToggleDeleteModal = () => {
    const selectedCategories = currentDataContent.filter((category) => selectedItems.all.includes(category.id));
    const hasTranslations = selectedCategories.some((category) => category.dataLanguages.length > 1);
    hasTranslations ? toggleDeleteModal() : bulkDelete();
  };

  const unselectAllItems = () => resetBulkSelection();

  const selectItems = () => (checkState.isAllSelected ? unselectAllItems() : handleSelectAll());

  const handleSelectAll = () => selectAllItems();

  const onScroll = (e: any) => setIsScrolling(e.target.scrollTop > 0);

  const undoAction = () => {
    if (deletedItem) {
      restoreDataContent(deletedItem);
    }
    setIsVisible(false);
  };

  const toastProps = {
    action: () => undoAction(),
    setIsVisible,
    message: deletedItem ? `Categories deleted` : "",
  };

  const bulkActions = allowedToDeleteTaxonomy
    ? [
        {
          icon: "delete",
          text: "delete",
          action: handleToggleDeleteModal,
        },
      ]
    : [];

  const TableHeader = (
    <BulkHeader
      showBulk={areItemsSelected(catIds)}
      selectAllItems={handleSelectAll}
      totalItems={totalItems}
      selectItems={selectItems}
      checkState={checkState}
      isScrolling={isScrolling}
      bulkActions={bulkActions}
    />
  );

  const availableLanguages = currentSiteID ? siteLanguages : globalLangs;

  const isTranslatable = currentStructuredData ? currentStructuredData.translate : true;

  const languageActions = {
    setLanguage,
  };

  const pagination = {
    setPage,
    itemsPerPage,
    totalItems,
    currPage: page,
  };

  const isEmpty = currentDataContent && currentDataContent.length === 0;
  const hasCategories = currentCategories.length > 0;
  const categoryName = currentStructuredData && currentStructuredData.title;
  const emptyStateProps = {
    message: hasCategories
      ? `To start using ${categoryName} categories, create as many of them as yo need.`
      : "To start using categories in your site, you must active all Content type packages as you need.",
    button: hasCategories ? `Create ${categoryName} category` : "Activate Content type packages",
    action: hasCategories ? openModal : () => setHistoryPush("/sites/settings/content-types"),
  };

  const mainDeleteModalAction = {
    title: "Delete categories",
    onClick: bulkDelete,
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  return (
    <MainWrapper
      title="Categories"
      rightButton={rightButtonProps}
      language={lang}
      availableLanguages={availableLanguages}
      languageActions={languageActions}
    >
      <S.CategoryListWrapper>
        <CategoryNav
          current={currentStructuredData}
          categories={currentCategories}
          onClick={handleClick}
          dataPacks={activatedDataPacks}
          setSelectedCategory={setSelectedCategory}
          scope={scope}
        />
        <S.TableWrapper>
          <ErrorToast />
          <TableList
            tableHeader={TableHeader}
            pagination={pagination}
            onScroll={onScroll}
            hasFixedHeader={true}
            tableRef={tableRef}
          >
            {isEmpty ? (
              <S.EmptyWrapper>
                <EmptyState {...emptyStateProps} />
              </S.EmptyWrapper>
            ) : (
              currentDataContent.map((category: any) => {
                const isItemSelected = isSelected(category.id);
                return (
                  <CategoryItem
                    category={category}
                    key={category.id}
                    languages={availableLanguages}
                    lang={lang}
                    isTranslatable={isTranslatable}
                    isSelected={isItemSelected}
                    onChange={addToBulkSelection}
                    toggleToast={toggleToast}
                    setDeletedItem={setDeletedItem}
                    getContents={getContents}
                  />
                );
              })
            )}
          </TableList>
        </S.TableWrapper>
      </S.CategoryListWrapper>
      <CategoryPanel isOpen={isOpen} toggleModal={toggleModal} getContents={getContents} />
      {isDeleteOpen && <DeleteModal
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        mainModalAction={mainDeleteModalAction}
        secondaryModalAction={secondaryDeleteModalAction}
        {...{ deleteAllVersions, setDeleteAllVersions }}
      />}
      {isVisible && <Toast {...toastProps} />}
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  categories: state.structuredData.categories,
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  siteLanguages: state.sites.currentSiteLanguages,
  globalLangs: state.app.globalLangs,
  currentDataContent: state.structuredData.currentDataContent,
  currentStructuredData: state.structuredData.currentStructuredData,
  lang: state.app.lang,
  totalItems: state.sites.totalItems,
  activatedDataPacks: state.dataPacks.activated,
});

interface IDispatchProps {
  getStructuredDataContents(params: IGetStructuredDataParams, siteID: number | null): Promise<void>;
  resetCategoryValues(): void;
  setLanguage(lang: { locale: string; id: number | null }): void;
  setSelectedCategory(id: string, scope: string): void;
  deleteDataContent(catID: number[]): Promise<boolean>;
  restoreDataContent(catID: number | number[]): void;
  setHistoryPush(path: string): void;
  resetCurrentData(): Promise<void>;
}

interface ICategoriesProps {
  currentSiteID: number | null;
  currentDataContent: IStructuredDataContent[];
  currentStructuredData: IStructuredData | null;
  lang: { locale: string; id: number | null };
  siteLanguages: any[];
  globalLangs: any[];
  totalItems: number;
  categories: any;
  activatedDataPacks: IDataPack[];
}

type IProps = ICategoriesProps & IDispatchProps;

const mapDispatchToProps = {
  getStructuredDataContents: structuredDataActions.getStructuredDataContents,
  resetCategoryValues: structuredDataActions.resetCategoryValues,
  setLanguage: appActions.setLanguage,
  setSelectedCategory: structuredDataActions.setSelectedCategory,
  deleteDataContent: structuredDataActions.deleteStructuredDataContent,
  restoreDataContent: structuredDataActions.restoreStructuredDataContent,
  setHistoryPush: appActions.setHistoryPush,
  resetCurrentData: structuredDataActions.resetCurrentData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);
