import React from "react";

import { CheckField, TableCounter, NameFilter, PermissionsFilter, UsersFilter } from "@ax/components";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const { totalItems, selectAllItems, isScrolling, sortItems, sortedListStatus, isSiteView } = props;

  return (
    <S.TableHeader isScrolling={isScrolling} data-testid="roles-table-header">
      <S.CheckHeader>
        <CheckField
          key="selectAll"
          name="selectAll"
          value="selectAll"
          onChange={selectAllItems}
          checked={false}
          error={false}
        />
      </S.CheckHeader>
      <S.NameWrapper>
        <NameFilter sortItems={sortItems} sortedState={sortedListStatus} pointer="name" />
      </S.NameWrapper>
      {isSiteView && (
        <S.UsersWrapper>
          <UsersFilter sortItems={sortItems} sortedState={sortedListStatus} />
        </S.UsersWrapper>
      )}
      <S.PermissionsWrapper>
        <PermissionsFilter sortItems={sortItems} sortedState={sortedListStatus} />
      </S.PermissionsWrapper>

      <S.ActionsHeader isSiteView={isSiteView}>
        <TableCounter totalItems={totalItems} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  totalItems: number;
  isScrolling: boolean;
  selectAllItems: () => void;
  filterItems: (filterPointer: string, filtersSelected: string) => void;
  sortItems: any;
  sortedListStatus: any;
  filterValues: any;
  isSiteView: boolean;
}

export default TableHeader;
