import React, { useEffect, useState } from "react";

import { checkgroups } from "@ax/api";
import { isReqOk } from "@ax/helpers";
import CheckField from "@ax/components/Fields/CheckField";
import { ISite, ICheck } from "@ax/types";

import * as S from "./style";

const AsyncCheckGroup = (props: IAsyncCheckGroup): JSX.Element => {
  const { onChange, value, site, source, disabled, error, handleValidation, fullHeight, allLanguages = false } = props;

  const [options, setOptions] = useState<ICheckValue[]>([]);
  const safeValue = value && Array.isArray(value) ? value : [];

  const isCheckValue = (object: any): object is ICheckValue => "value" in object;

  useEffect((): any => {
    let isSubscribed = true;

    const getItems = async (): Promise<ICheckValue[]> => {
      try {
        let result = null;
        const siteID = site ? site.id : "global";
        result = await checkgroups.getCheckGroupItems(siteID, source, allLanguages);
        if (result && isReqOk(result.status)) {
          return result.data;
        }
      } catch (e) {
        console.log(e);
      }
      return [];
    };

    getItems()
      .then((result) => {
        if (isSubscribed) {
          setOptions(result);
          if (safeValue.length > 0 && isCheckValue(safeValue[0])) {
            // clean array of old values and set translations
            const resultIDs = result && result.map((opt: ICheckValue) => opt.value);
            const fixedState = safeValue.reduce((acc: ICheckValue[], current: ICheckValue) => {
              if (resultIDs.includes(current.value)) {
                return [...acc, current];
              } else {
                const trad = result.find(
                  (value: ICheckValue) =>
                    value.dataLanguages && value.dataLanguages.find((data: any) => data.id === current.value)
                );
                if (trad) {
                  return [...acc, trad];
                }
                return acc;
              }
            }, []);
            onChange(fixedState);
          }
        }
      })
      .catch((apiError) => console.log(apiError));

    return () => (isSubscribed = false);
  }, [site, source, allLanguages]);

  const handleChange = (newValue: ICheck) => {
    const arrayIds = safeValue.map((e: any) => (typeof e === "number" ? e : e.value));

    let newArray: number[] = [];
    const isInArray = arrayIds.find((e: any) => e === newValue.value);

    if (isInArray && !newValue.isChecked) {
      newArray = arrayIds.filter((e: any) => e !== newValue.value);
    }

    if (!isInArray && newValue.isChecked) {
      newArray = [...arrayIds, newValue.value];
    }

    const newArrayObject = options.filter((e: ICheckValue) => newArray.includes(e.value));

    onChange(newArrayObject);
    error && handleValidation && handleValidation(newArrayObject);
  };

  const isChecked = (id: number): boolean =>
    !!safeValue.find((e: ICheckValue | number) => (typeof e === "number" ? e === id : e.value === id));

  const checks =
    options &&
    options.map((item: any) => (
      <CheckField
        key={item.name}
        onChange={handleChange}
        checked={isChecked(item.value)}
        value={item.value}
        title={item.title}
        name={item.name}
        disabled={item.disabled || disabled}
        error={item.error}
      />
    ));

  return <S.FieldGroup full={fullHeight}>{checks}</S.FieldGroup>;
};

interface ICheckValue {
  value: number;
  name: string;
  title: string;
  dataLanguages?: { id: number; language: number }[];
}

export interface IAsyncCheckGroup {
  value: any[] | null;
  site?: ISite | null;
  source: string;
  onChange: (value: ICheckValue[]) => void;
  disabled?: boolean;
  error?: boolean;
  handleValidation?: (value: string | any[]) => void;
  validators?: Record<string, unknown>;
  fullHeight?: boolean;
  allLanguages?: boolean;
}

export default AsyncCheckGroup;
