import styled from "styled-components";

const BulkHeader = styled.div<{ isScrolling?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.m}`};
  border-bottom: ${(p) => (p.isScrolling ? `1px solid ${p.theme.color.uiLine};` : "")};
`;

const BulkActionsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const BulkActions = styled.div`
  display: flex;
  margin-top: 2px;
  min-height: 32px;
  button {
    margin-right: ${(p) => p.theme.spacing.xs};
  }
`;

const SelectAllCheckField = styled.span`
  width: ${(p) => p.theme.spacing.s};
  margin-right: ${(p) => p.theme.spacing.s};
  margin-left: ${(p) => p.theme.spacing.m};
`;

const Counter = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export { BulkHeader, BulkActionsWrapper, BulkActions, SelectAllCheckField, Counter };
