import React from "react";
import { IErrorItem } from "@ax/types";

import AppBar from "./AppBar";

import * as S from "./style";

const MainWrapper = (props: IWrapperProps): JSX.Element => {
  const { children, fixedAppBar, fullWidth, hasAnimation } = props;

  return (
    <S.Wrapper fixedAppBar={fixedAppBar} data-testid="main-wrapper">
      {hasAnimation && <S.BackgroundAnimation />}
      <AppBar {...props} />
      <S.Main fullWidth={fullWidth} data-testid="main-component" id="main-component">
        {children}
      </S.Main>
    </S.Wrapper>
  );
};

export interface IWrapperProps {
  children?: any[] | JSX.Element;
  backLink?: boolean | string;
  rightButton?: { label: string; disabled?: boolean; action: (e: any) => void };
  rightLineButton?: { label: string; disabled?: boolean; action: (e: any) => void };
  title: string;
  subtitle?: string;
  fixedAppBar?: boolean;
  additionalClass?: string;
  downArrowMenu?: { displayed: boolean; options: any; button: any };
  tabs?: { tabSet?: any; icons?: { name: string; text: string }[]; selectedTab: string; action: (e: any) => void };
  pageStatus?: string;
  language?: { locale: string; id: number | null } | null;
  availableLanguages?: any[] | null;
  pageLanguages?: any[];
  inversed?: boolean;
  currentPageID?: number;
  fullWidth?: boolean;
  isFromEditor?: boolean;
  languageActions?: {
    setLanguage?(lang: { locale: string; id: number | null }): void;
    setIsNewTranslation?(isNewTranslation: boolean): void;
    getContent?(id?: number): Promise<void>;
    getDataContent?(dataID?: number): Promise<void>;
  } | null;
  searchAction?(query: string): void;
  filterSearchAction?(filter: string): void;
  searchFilters?: any;
  errors?: IErrorItem[];
  errorActions?: {
    goToError(editorID: number, tab: string, template: boolean): void;
    goToPackage(): void;
  };
  pageStatusActions?: any[];
  hasAnimation?: boolean;
}

export default MainWrapper;
