import React from "react";

import { Icon, FloatingMenu, ListTitle, ListItem } from "@ax/components";

import * as S from "./style";

const GridHeaderFilter = ({ sortItems, sortedState }: IGridHeaderFilterProps): JSX.Element => {
  const { isAscending, sortedByTitle, sortedByLastAccess, sortedByDateCreated } = sortedState;

  const sortAscendingName = () => sortItems("name", true);
  const sortDescendingName = () => sortItems("name", false);
  const sortAscendingLastAccess = () => sortItems("lastAccess", true);
  const sortDescendingLastAccess = () => sortItems("lastAccess", false);
  const sortAscendingDateCreated = () => sortItems("dateCreated", true);
  const sortDescendingDateCreated = () => sortItems("dateCreated", false);

  const SortedStateArrow = () =>
    isAscending ? <Icon name="FullArrowUp" size="16" /> : <Icon name="FullArrowDown" size="16" />;

  const isActive = sortedByTitle || sortedByLastAccess;

  const getFilterLabel = () => {
    if (sortedByTitle) return "Name";
    if (sortedByLastAccess) return "Last access";
    if (sortedByDateCreated) return "Creation date";
  };

  const Button = () => (
    <S.FloatingMenuButton isActive={isActive} data-testid="grid-floating-menu-button">
      <S.Label data-testid="filter-label">{getFilterLabel()}</S.Label>
      <S.IconsWrapper>
        {isActive && <SortedStateArrow />}
        <S.InteractiveArrow>
          <Icon name="DownArrow" size="16" />
        </S.InteractiveArrow>
      </S.IconsWrapper>
    </S.FloatingMenuButton>
  );

  return (
    <FloatingMenu Button={Button} position="left">
      <ListTitle>Name Sorting</ListTitle>
      <ListItem isSelected={sortedByTitle && isAscending} onClick={sortAscendingName}>
        Ascendent
      </ListItem>
      <ListItem isSelected={sortedByTitle && !isAscending} onClick={sortDescendingName}>
        Descendent
      </ListItem>
      <ListTitle>Sort by access date</ListTitle>
      <ListItem isSelected={sortedByLastAccess && !isAscending} onClick={sortDescendingLastAccess}>
        Newest
      </ListItem>
      <ListItem isSelected={sortedByLastAccess && isAscending} onClick={sortAscendingLastAccess}>
        Oldest
      </ListItem>
      <ListTitle>Sort by creation</ListTitle>
      <ListItem isSelected={sortedByDateCreated && !isAscending} onClick={sortDescendingDateCreated}>
        Newest
      </ListItem>
      <ListItem isSelected={sortedByDateCreated && isAscending} onClick={sortAscendingDateCreated}>
        Oldest
      </ListItem>
    </FloatingMenu>
  );
};

export interface IGridHeaderFilterProps {
  sortedState: any;
  sortItems(orderPointer: string, isAscending: boolean): any;
}

export default GridHeaderFilter;
