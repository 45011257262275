import { useState } from "react";

const useSortedListStatus = () => {
  const sortedInitialState: { isAscending: boolean; sortedByDate: boolean; } = {
    isAscending: false,
    sortedByDate: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = (currentSiteID: number | null) => {
  const initialQueryValues = {
    sites: currentSiteID || "all",
    order: "",
  };

  const [query, setQuery] = useState(initialQueryValues);

  const setFilterQuery = (filterValues: any) => {
    const { sites, order } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: string) => {
      return filterQuery.concat(`&${pointer}=${values}`);
    };

    if (sites) {
      filterQuery = currentQuery("sites", sites);
    }

    if (order) {
      filterQuery = currentQuery("order", order);
    }

    return filterQuery;
  };

  const setFiltersSelection = (pointer: string, filter: string, isAscendent?: boolean) => {
    const { sites, order } = query;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues = {
      sites: pointer === "sites" ? filter : sites,
      order: pointer === "order" ? `${filter}-${orderMethod}` : order,
    };

    setQuery(filterValues);

    return filterValues;
  };

  const resetFilterQuery = () => setQuery(initialQueryValues);

  return {
    setFiltersSelection,
    setFilterQuery,
    resetFilterQuery,
    filterValues: query,
  };
};

export { useSortedListStatus, useFilterQuery };
