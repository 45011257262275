import { AxiosResponse } from "axios";
import { template } from "./config";
import { sendRequest, IServiceConfig } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_STRUCTURED_DATA: {
    ...template,
    endpoint: ["/site/", "/structured_data_contents"],
    method: "GET",
  },
  GET_LIVE_STATUS: {
    ...template,
    endpoint: "/live-status",
    method: "GET",
  },
};

const getCheckGroupItems = async (
  siteId: number | string | null,
  source: string,
  allLanguages?: boolean
): Promise<AxiosResponse<any>> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_STRUCTURED_DATA;

  const allLanguagesQuery = allLanguages ? "&allLanguages=true" : "";

  SERVICES.GET_STRUCTURED_DATA.dynamicUrl = `${host}${prefix}${siteId}${suffix}/${source}/checkgroup?order=title-ASC${allLanguagesQuery}`;

  return sendRequest(SERVICES.GET_STRUCTURED_DATA);
};

const getLiveStatusItems = async (): Promise<AxiosResponse<any>> => sendRequest(SERVICES.GET_LIVE_STATUS);

export default { getCheckGroupItems, getLiveStatusItems };
