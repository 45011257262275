import React from "react";
import { connect } from "react-redux";

import { structuredDataActions } from "@ax/containers/StructuredData";
import { IRootState, IStructuredData, IStructuredDataContent, ICategory, ILanguage } from "@ax/types";

import { Button, Flag, FloatingPanel } from "@ax/components";
import Form from "./Form";

import * as S from "./style";

const CategoryPanel = (props: IProps): JSX.Element => {
  const {
    isOpen,
    toggleModal,
    item,
    createStructuredDataContent,
    updateStructuredDataContent,
    getContents,
    currentStructuredData,
    category,
    entity,
    siteLang,
    languages,
  } = props;

  let newCategory: any = {
    structuredData: currentStructuredData ? currentStructuredData.id : null,
    relatedSite: item ? item.relatedSite : null,
    content: {
      title: category.name,
      code: category.code,
    },
    draft: false,
    entity: item && !category.isNew ? item.entity : entity,
    language: category.lang ? category.lang.id : siteLang.id,
  };

  const { lang } = category;

  if (item && item.id && !category.isNew) {
    newCategory = { ...newCategory, id: category.isTranslation ? category.id : item.id };
  }

  const addItemAction = async () => {
    const langID = lang && category.isTranslation ? lang.id : undefined;
    const isCreated = await createStructuredDataContent(newCategory, langID);

    if (isCreated && currentStructuredData) {
      getContents(currentStructuredData.id);
    }

    toggleModal();
  };

  const editItemAction = async () => {
    const isUpdated = await updateStructuredDataContent(newCategory);
    if (isUpdated && currentStructuredData) {
      getContents(currentStructuredData.id);
    }
    toggleModal();
  };

  const addButton = {
    label: "Add",
    action: addItemAction,
    disabled: false,
  };

  const editButton = {
    label: "Update",
    action: editItemAction,
    disabled: false,
  };

  const title = category.isNew ? "New Item" : "Update Item";

  const language = languages.find((l: ILanguage) => lang && l.locale === lang.locale);

  const flag =
    language && category.isTranslation ? (
      <>
        <S.FlagWrapper>
          <Flag name={language.locale} size="16" />
          <S.TranslationText>{`${language.language} translation of:`}</S.TranslationText>
        </S.FlagWrapper>
        <S.Translation>{newCategory.content.title}</S.Translation>
      </>
    ) : null;

  return (
    <FloatingPanel title={title} toggleModal={toggleModal} isOpen={isOpen}>
      <S.Wrapper>
        <div>{flag}</div>
        <Form edit={!category.isNew} />
        <S.Footer>
          {!category.isNew ? (
            <Button className="button" type="button" onClick={editButton.action} disabled={editButton.disabled}>
              {editButton.label}
            </Button>
          ) : (
            <Button className="button" type="button" onClick={addButton.action} disabled={addButton.disabled}>
              {addButton.label}
            </Button>
          )}
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentStructuredData: state.structuredData.currentStructuredData,
  category: state.structuredData.category,
  entity: state.structuredData.entity,
  siteLang: state.app.lang,
  languages: state.app.globalLangs,
});

interface IStateProps {
  currentStructuredData: IStructuredData | null;
  category: ICategory;
  entity: string | null;
  siteLang: any;
  languages: ILanguage[];
}

interface ICategoryPanelProps {
  item?: IStructuredDataContent;
  isOpen: boolean;
  toggleModal(): any;
  getContents(dataId: string): void;
}

interface IDispatchProps {
  createStructuredDataContent: (category: IStructuredDataContent, langId?: number | null) => Promise<boolean>;
  updateStructuredDataContent: (category: IStructuredDataContent) => Promise<boolean>;
}

type IProps = IDispatchProps & ICategoryPanelProps & IStateProps;

export default connect(mapStateToProps, {
  createStructuredDataContent: structuredDataActions.createStructuredDataContent,
  updateStructuredDataContent: structuredDataActions.updateStructuredDataContent,
})(CategoryPanel);
