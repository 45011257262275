import { GriddoImage, GriddoImageCommonProps } from "@griddo/core";
import React from "react";
import { createCloudinaryUrl, isCloudinary } from "./utils";

const DAM_DEFAULTS = {
  quality: 75,
  crop: "cover",
  loading: "lazy",
  formats: ["webp"],
};

const Image = (props: ICloudinaryImageProps | GriddoImageCommonProps) => {
  const { url } = props;

  if (!url) {
    return null;
  }

  if (isCloudinary(url)) {
    const cloudinaryProps = props as ICloudinaryImageProps;
    const cloudinaryUrl = createCloudinaryUrl({ props: cloudinaryProps });
    return <img src={cloudinaryUrl} data-testid="image-wrapper" />;
  } else {
    const griddoProps = { ...DAM_DEFAULTS, ...props } as GriddoImageCommonProps;
    return <GriddoImage {...griddoProps} />;
  }
};

export interface ICloudinaryImageProps {
  url: string;
  width: number;
  height?: number;
  quality?: number;
  alt?: string;
}

export default Image;
