import React, { useState } from "react";

import { CheckGroup, FloatingMenu, Icon, ListTitle } from "@ax/components";
import { areEquals, isNumber } from "@ax/helpers";

import * as S from "./style";

const TranslationsFilter = (props: ITranslationsFilterProps): JSX.Element => {
  const { filterItems, value } = props;

  const filters = [
    {
      name: "all",
      value: "all",
      title: "All",
    },
    {
      name: "no",
      value: "no",
      title: "Not translated content",
    },
  ];

  const storedValue = value.split(",");
  const parsedValue = storedValue.map((value) => (isNumber(value) ? parseInt(value) : value));
  const [initialState] = useState(parsedValue);
  const selectAllOption = "all";
  const [selectedValue, setSelectedValue] = useState([...initialState]);

  const setQuery = (selection: any) => {
    setSelectedValue(selection);
    const queryFilters = selection.join("&");
    const pointer = "translated";
    filterItems(pointer, queryFilters);
  };

  const isActive = !areEquals(selectedValue, selectAllOption);

  const Header = () => (
    <S.Translations isActive={isActive} data-testid="translation-filter-header">
      Trans.
      <S.IconsWrapper data-testid="translation-filter-icons-wrapper">
        {isActive ? <Icon name="Filter" size="16" /> : <Icon name="DownArrow" size="16" />}
      </S.IconsWrapper>
    </S.Translations>
  );

  return (
    <FloatingMenu Button={Header} position="left" closeOnSelect={true} isCheckGroup={true}>
      <ListTitle>Filter by translations</ListTitle>
      <S.ChecksWrapper data-testid="translation-filter-checks-wrapper">
        <CheckGroup options={filters} value={selectedValue} onChange={setQuery} multipleSelection={false} />
      </S.ChecksWrapper>
    </FloatingMenu>
  );
};

export interface ITranslationsFilterProps {
  filterItems(pointer: string, filter: string): void;
  value: string;
}

export default TranslationsFilter;
