import React from "react";
import { connect } from "react-redux";

import { IRootState } from "@ax/types";

import Style from "../../Style";
import { Loading } from "@ax/components";

import Routing from "./Routing";

const App = (props: IProps) => {
  const { isRehydrated } = props;

  return (
    <Style>
      {isRehydrated ? (
        <>
          <Routing />
        </>
      ) : (
        <Loading />
      )}
    </Style>
  );
};

function mapStateToProps(state: IRootState) {
  return {
    isRehydrated: state.app.isRehydrated,
  };
}

interface IProps {
  isRehydrated: boolean;
}

export default connect(mapStateToProps)(App);
