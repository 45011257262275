import styled from "styled-components";

import { Cell, Row } from "@ax/components/TableList/TableItem/style";
import { ActionMenu } from "@ax/components";

const CheckCell = styled(Cell)`
  padding-right: 0;
  padding-left: ${(p) => p.theme.spacing.m};
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
    padding-left: ${(p) => p.theme.spacing.s};
  }
`;

const NameCell = styled(Cell)`
  width: 35%;
  flex-grow: 1;
`;

const ActionsCell = styled(Cell)`
  width: 92px;
`;

export const ActionsWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.1s;
  text-align: right;
`;

const TransCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  width: 115px;
  align-items: center;
`;

const LiveCell = styled(Cell)`
  width: 80px;
  align-items: center;
  svg {
    width: ${(p) => p.theme.spacing.m};
    height: ${(p) => p.theme.spacing.m};
  }
`;

const StatusCell = styled(Cell)`
  width: 170px;
  align-items: center;
  div:nth-child(2) {
    margin-top: ${(p) => p.theme.spacing.xs};
  }
`;

const ModDate = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
`;

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const StructuredDataRow = styled(Row)<{ disabled: boolean }>`
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};
  &:hover {
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
  ${NameCell}, ${LiveCell}, ${StatusCell} {
    pointer-events: ${(p) => (p.disabled ? "none" : "auto")};
  }
`;

const FlagsWrapper = styled.div`
  svg {
    margin-right: 2px;
    margin-bottom: -3px;
  }
`;

export {
  CheckCell,
  NameCell,
  ActionsCell,
  StyledActionMenu,
  TransCell,
  LiveCell,
  StatusCell,
  ModDate,
  StructuredDataRow,
  FlagsWrapper,
};
