import React from "react";

import { IModal } from "@ax/types";
import { Modal, FieldsBehavior } from "@ax/components";

import * as S from "./style";

const DeleteModal = (props: IDeleteModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, deleteAllVersions, setDeleteAllVersions, title } =
    props;

  const options = [
    {
      title: "Delete only this version",
      name: "deletePage",
      value: false,
    },
    {
      title: "Delete all languages versions",
      name: "deleteAll",
      value: true,
    },
  ];

  const categoryTitle = title ? <strong>{title}</strong> : "this";

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete Category?"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
    >
      <S.ModalContent>
        <p>
          You are going to delete {categoryTitle} category that have some translations associated. Choose if you want to
          delete all languages versions or only this one.
        </p>
        <FieldsBehavior
          name="removeAllVersions"
          fieldType="RadioGroup"
          value={deleteAllVersions}
          options={options}
          onChange={setDeleteAllVersions}
        />
      </S.ModalContent>
    </Modal>
  );
};

interface IDeleteModal extends IModal {
  deleteAllVersions: boolean;
  setDeleteAllVersions: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
}

export { DeleteModal };
