export const NAME: string = "menu";

export const SET_ORIGINAL_MENUS: string = `${NAME}/SET_ORIGINAL_MENUS`;
export const SET_ORIGINAL_MENU: string = `${NAME}/SET_CURRENT_ORIGINAL_MENU`;
export const SET_EDITOR_MENU: string = `${NAME}/SET_EDITOR_MENU`;
export const SET_TYPE: string = `${NAME}/SET_MENU_TYPE`;
export const SET_ID: string = `${NAME}/SET_ID`;
export const SET_ITEM: string = `${NAME}/SET_ITEM`;
export const SET_MENU_FORM_DATA: string = `${NAME}/SET_MENU_FORM_DATA`;
export const SET_TOTAL_ITEMS: string = `${NAME}/SET_TOTAL_ITEMS`;
