import { getSchema, getDisplayName, getSchemaType, isComponentEmpty } from "@ax/helpers";
import { IComponent } from "@ax/types";

const getComponentTitle = (component: string, title: any) => {
  if (!title) return;
  const {
    displayName,
    default: { title: defaultTitle },
  } = getSchema(component);
  if (!defaultTitle) return displayName;
  const { content } = defaultTitle;
  if (content) return content === title.content ? displayName : title.content;
  return defaultTitle === title ? displayName : title;
};

const getComponentProps = (element: any, activatedModules: string[], isModuleArr: boolean) => {
  const { component } = element;
  const schemaType = getSchemaType(component);
  const displayName = getDisplayName(component);
  const title = getComponentTitle(component, element.title);
  const isModule = schemaType === "module";
  const moduleTitle = isModule && displayName !== title && title;
  const componentTitle = !isModule && displayName !== title && title;
  const isModuleDeactivated = isModuleArr && activatedModules && !activatedModules.includes(component);

  return { moduleTitle, isModuleDeactivated, componentTitle, displayName, isModule };
};

const containerToComponentArray = (value?: Record<string, IComponent>): IComponent[] =>
  value ? Object.values(value).filter((item: IComponent) => !isComponentEmpty(item)): [];

const getTypefromKey = (key: string) => {
  switch (key) {
    case "elements":
    case "componentModules":
      return "components";
    case "modules":
      return key;
    default:
      return null;
  }
};

export { getComponentTitle, getComponentProps, containerToComponentArray, getTypefromKey };
