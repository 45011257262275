import { getDefaultSchema, camelize, isSelectedEditorID, deepClone } from "@ax/helpers";
import { findByEditorID } from "./editor";

const updateElementCollection = (elementType: string, prevCollection: any[]) => {
  const newElement = getDefaultSchema(elementType);
  return [...prevCollection, newElement];
};

const addElement = (componentType: any) => {
  const defaultSchema = getDefaultSchema(componentType.component);
  for (const key in defaultSchema) {
    componentType[key] = defaultSchema[key];
  }
};

const updateComponent = (component: any, schema: any) => {
  for (const key in schema) {
    if (!(key in component)) {
      const defaultVal = schema[key] && typeof schema[key] === "object" ? { ...schema[key] } : schema[key];
      component[key] = defaultVal;
    }
  }
};

const updateCollection = (type: string, item: any, key: string) =>
  (item[key] = updateElementCollection(type, item[key]));

const getUpdatedComponents = (sections: any, component: any, key: string) => {
  const { editorID, type } = component;

  const isCollectionItem = typeof type === "string";
  let selectedIndex = 0;

  const addItem = (item: any) => (isCollectionItem ? updateCollection(type, item, key) : addElement(type));

  const sectionEntries = sections.map((section: any, index: number) => {
    const updatedModules = section.modules.map((module: any) => {
      const mapValues = (item: any) => {
        if (item.editorID && item.editorID === editorID) {
          addItem(item);
          selectedIndex = index;
        }
        const itemKeys = Object.keys(item);
        itemKeys.forEach((key: string) => {
          const containedValue = item[key];
          if (containedValue && typeof containedValue === "object") {
            mapValues(containedValue);
          }
        });
      };
      mapValues(module);
      return module;
    });

    const sectionKey = camelize(section.name);
    section.modules = [...updatedModules];

    return [sectionKey, section];
  });
  const updatedSections = Object.fromEntries(sectionEntries);

  return {
    updatedSections,
    selectedIndex,
  };
};

const getUpdatedSections = (sections: any[], id: number, moduleType: string) => {
  let selectedIndex = 0;
  const sectionEntries = sections.map((section: any, index: number) => {
    const { modules, key } = section;
    if (isSelectedEditorID(section, id)) {
      selectedIndex = index;
      const newModule = getDefaultSchema(moduleType);
      const updatedModules = [...modules, newModule];
      section.modules = [...updatedModules];
    }

    return [key, section];
  });
  const updatedSections = Object.fromEntries(sectionEntries);

  return {
    updatedSections,
    selectedIndex,
  };
};

const deleteComponent = (elements: any, editorID: number) => {
  const isArray = elements.length || elements.length === 0;
  if (isArray) {
    elements = elements.filter((element: any) => {
      if (element.elements) {
        element.elements = deleteComponent(element.elements, editorID);
      }
      return !isSelectedEditorID(element, editorID);
    });
  } else {
    Object.keys(elements).forEach((objKey: any) => {
      const element = elements[objKey];
      if (isSelectedEditorID(element, editorID)) {
        delete elements[objKey];
      } else if (element.elements) {
        element.elements = element.elements.filter((el: any) => !isSelectedEditorID(el, editorID));
      }
    });
  }

  return elements;
};

function deleteModules(sections: any, editorID: number) {
  return [...sections].map((section: any) => {
    section.modules = section.modules.filter((module: any) => {
      if (module.elements) {
        module.elements.forEach((element: any) => {
          return element.componentModules
            ? (element.componentModules = deleteComponent(element.componentModules, editorID))
            : null;
        });

        module.elements = deleteComponent(module.elements, editorID);
      }
      return module.editorID !== editorID;
    });
    return section;
  });
}

const updateByEditorID = (content: any, editorID: number, contentKey: string, value: any): any => {
  const isContent = !content || typeof content !== "object";

  if (isContent) return content;

  if (content.editorID === editorID) {
    const [parentKey, childKey] = contentKey.split(".");

    if (childKey) {
      content[parentKey][childKey] = value;
    } else {
      content[parentKey] = value;
    }
  }
  for (const key in content) {
    if (Object.prototype.hasOwnProperty.call(content, key)) {
      content[key] = updateByEditorID(content[key], editorID, contentKey, value);
    }
  }

  return content;
};

const moveElement = (elementID: number, arr: any[], newIndex: number, idKey = "editorID") => {
  const arrCopy = [...arr];
  if (arrCopy.length <= 1) return arrCopy;
  const elementIndex = arrCopy.findIndex((el: any) => el[idKey] === elementID);
  const element = { ...arrCopy[elementIndex] };
  arrCopy.splice(elementIndex, 1);
  arrCopy.splice(newIndex, 0, element);

  return arrCopy;
};

const moveModule = (params: IMoveElementParams) => {
  const { elementID, content, selectedContent, newIndex, page, key } = params;
  const isPage = ["Page", "GlobalPage"].includes(selectedContent.component);
  let newContent;
  if (isPage) {
    const { modules } = content;
    const newModules = moveElement(elementID, modules, newIndex);
    const { template } = selectedContent;
    const selectedSection = Object.keys(template).find(
      (key: string) => template[key] && template[key].editorID === content.editorID
    );
    newContent = selectedSection
      ? {
          ...selectedContent,
          template: {
            ...selectedContent.template,
            [selectedSection]: {
              ...deepClone(content),
              modules: newModules,
            },
          },
        }
      : null;
  } else {
    const contentElements = content[key];
    const { template } = page;
    const { element: selectedModule } = findByEditorID(page, selectedContent.editorID);
    selectedModule[key] = moveElement(elementID, contentElements, newIndex);

    newContent = {
      ...page,
      template: {
        ...template,
      },
    };
  }
  return newContent;
};

function replaceElements(elements: any, elemType: string): any {
  const defaultSchema = getDefaultSchema(elemType);
  return elements.map((ele: any) => {
    let newElement = {};
    Object.keys(defaultSchema).forEach((key: string) => {
      const value = ele[key] ? ele[key] : defaultSchema[key];
      newElement = { ...newElement, [key]: value };
    });
    return { ...newElement, component: elemType };
  });
}

interface IMoveElementParams {
  elementID: number;
  content: any;
  newIndex: number;
  selectedContent: any;
  page: any;
  key: string;
}

export {
  getUpdatedComponents,
  getUpdatedSections,
  updateComponent,
  deleteModules,
  updateByEditorID,
  moveModule,
  moveElement,
  deleteComponent,
  replaceElements,
};
