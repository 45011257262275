import styled from "styled-components";

const FieldWrapper = styled.div<{ error?: boolean; disabled?: boolean }>`
  display: flex;
  height: ${(p) => p.theme.spacing.l};
  max-width: ${(p) => `calc(8 * ${p.theme.spacing.l})`};
  min-width: ${(p) => `calc(5 * ${p.theme.spacing.l})`};
  width: 100%;
  border: 1px solid
    ${(p) => (p.error ? p.theme.color.error : p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.uiLine)};
  border-radius: ${(p) => p.theme.radii.s};
  background: ${(p) => p.theme.color.uiBackground02};
  padding: ${(p) => `0 ${p.theme.spacing.s}`};
  align-items: center;

  &:focus-within {
    border-color: ${(p) => (p.error ? p.theme.color.error : p.theme.color.interactive01)};
  }
`;

const IconWrapper = styled.div`
  width: ${(p) => p.theme.spacing.m};
  height: ${(p) => p.theme.spacing.m};
  margin-left: auto;
  svg {
    path {
      fill: ${(p) => p.theme.color.interactive01};
    }
  }
`;

const SelectWrapper = styled.div`
  padding-top: 4px;
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

export { FieldWrapper, IconWrapper, SelectWrapper };
