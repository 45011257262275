import React from "react";
import { connect } from "react-redux";

import { IErrorItem, IRootState } from "@ax/types";
import { structuredDataActions } from "@ax/containers/StructuredData";
import { FieldsBehavior } from "@ax/components";
import { getStructuredDataInnerFields } from "@ax/forms";

import * as S from "./style";

const ConnectedField = (props: IProps) => {
  const { field, site, form, fieldKey, updateFormValue, disabled, errors, deleteError, theme, lang } = props;

  const value = form?.content && form.content[fieldKey];
  const error = errors.find((err: any) => err.key === field.key);

  const handleChange = (newValue: any) => updateFormValue({ [fieldKey]: newValue });

  const isConditional = field.type === "ConditionalField";
  const isArrayGroup = field.type === "ArrayFieldGroup";

  let innerFields: JSX.Element[] = [];

  if (isConditional || isArrayGroup) {
    innerFields = getStructuredDataInnerFields(field.fields, form?.content, updateFormValue, theme, errors);
  }

  const fieldProps = {
    objKey: field.key,
    value,
    onChange: handleChange,
    site,
    disabled,
    fieldType: field.type,
    ...field,
    innerFields,
    error,
    deleteError,
    theme,
    delayed: false,
    lang: lang.id,
  };

  return (
    <S.Wrapper>
      <FieldsBehavior {...fieldProps} />
    </S.Wrapper>
  );
};

interface IProps {
  field: any;
  form: any;
  site: any;
  fieldKey: string;
  disabled: boolean;
  errors: IErrorItem[];
  updateFormValue: (value: any) => void;
  deleteError: (error: IErrorItem) => void;
  theme: string;
  lang: { locale: string; id: number };
}

const mapStateToProps = (state: IRootState) => ({
  form: state.structuredData.form,
  errors: state.structuredData.errors,
  lang: state.app.lang,
});

const mapDispatchToProps = {
  updateFormValue: structuredDataActions.updateFormValue,
  deleteError: structuredDataActions.deleteError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedField);
