import React, { useEffect, useCallback, memo } from "react";
import { connect } from "react-redux";

import { IGetSitePagesParams, IRootState, IMenuItem } from "@ax/types";

import { sitesActions } from "@ax/containers/Sites";
import { menuActions } from "@ax/containers/Navigation";
import { useShouldBeSaved } from "./helpers";
import { setIsSavedData } from "@ax/forms";

import Nav from "./Nav";
import Table from "./Table";
import * as S from "./style";

const List = (props: IMenuList): JSX.Element => {
  const { getMenus, lang, getSitePages, currentSiteID, editorMenu, savedMenu, currentType } = props;

  if (!currentSiteID) {
    throw new Error(`ERROR: User reached Menu List with null site info`);
  }

  const { isDirty } = useShouldBeSaved(editorMenu, savedMenu, currentType);
  const memoizedGetMenus = useCallback(() => getMenus(), [getMenus]);
  const memoizedGetSitePages = useCallback(
    (params: IGetSitePagesParams) => {
      getSitePages(params);
    },
    [getSitePages]
  );

  useEffect(() => {
    const params = {
      siteID: currentSiteID,
      deleted: false,
      page: 1,
      itemsPerPage: 50,
    };
    memoizedGetSitePages(params);
    memoizedGetMenus();
    setIsSavedData(true);
  }, [memoizedGetMenus, memoizedGetSitePages, lang.id, currentSiteID]);

  return (
    <S.ListWrapper>
      <Nav isDirty={isDirty} />
      <S.TableWrapper>
        <Table isDirty={isDirty} />
      </S.TableWrapper>
    </S.ListWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  lang: state.app.lang,
  currentType: state.menu.type,
  editorMenu: state.menu.editorMenu.elements,
  savedMenu: state.menu.savedMenu.elements,
});

const mapDispatchToProps = {
  getMenus: menuActions.getMenus,
  getSitePages: sitesActions.getSitePages,
};

interface IStateProps {
  currentSiteID: number | null;
  lang: { locale: string; id: number | null };
  currentType: string;
  editorMenu: IMenuItem[] | undefined;
  savedMenu: IMenuItem[] | undefined;
}

interface IDispatchProps {
  getMenus(): void;
  getSitePages(params: IGetSitePagesParams): any;
}

type IMenuList = IStateProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(memo(List));
