import React, { useState } from "react";

import { connect } from "react-redux";
import { menuActions } from "@ax/containers/Navigation";

import { FloatingPanel, Button } from "@ax/components";

import Form from "./Form";

import { IRootState, IMenuItem, IMenuForm } from "@ax/types";
import * as S from "./style";

const SidePanel = (props: IProps): JSX.Element => {
  const { isOpen, isOpenedSecond, toggleModal, toggleSecondaryPanel, addMenuItem, updateMenuItem, item, edit, form } =
    props;

  const { type, headerStyle, footerStyle } = form;

  const [isGalleryOpened, setIsGalleryOpened] = useState(false);

  const setIsOpenedGallery = () => {
    setIsGalleryOpened(!isGalleryOpened);
  };

  let menuItem: any = {
    ...form,
    component: "Menu",
    config: { type, headerStyle, footerStyle },
  };

  menuItem.children = edit && item ? item.children : [];

  if (edit && item && item.id) {
    menuItem = { ...menuItem, id: item.id };
  }

  const addItemAction = () => {
    addMenuItem(menuItem);
    toggleModal();
  };

  const editItemAction = () => {
    menuItem = { ...menuItem, editorID: item && item.editorID };
    updateMenuItem(menuItem);
    toggleModal();
  };

  const addButton = {
    label: "Add",
    action: addItemAction,
    disabled: false,
  };

  const editButton = {
    label: "Update",
    action: editItemAction,
    disabled: false,
  };

  const title = edit ? "Update Item" : "New Item";

  return (
    <FloatingPanel
      title={title}
      toggleModal={toggleModal}
      isOpen={isOpen}
      isOpenedSecond={isOpenedSecond}
      closeOnOutsideClick={!isGalleryOpened}
      handlePanel={toggleSecondaryPanel}
    >
      <S.Wrapper>
        <S.FormWrapper>
          <Form toggleSecondaryPanel={toggleSecondaryPanel} setIsGalleryOpened={setIsOpenedGallery} />
        </S.FormWrapper>
        <S.Footer>
          <Button
            className="button"
            type="button"
            onClick={edit ? editButton.action : addButton.action}
            disabled={edit ? editButton.disabled : addButton.disabled}
          >
            {edit ? editButton.label : addButton.label}
          </Button>
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

const mapStateToProps = (state: IRootState) => ({
  form: state.menu.form,
  item: state.menu.item,
  type: state.menu.type,
});

const mapDispatchToProps = {
  addMenuItem: menuActions.addMenuItem,
  updateMenuItem: menuActions.updateMenuItem,
};

interface IStateProps {
  form: IMenuForm;
  type: string;
  item: IMenuItem | null;
}
interface ISidePanelProps {
  edit?: boolean;
  isOpen: boolean;
  isOpenedSecond: boolean;
  toggleSecondaryPanel: () => void;
  toggleModal: () => void;
}

interface IDispatchProps {
  addMenuItem(menuItem: any): void;
  updateMenuItem(menuItem: any): void;
}

type IProps = IDispatchProps & ISidePanelProps & IStateProps;

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
