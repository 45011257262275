import { IBreadcrumbItem, INavPages, ISchema } from "@ax/types";

import {
  SET_EDITOR_CONTENT,
  SET_BREADCRUMB,
  SET_SCHEMA,
  SET_SELECTED_CONTENT,
  SET_SELECTED_DEFAULT,
  SET_TAB,
  SET_SELECTED_EDITOR_ID,
  SET_HEADER,
  SET_FOOTER,
  SET_DEFAULTS_CONTENT,
  SET_TOTAL_ITEMS,
  SET_CURRENT_NAVIGATION_LANGUAGES,
  SET_IS_NEW_TRANSLATION,
  SET_SELECTED_PARENT,
  SET_COPY_MODULE,
  SET_NAV_PAGES,
} from "./constants";
import { NavigationActionsCreators } from "./interfaces";

export interface INavigationState {
  editorContent: any | undefined;
  breadcrumb: IBreadcrumbItem[];
  schema: ISchema | Record<string, unknown>;
  selectedContent: any;
  selectedDefault: string;
  selectedParent: Record<string, unknown> | null;
  tab: string;
  selectedEditorID: number | undefined;
  header: number | null;
  footer: number | null;
  currentDefaultsContent: any;
  totalItems: number;
  currentNavigationLanguages: any[];
  isNewTranslation: boolean;
  form: any;
  moduleCopy: { date: string; element: Record<string, unknown> } | null;
  navigationPages: INavPages | null;
}

export const initialState = {
  editorContent: undefined,
  breadcrumb: [],
  schema: {},
  selectedContent: {},
  selectedDefault: "Headers",
  selectedParent: null,
  tab: "content",
  selectedEditorID: 0,
  header: null,
  footer: null,
  currentDefaultsContent: [],
  totalItems: 0,
  currentNavigationLanguages: [],
  isNewTranslation: false,
  form: {},
  moduleCopy: null,
  navigationPages: null,
};

export function reducer(state = initialState, action: NavigationActionsCreators): INavigationState {
  switch (action.type) {
    case SET_EDITOR_CONTENT:
    case SET_BREADCRUMB:
    case SET_SCHEMA:
    case SET_SELECTED_CONTENT:
    case SET_SELECTED_DEFAULT:
    case SET_SELECTED_PARENT:
    case SET_TAB:
    case SET_SELECTED_EDITOR_ID:
    case SET_HEADER:
    case SET_FOOTER:
    case SET_DEFAULTS_CONTENT:
    case SET_TOTAL_ITEMS:
    case SET_CURRENT_NAVIGATION_LANGUAGES:
    case SET_IS_NEW_TRANSLATION:
    case SET_COPY_MODULE:
    case SET_NAV_PAGES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as navigationInitialState, reducer as navigationReducer };
