import styled from "styled-components";
import { Wrapper } from "@ax/components/FieldsBehavior/style";

const FieldWrapper = styled.div<{ error?: boolean; preview: boolean; disabled?: boolean; fullWidth?: boolean }>`
  position: relative;
  display: ${(p) => (p.preview ? `none` : `flex`)};
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  min-height: ${(p) => `calc(${p.theme.spacing.l} * 2)`};
  max-width: ${(p) => (!p.fullWidth ? `calc(${p.theme.spacing.xl} * 5) ` : "100%")};
  width: 100%;
  background-color: ${(p) => p.theme.colors.uiBackground02};
  border: ${(p) =>
    `1px solid ${
      p.error ? p.theme.colors.error : p.disabled ? p.theme.color.interactiveDisabled : p.theme.colors.uiLine
    }`};
  border-radius: 4px;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.interactive01)};
  &:hover {
    background-color: ${(p) => (p.disabled ? p.theme.colors.uiBackground02 : p.theme.colors.overlayHoverPrimary)};
    cursor: ${(p) => (p.disabled ? `default` : `pointer`)};
  }
  ${Wrapper}:focus-within & {
    border-color: ${(p) => (p.error === true ? p.theme.color.error : p.theme.color.interactive01)};
  }
  svg {
    path {
      fill: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.interactive01)};
    }
  }
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  line-height: 0;
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
`;

const PreviewActions = styled.div`
  display: none;
  justify-content: flex-end;
  position: absolute;
  bottom: ${(p) => p.theme.spacing.xs};
  right: ${(p) => p.theme.spacing.xs};
  background-color: #ffffff;
  padding: ${(p) => `${p.theme.spacing.xxs} ${p.theme.spacing.xs}`};
  z-index: 3;
  border-radius: 4px;
  transition: all 0.3s ease-out;
  button:last-child {
    margin-left: ${(p) => p.theme.spacing.xs};
  }
`;

const PositionTitle = styled.div`
  display: flex;
  ${(p) => p.theme.textStyle.uiButton};
  color: ${(p) => p.theme.color.interactive01};
  align-items: center;
  span {
    margin-left: 4px;
  }
`;

const PositionGrid = styled.div`
  display: none;
  gap: ${(p) => p.theme.spacing.xxs};
  grid-template-columns: repeat(3, 1fr);
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const Position = styled.div<{active?: boolean}>`
  width: ${(p) => p.theme.spacing.m};
  height: ${(p) => p.theme.spacing.m};
  border-radius: ${(p) => p.theme.radii.xs};
  background-color: ${(p) => p.active ? p.theme.color.interactive01 : p.theme.color.uiBackground03};
  :hover {
    background-color: ${(p) => p.active ? p.theme.color.interactive01 : p.theme.color.overlayPressedPrimary};
  }
`;

const PositionWrapper = styled.div`
  position: absolute;
  display: none;
  top: ${(p) => p.theme.spacing.xs};
  right: ${(p) => p.theme.spacing.xs};
  background-color: ${(p) => p.theme.color.uiBarBackground};
  padding: ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radii.s};
  flex-direction: column;
  :hover {
    ${PositionGrid} {
      display: grid;
    }
  }
`;

const Preview = styled.div<{ preview: boolean }>`
  position: relative;
  min-height: ${(p) => `calc(${p.theme.spacing.l} * 2)`};
  max-width: ${(p) => `calc(${p.theme.spacing.xl} * 5)`};
  width: 100%;
  border: 1px solid ${(p) => p.theme.colors.uiLine};
  border-radius: 4px;
  display: ${(p) => (p.preview ? `block` : `none`)};
  overflow: hidden;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  :hover {
    cursor: pointer;
    ${PreviewActions} {
      display: flex;
    }
    ${PositionWrapper} {
      display: flex;
    }
  }
  img {
    border-radius: 4px;
  }
`;

const ImageDataWrapper = styled.div`
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
  max-width: ${(p) => `calc(${p.theme.spacing.xl} * 5)`};
`;

const FileName = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const ImageData = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
`;

export {
  FieldWrapper,
  Input,
  IconWrapper,
  Text,
  PreviewActions,
  Preview,
  ImageDataWrapper,
  FileName,
  ImageData,
  PositionWrapper,
  PositionTitle,
  PositionGrid,
  Position,
};
