import { useState } from "react";
import { IRolesQueryValues } from "@ax/types";

const useSortedListStatus = (): any => {
  const sortedInitialState: {
    isAscending: boolean;
    sortedByTitle: boolean;
    sortedByUsers: boolean;
    sortedByPermissions: boolean;
  } = {
    isAscending: false,
    sortedByTitle: false,
    sortedByUsers: false,
    sortedByPermissions: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = (): IRolesFilterQuery => {
  const initialQueryValues = {
    order: "",
  };

  const [query, setQuery] = useState(initialQueryValues);

  const setFilterQuery = (filterValues: IRolesQueryValues) => {
    const { order } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: string) => {
      return filterQuery ? filterQuery.concat(`&${pointer}=${values}`) : `?${pointer}=${values}`;
    };

    const isNotInitialValue = (pointer: keyof IRolesQueryValues) => {
      return filterValues[pointer] && initialQueryValues[pointer] !== filterValues[pointer];
    };

    if (isNotInitialValue("order")) filterQuery = currentQuery("order", order);

    return filterQuery;
  };

  const setFiltersSelection = (pointer: string, filter: string, isAscendent?: boolean) => {
    const { order } = query;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues = {
      order: pointer === "order" ? `${filter}-${orderMethod}` : order,
    };

    setQuery(filterValues);

    return filterValues;
  };

  const resetFilterQuery = () => setQuery(initialQueryValues);

  return {
    setFiltersSelection,
    setFilterQuery,
    resetFilterQuery,
    filterValues: query,
  };
};

interface IRolesFilterQuery {
  setFiltersSelection(pointer: string, filter: string, isAscendent?: boolean): IRolesQueryValues;
  setFilterQuery(filterValues: IRolesQueryValues): string;
  resetFilterQuery(): void;
  filterValues: IRolesQueryValues;
}

export { useSortedListStatus, useFilterQuery };
