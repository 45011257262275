import React, { useState } from "react";
import { CheckField, Tooltip } from "@ax/components";
import { IActionMenuOption, ICheck, IFile } from "@ax/types";
import { formatBytes, getFormattedDateWithTimezone, trimText } from "@ax/helpers";
import { useModal } from "@ax/hooks";

import { getFileIcon } from "../helpers";
import { DeleteFileModal, MoveItemModal } from "../atoms";

import * as S from "./style";

const GridItem = (props: IProps) => {
  const {
    file,
    isSelected,
    currentFolderID,
    isAllowedToDelete,
    isAllowedToEdit,
    isAllowedToMove,
    onChange,
    onClick,
    onDelete,
    onMove,
  } = props;
  const { id, fileName, fileType, sizeBytes, uploadDate, folder } = file;

  const [selectedFolder, setSelectedFolder] = useState<number>(currentFolderID || 0);
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isMoveOpen, toggleModal: toggleMoveModal } = useModal();

  const handleChange = (value: ICheck) => onChange(value);
  const handleClick = () => onClick(file);
  const handleCheckClick = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  let menuOptions: IActionMenuOption[] = [];

  if (isAllowedToEdit) {
    menuOptions = [
      {
        label: "Edit",
        icon: "edit",
        action: () => onClick(file),
      },
    ];

    if (isAllowedToMove) {
      menuOptions = [
        ...menuOptions,
        {
          label: "Move to...",
          icon: "change",
          action: () => toggleMoveModal(),
        },
      ];
    }
  }

  if (isAllowedToDelete) {
    menuOptions = [
      ...menuOptions,
      {
        label: "Delete",
        icon: "delete",
        action: () => toggleDeleteModal(),
      },
    ];
  }

  const iconUrl = `/img/icons/${getFileIcon(fileType)}`;

  const handleDeleteFile = () => {
    onDelete(id);
    isDeleteOpen && toggleDeleteModal();
  };

  const handleMoveFile = () => {
    if(folder !== selectedFolder) {
      onMove(id, selectedFolder);
      isMoveOpen && toggleMoveModal();
      setSelectedFolder(0);
    }
  };

  const handleModalClose = () => {
    setSelectedFolder(0);
    isMoveOpen && toggleMoveModal();
  };

  const mainDeleteModalAction = {
    title: "Delete document",
    onClick: () => handleDeleteFile(),
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const mainMoveModalAction = {
    title: "Move",
    onClick: () => handleMoveFile(),
  };

  const secondaryMoveModalAction = { title: "Cancel", onClick: handleModalClose };

  const FileTitle = () =>
    fileName.length > 40 ? (
      <Tooltip content={fileName}>
        <S.Name>{trimText(fileName, 40)}</S.Name>
      </Tooltip>
    ) : (
      <S.Name>{fileName}</S.Name>
    );

  return (
    <>
      <S.Wrapper onClick={handleClick}>
        <S.Header>
          <S.CheckWrapper onClick={handleCheckClick}>
            <CheckField name="check" value={id} checked={isSelected} onChange={handleChange} />
          </S.CheckWrapper>
          <S.IconWrapper>
            <img src={iconUrl} alt={`${fileType} Icon`} />
          </S.IconWrapper>
          <S.StyledActionMenu icon="more" options={menuOptions} tooltip="File actions" />
        </S.Header>
        <FileTitle />
        <S.Info>
          <S.Tag>{getFormattedDateWithTimezone(uploadDate, "d MMM Y")}</S.Tag>
          <S.Tag>{formatBytes(sizeBytes)}</S.Tag>
        </S.Info>
      </S.Wrapper>
      {isDeleteOpen && (
        <DeleteFileModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
          title={fileName}
        />
      )}
      {isMoveOpen && (
        <MoveItemModal
          isOpen={isMoveOpen}
          toggleModal={handleModalClose}
          mainModalAction={mainMoveModalAction}
          secondaryModalAction={secondaryMoveModalAction}
          folder={selectedFolder}
          setFolder={setSelectedFolder}
          hideRoot={!folder}
        />
      )}
    </>
  );
};

interface IProps {
  file: IFile;
  isSelected: boolean;
  currentFolderID: number | null;
  isAllowedToEdit: boolean;
  isAllowedToDelete: boolean;
  isAllowedToMove: boolean;
  onChange(e: ICheck): void;
  onClick(file: IFile): void;
  onDelete(fileID: number): void;
  onMove(fileID: number, folderID: number): void;
}

export default GridItem;
