import React, { useState } from "react";
import { IFile } from "@ax/types";
import { Button, FloatingMenu, IconAction, Toast, Tooltip } from "@ax/components";
import { useToast } from "@ax/hooks";
import FileDragAndDrop from "../FileDragAndDrop";
import { getFileIcon } from "../helpers";
import DetailPanel from "./DetailPanel";

import * as S from "./style";

const FileModal = (props: IProps) => {
  const { file, items, activeDelete, isAllowedToEdit, setFile, toggleModal, onDelete, setFileSelected } = props;
  const { fileType, fileName, id, site } = file;

  const [replaceType, setReplaceType] = useState<"full" | "partial" | null>(null);
  const [isDNDVisible, setDNDVisible] = useState(false);
  const { isVisible, toggleToast, setIsVisible } = useToast();

  const iconUrl = `/img/icons/${getFileIcon(fileType)}`;
  const index = items.indexOf(file.id);

  const validFormats = ["pdf", "doc", "docx", "xls", "xlsx", "zip"];

  const handleArrowClick = (isPrev: boolean) => () => {
    isPrev ? setFile(items[index - 1]) : setFile(items[index + 1]);
    setDNDVisible(false);
    setReplaceType(null);
  };

  const OptionsButton = () => (
    <Tooltip content="Replacement file will appear on all linked pages.">
      <Button type="button" buttonStyle="text" icon="page" backIcon="downArrow">
        Replace
      </Button>
    </Tooltip>
  );

  const handleReplaceType = (type: "full" | "partial") => {
    setReplaceType(type);
    setDNDVisible(true);
  };

  const textReplaceType =
    replaceType === "full"
      ? "Replace file and its URL"
      : replaceType === "partial"
      ? "Replace file and keep its original URL"
      : "";

  const handleUpload = (file: IFile[]) => {
    setFileSelected(file[0]);
    setDNDVisible(false);
    setReplaceType(null);
    toggleToast();
  };

  const toastProps = {
    setIsVisible,
    message: "1 file replaced",
  };

  return (
    <>
      <S.Wrapper>
        <S.LeftPanel>
          <IconAction icon="leftArrow" onClick={handleArrowClick(true)} disabled={index === 0} />
          <S.FilePreview>
            <S.ImageWrapper>
              <S.IconWrapper>
                <div><img src={iconUrl} alt={`${fileType} Icon`} /></div>
                {isDNDVisible && (
                <S.DragAndDropWrapper>
                  <FileDragAndDrop
                    validFormats={validFormats}
                    handleUpload={handleUpload}
                    inverse={true}
                    replaceData={{ fileID: id, keepURL: replaceType === "full" ? false : true }}
                    siteID={site || "global"}
                  />
                </S.DragAndDropWrapper>
              )}
              </S.IconWrapper>
            </S.ImageWrapper>
            <S.FileName>{fileName}</S.FileName>
            <S.FileActions>
              {isAllowedToEdit ? (
                <S.ReplaceWrapper>
                  <FloatingMenu Button={OptionsButton} position="left">
                    <S.ActionMenu>
                      <S.ActionItem onClick={() => handleReplaceType("full")}>Replace file and its URL</S.ActionItem>
                      <S.ActionItem onClick={() => handleReplaceType("partial")}>
                        Replace file and keep its original URL
                      </S.ActionItem>
                    </S.ActionMenu>
                  </FloatingMenu>
                  <S.TextType>{textReplaceType}</S.TextType>
                </S.ReplaceWrapper>
              ) : (
                <></>
              )}
            </S.FileActions>
          </S.FilePreview>
          <IconAction icon="rightArrow" onClick={handleArrowClick(false)} disabled={index === items.length - 1} />
        </S.LeftPanel>
        <DetailPanel
          file={file}
          toggleModal={toggleModal}
          onDelete={onDelete}
          activeDelete={activeDelete}
          isAllowedToEdit={isAllowedToEdit}
        />
      </S.Wrapper>
      {isVisible && <Toast {...toastProps} />}
    </>
  );
};

interface IProps {
  file: IFile;
  toggleModal(): void;
  onDelete(fileID: number): void;
  items: number[];
  setFile: (id: number) => void;
  setFileSelected: (file: IFile) => void;
  activeDelete: boolean;
  isAllowedToEdit: boolean;
}

export default FileModal;
