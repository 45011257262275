import styled, { css, keyframes } from "styled-components";

const OpacityAnimation = keyframes`
  to {
    opacity: 1;
  }
}`;

const TranslateAnimation = keyframes`
  to {
    transform: translateY(0);
  }
}`;

const SectionHeader = styled.div<{ isRecentSites?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin: 0 ${(p) => (p.isRecentSites ? p.theme.spacing.s : p.theme.spacing.m)};
  margin-top: ${(p) => p.theme.spacing.m};
`;

const Label = styled.span`
  margin-right: 14px;
`;

const Title = styled.h1<{ isActive?: boolean }>`
  margin: 0;
  margin-right: ${(p) => p.theme.spacing.m};
  ${(p) => p.theme.textStyle.headingM};
  color: ${(p) => (p.isActive ? p.theme.colors.textHighEmphasis : p.theme.colors.textMediumEmphasis)};
`;

const HeaderIconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FilterSelectLabel = styled.span`
  color: ${(p) => p.theme.color.interactive01};
`;

const FilterSelect = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  line-height: 32px;
  display: flex;
  align-items: center;
  position: relative;
  color: ${(p) => p.theme.color.textLowEmphasis};
`;

const CollapseButton = styled.div`
  display: flex;
  align-items: center;
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const RecentSites = styled.div<{ fullWidth: boolean }>`
  display: ${(p) => (p.fullWidth ? "block" : "inline-block")};
  padding-bottom: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  background: ${(p) => p.theme.color.uiBackground02};
  min-height: 56px;
  overflow: hidden;
  margin: ${(p) => p.theme.spacing.m};
  margin-bottom: 0;

  h1 {
    padding-left: 0;
  }
`;

const RecentSitesItemsWrapper = styled.div<{ isHidden: boolean }>`
  display: grid;
  grid-auto-flow: column;
  overflow: auto;
  grid-gap: ${(p) => p.theme.spacing.m};
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.s} 0px ${(p) => p.theme.spacing.s};
  overflow: hidden;

  div {
    min-width: 100%;
  }

  ${(props) =>
    props.isHidden &&
    css`
      height: 0;
      padding: 0;
      padding-left: ${(p) => p.theme.spacing.s};
      padding-top: ${(p) => p.theme.spacing.xs};
    `};

  @media (min-width: 1200px) {
    div:nth-child(6),
    div:nth-child(7) {
      display: none;
    }
  }

  @media (min-width: 1600px) {
    div:nth-child(6) {
      display: block;
    }
    div:nth-child(7) {
      display: none;
    }
  }

  @media (min-width: 1750px) {
    div:nth-child(6),
    div:nth-child(7) {
      display: block;
    }
  }
`;

const SitesListWrapper = styled.div`
  max-width: calc(100vw - calc(24px * 3));

  &.animate {
    animation: ${OpacityAnimation} 1.5s ease-in 1.5s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
`;

const GridList = styled.div<{ isEmpty: boolean; fullWidth: boolean }>`
  padding-top: ${(p) => p.theme.spacing.s};
  padding-bottom: ${(p) => p.theme.spacing.m};
  margin: 0 ${(p) => p.theme.spacing.m};
  max-width: calc(100vw - calc(24px * 3));

  ${({ isEmpty, theme, fullWidth }) =>
    isEmpty
      ? `
    display: flex;
    justify-content: center;
  `
      : `display: grid;
    grid-template-columns: ${
      fullWidth ? "repeat(auto-fit, minmax(270px, 1fr))" : "repeat(auto-fit, minmax(270px, 270px))"
    };
    grid-gap: ${theme.spacing.m};
  `}
`;

const EmptyStateWrapper = styled.div`
  text-align: center;
`;

const ImageWrapper = styled.div<{ borderRadius?: boolean }>`
  img {
    border-radius: ${(p) => (p.borderRadius ? p.theme.spacing.xs : 0)};
    object-fit: cover;
  }
`;

const Thumbnail = styled.div<{ backgroundUrl: string; height: number; width: number; borderRadius?: boolean }>`
  background: url(${(p) => p.backgroundUrl}) no-repeat center;
  background-size: cover;
  height: ${(p) => p.height}px;
  width: ${(p) => p.width}px;
  border-radius: ${(p) => (p.borderRadius ? p.theme.spacing.xs : 0)};
`;

const ItemName = styled.span``;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: ${(p) => p.theme.spacing.m};
  padding-right: ${(p) => p.theme.spacing.m};
`;

const AllSitesListWrapper = styled.div`
  &.animate {
    transform: translateY(50px);
    animation: ${TranslateAnimation} 1s ease-out 1.5s;
    animation-fill-mode: forwards;
  }
`;

export {
  SectionHeader,
  CollapseButton,
  Label,
  Title,
  HeaderIconsWrapper,
  FilterSelect,
  FilterSelectLabel,
  RecentSites,
  RecentSitesItemsWrapper,
  SitesListWrapper,
  GridList,
  EmptyStateWrapper,
  ImageWrapper,
  Thumbnail,
  ItemName,
  PaginationWrapper,
  AllSitesListWrapper,
};
