import styled from "styled-components";

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

export { Wrapper, Title, Text };
