import React from "react";
import styled from "styled-components";
import Button from "@ax/components/Button";

export const Wrapper = styled.div<{ inverse: boolean }>`
  background-color: ${(p) => (p.inverse ? p.theme.color.overlay : p.theme.color.uiBarBackground)};
  position: relative;
  transition: opacity 0.1s;
  height: 100%;
  width: 100%;
  padding: ${(p) => p.theme.spacing.m};
`;

export const StatusWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const DragStatus = styled.div`
  transition: opacity 0.1s;
`;

export const DragTitle = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

export const DragSubtitle = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

export const DragIcon = styled.div`
  height: ${(p) => p.theme.spacing.l};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

export const DragOverStatus = styled.div`
  transition: opacity 0.1s;
  ${DragIcon} {
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
`;

export const UploadingStatus = styled.div`
  transition: opacity 0.1s;
`;

export const SuccessStatus = styled.div`
  transition: opacity 0.1s;
  ${DragIcon as any} {
    svg {
      path {
        fill: ${(p) => p.theme.color.online};
      }
    }
  }
  ${DragTitle as any} {
    color: ${(p) => p.theme.color.textHighEmphasis};
  }
`;

export const ErrorStatus = styled.div`
  transition: opacity 0.1s;
  ${DragIcon as any} {
    svg {
      path {
        fill: ${(p) => p.theme.color.error};
      }
    }
  }
  ${DragTitle as any} {
    color: ${(p) => p.theme.color.textHighEmphasis};
  }
`;

export const DragAndDropWrapper = styled.div<{
  inDropZone: boolean;
  uploading: boolean;
  success: boolean;
  error: boolean;
  inverse: boolean;
}>`
  border: ${(p) =>
    `2px dashed ${p.inDropZone || p.inverse ? p.theme.color.interactiveInverse : p.theme.color.interactive01}`};
  border-radius: 4px;
  background-color: ${(p) =>
    p.inDropZone ? p.theme.color.interactive01 : p.inverse ? "transparent" : p.theme.color.uiBarBackground};
  width: 100%;
  height: 100%;
  opacity: ${(p) => (p.uploading || p.success || p.error ? "0" : "1")};
  display: ${(p) => (p.uploading || p.success || p.error ? "none" : "block")};
  transition: opacity 0.1s;

  ${DragStatus} {
    opacity: ${(p) => (p.inDropZone ? "0" : "1")};
    display: ${(p) => (p.inDropZone ? "none" : "block")};
  }

  ${DragOverStatus} {
    opacity: ${(p) => (p.inDropZone ? "1" : "0")};
    display: ${(p) => (p.inDropZone ? "block" : "none")};
  }

  ${DragTitle} {
    color: ${(p) => (p.inDropZone || p.inverse ? p.theme.color.interactiveInverse : p.theme.color.interactive01)};
  }

  ${DragSubtitle} {
    color: ${(p) =>
      p.inDropZone || p.inverse ? p.theme.color.textMediumEmphasisInverse : p.theme.color.textMediumEmphasis};
  }

  ${DragIcon} {
    svg {
      path {
        fill: ${(p) => (p.inverse ? p.theme.color.interactiveInverse : p.theme.color.interactive01)};
      }
    }
  }
`;

export const UploadingWrapper = styled.div<{
  inDropZone: boolean;
  uploading: boolean;
  success: boolean;
  error: boolean;
  inverse: boolean;
}>`
  border: ${(p) => (p.success || p.error ? `none` : `2px dashed ${p.theme.color.interactive01}`)};
  border-radius: 4px;
  background-color: ${(p) => (p.inverse ? "transparent" : p.theme.color.uiBackground01)};
  padding: ${(p) => p.theme.spacing.m};
  width: 100%;
  height: 100%;
  opacity: ${(p) => (p.uploading || p.success || p.error ? "1" : "0")};
  display: ${(p) => (p.uploading || p.success || p.error ? "block" : "none")};
  transition: opacity 0.1s;

  ${UploadingStatus} {
    opacity: ${(p) => (p.uploading ? "1" : "0")};
    display: ${(p) => (p.uploading ? "block" : "none")};
  }

  ${SuccessStatus} {
    opacity: ${(p) => (p.success ? "1" : "0")};
    display: ${(p) => (p.success ? "block" : "none")};
  }

  ${ErrorStatus} {
    opacity: ${(p) => (p.error ? "1" : "0")};
    display: ${(p) => (p.error ? "block" : "none")};
  }

  ${DragTitle} {
    color: ${(p) => (p.inverse ? p.theme.color.interactiveInverse : p.theme.color.interactive01)};
  }

  ${DragIcon} {
    svg {
      path {
        fill: ${(p) => (p.inverse ? p.theme.color.interactiveInverse : p.theme.color.interactive01)};
      }
    }
  }
`;

export const ErrorMsg = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-top: ${(p) => p.theme.spacing.xxs};
`;

export const StyledButton = styled((props) => <Button {...props} />)`
  margin-top: ${(p) => p.theme.spacing.s};
`;

export const FilesInput = styled.input<{ ref: any }>`
  display: none;
`;

const _Button: any = React.forwardRef((props: any, ref?: React.Ref<HTMLDivElement>) => <Button {...props} />);
export const FilesButton = styled(_Button)`
  margin-top: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.xs};

  &.inverse {
    background-color: transparent;

    :hover:before {
      background-color: ${(p) => p.theme.color.overlay};
    }
    :focus:before {
      background-color: ${(p) => p.theme.color.overlay};
    }
    :active:before {
      background-color: ${(p) => p.theme.color.overlay};
    }
  }
`;

export const ProgressBar = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;
