import * as React from "react";

import { PageProvider, NavigationProvider } from "../../contexts";
import { recursiveSearch } from "../../functions/utils";
import { Page } from "../../types/core";
import { Component, ComponentProps } from "../Component";

/**
 * `<PageComponent>`
 */
function PageComponent(props: PageProps) {
	const {
		apiUrl,
		content,
		footer,
		header,
		languageId,
		library: { templates, components },
		pageLanguages,
		siteMetadata,
	} = props;

	const Template = templates[content.template.templateType];

	// ⚠️ WARNING️ 😱
	// Aquí estamos asumiendo que hay una sección que se llama `heroSection`
	//                                        ~~~~~~~~~~~
	const firstHeroElement = content.template.heroSection
		? content.template.heroSection.modules &&
		  content.template.heroSection.modules[0]
		: undefined;

	const firstModule = firstHeroElement ? firstHeroElement.component : undefined;

	// Components (component and modules) list used by the page.
	const componentList = recursiveSearch({
		obj: content.template,
		searchKey: "component",
	});

	// Array of section names
	const sectionsName = Object.keys(content?.template).filter(
		(key) => content?.template[key] && content.template[key].component
	);

	// Object with modules by section
	// {
	//   section01: [ 'Mod2', 'Mod4' ],
	//   section02: [ 'Mod7', 'Mod9' ],
	// }
	const sectionModules = Object.fromEntries(
		sectionsName?.map((key) => [
			key,
			content?.template[key].modules?.map(
				({ component }: { component: string }) => component
			),
		])
	);

	return (
		<PageProvider
			activeSectionBase={content.template.activeSectionBase}
			activeSectionSlug={content.template.activeSectionSlug || "/"}
			apiUrl={apiUrl}
			breadcrumb={content.breadcrumb}
			canonicalSite={content.canonicalSite}
			componentList={componentList}
			dimensions={content.dimensions?.values}
			firstModule={firstModule}
			footer={footer}
			fullPath={content.fullPath}
			fullUrl={content.fullUrl}
			header={header}
			isHome={content.isHome}
			languageId={languageId}
			modified={content.modified}
			origin={content.origin}
			pageLanguages={pageLanguages}
			published={content.published}
			sectionModules={sectionModules}
			site={content.site}
			siteMetadata={siteMetadata}
			siteSlug={content.siteSlug}
			structuredData={content.structuredData}
			structuredDataContent={content.structuredDataContent}
			title={content.title}
			template={content.template}
			headerTheme={content.headerTheme}
			footerTheme={content.footerTheme}
			theme={content.theme}
		>
			<>
				{/* Header Sections */}
				{!!header && (
					<NavigationProvider>
						<Component libComponents={components} {...header} />
					</NavigationProvider>
				)}

				{/* Template Modules/Components */}
				<Template {...content.template} />

				{/* Footer Sections  */}
				{!!footer && (
					<NavigationProvider>
						<Component libComponents={components} {...footer} />
					</NavigationProvider>
				)}
			</>
		</PageProvider>
	);
}

export interface PageProps
	extends Pick<
		Page,
		| "apiUrl"
		| "footer"
		| "header"
		| "languageId"
		| "pageLanguages"
		| "siteMetadata"
	> {
	content: Pick<
		Page,
		| "breadcrumb"
		| "canonicalSite"
		| "dimensions"
		| "footerTheme"
		| "fullPath"
		| "fullUrl"
		| "headerTheme"
		| "isHome"
		| "modified"
		| "origin"
		| "published"
		| "site"
		| "siteSlug"
		| "structuredData"
		| "structuredDataContent"
		| "template"
		| "theme"
		| "title"
	>;
	library: {
		components: Record<string, React.FC<Omit<ComponentProps, "libComponents">>>;
		templates: Record<string, React.FC<Omit<ComponentProps, "libComponents">>>;
	};
	children?: JSX.Element;
}

export { PageComponent as Page };
