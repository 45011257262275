import React from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import { IModule } from "@ax/types";
import { ComponentContainer } from "@ax/components";

import AddItemButton from "./AddItemButton";
import { getComponentProps, getTypefromKey } from "../helpers";

import * as S from "./style";

const SameComponentArray = (props: ISameComponentArrayProps): JSX.Element => {
  const {
    whiteList,
    title,
    value,
    goTo,
    selectedContent,
    editorID,
    actions,
    categories,
    maxItems,
    disabled,
    activatedModules,
    objKey,
    field,
    mandatory,
    theme,
  } = props;

  const type = getTypefromKey(objKey);
  const { contentType = type } = field;

  let addModuleAction: any;
  let addComponentAction: any;

  if (actions) {
    addModuleAction = actions.addModuleAction;
    addComponentAction = actions.addComponentAction;
  }

  const getText = (name: string, index: number) => {
    return value && value.length > 1 ? `#${index + 1} ${name}` : name;
  };

  const { kind } = selectedContent;

  const isModuleArr = contentType === "modules";
  const isComponentModule = contentType === "components";

  const handleAddModule = (moduleType: string) => addModuleAction(moduleType, objKey, editorID, isComponentModule);

  const handleAddComponent = () => addComponentAction && addComponentAction(kind, objKey);

  const handleAdd = isModuleArr ? handleAddModule : handleAddComponent;

  const showAddItemButton = !maxItems || (value && value.length < maxItems);

  const Asterisk = () => (mandatory ? <S.Asterisk>*</S.Asterisk> : null);

  const onDragEnd = (result: DropResult) => {
    const { moveModuleAction } = actions;

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    moveModuleAction(parseInt(result.draggableId), selectedContent, result.destination.index, objKey);
  };

  const ComponentList = React.memo(function ComponentList({ components }: any) {
    return components.map((element: any, i: number) => {
      const { editorID } = element;
      const { moduleTitle, isModuleDeactivated, componentTitle, displayName, isModule } = getComponentProps(
        element,
        activatedModules,
        isModuleArr
      );
      const text = getText(componentTitle || displayName, i);
      return (
        <Draggable draggableId={`${editorID}`} index={i} key={editorID}>
          {(provided) => (
            <ComponentContainer
              isArray={true}
              key={editorID}
              editorID={editorID}
              goTo={goTo}
              text={text}
              moduleTitle={moduleTitle}
              whiteList={whiteList}
              categories={categories}
              actions={actions}
              selectedContent={selectedContent}
              disabled={disabled}
              canDuplicate={showAddItemButton && !isModuleDeactivated}
              parentKey={objKey}
              theme={theme}
              arrayLength={components.length}
              innerRef={provided.innerRef}
              provided={provided}
              isModule={isModule}
            />
          )}
        </Draggable>
      );
    });
  });

  return (
    <S.Wrapper data-testid="sameComponentWrapper">
      <S.Title>
        {title} <Asterisk />
      </S.Title>
      <S.ItemRow>
        <S.Subtitle>{value && value.length} items</S.Subtitle>
        {showAddItemButton && !disabled && (
          <AddItemButton handleClick={handleAdd} tooltipText={isModuleArr ? "Add module" : "Add component"} />
        )}
      </S.ItemRow>
      {value && Array.isArray(value) && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <ComponentList components={value} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </S.Wrapper>
  );
};

export interface ISameComponentArrayProps {
  maxItems: number;
  title: string;
  whiteList: string[];
  value?: IModule[];
  elementUniqueSelection: boolean;
  selectedContent: any;
  editorID: number;
  goTo: (editorID: string) => void;
  actions: any;
  categories?: any;
  disabled?: boolean;
  activatedModules: string[];
  objKey: string;
  field: any;
  mandatory?: boolean;
  theme: string;
}

export default SameComponentArray;
