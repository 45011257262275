import React from "react";
import { connect } from "react-redux";

import { FieldsBehavior } from "@ax/components";
import { structuredDataActions } from "@ax/containers/StructuredData";

import { IRootState, ICategory } from "@ax/types";
import { slugify } from "@ax/helpers";

const Form = (props: IProps): JSX.Element => {
  const { edit, category, setCategoryValues } = props;

  const setNameValue = (value: string) => {
    if (!edit) {
      setCategoryValues({ ...category, name: value, code: slugify(value) });
    } else {
      setCategoryValues({ ...category, name: value });
    }
  };

  const setCodeValue = (value: string) => setCategoryValues({ ...category, code: value });

  return (
    <>
      <FieldsBehavior
        title="Name"
        name="name"
        fieldType="TextField"
        value={category.name || ""}
        onChange={setNameValue}
        autoComplete="category-name"
      />
      <FieldsBehavior
        title="Code"
        name="code"
        fieldType="TextField"
        value={category.code || ""}
        onChange={setCodeValue}
        autoComplete="category-code"
      />
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  category: state.structuredData.category,
});

interface IFormProps {
  edit: boolean | undefined;
}

interface IStateProps {
  category: ICategory;
}

interface IDispatchProps {
  setCategoryValues(value: ICategory): void;
}

type IProps = IDispatchProps & IStateProps & IFormProps;

export default connect(mapStateToProps, { setCategoryValues: structuredDataActions.setCategoryValues })(Form);
