import React, { useRef, useEffect, useState, useCallback, useLayoutEffect } from "react";
import { connect } from "react-redux";

import {
  Button,
  EmptyState,
  ErrorToast,
  Icon,
  IconAction,
  MainWrapper,
  TableList,
  Loading,
  Tooltip,
  Toast,
  Modal,
  Tabs,
  BackFolder,
} from "@ax/components";
import { IBulkAction, IFile, IFilesFolder, IFolder, IFolderTree, IGetFolderParams, IRootState } from "@ax/types";
import { useBulkSelection, useModal, usePermission, useToast } from "@ax/hooks";
import { fileDriveActions } from "@ax/containers/FileDrive";

import GridItem from "./GridItem";
import ListItem from "./ListItem";
import BulkListHeader from "./BulkListHeader";
import FolderItem from "./FolderItem";
import Breadcrumb from "./Breadcrumb";
import FolderTree from "./FolderTree";
import FileDragAndDrop from "./FileDragAndDrop";
import FileModal from "./FileModal";
import BulkGridHeader from "./BulkGridHeader";
import { DeleteFileModal, MoveItemModal, NewFolderModal } from "./atoms";

import * as S from "./style";

const FileDrive = (props: IProps) => {
  const {
    currentFolderContent,
    currentFolderID,
    currentSiteID,
    getFolderContent,
    getFoldersTree,
    updateCurrentFolder,
    createNewFolder,
    deleteFolder,
    deleteFile,
    moveFile,
    updateDisplayMode,
    updateTab,
    breadcrumb,
    isLoading,
    isSaving,
    displayMode,
    selectedTab,
  } = props;

  const {
    files: { totalItems, items },
    folders,
  } = currentFolderContent || { files: { totalItems: 0, items: [] }, folders: [] };

  const [isPanelOpen, setPanelOpen] = useState(false);
  const [fileSelected, setFileSelected] = useState<IFile | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<number>(currentFolderID || 0);
  const filesIds: number[] = items.map((file: any) => file.id) || [];
  const [galleryItems, setGalleryItems] = useState<number[]>(filesIds);
  const [galleryDelete, setGalleryDelete] = useState(true);
  const [numDocs, setNumDocs] = useState(1);
  const tableRef = useRef<HTMLDivElement>(null);
  const { isOpen: isNewOpen, toggleModal: toggleNewModal } = useModal();
  const { isOpen: isUploadOpen, toggleModal: toggleUploadModal } = useModal();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isMoveOpen, toggleModal: toggleMoveModal } = useModal();
  const { isOpen, toggleModal } = useModal();
  const { isVisible, toggleToast, setIsVisible } = useToast();
  const { isVisible: isNewVisible, toggleToast: toggleNewToast, setIsVisible: setIsNewVisible } = useToast();
  const { isVisible: isDeleteVisible, toggleToast: toggleDeleteToast, setIsVisible: setIsDeleteVisible } = useToast();
  const { isVisible: isMoveVisible, toggleToast: toggleMoveToast, setIsVisible: setIsMoveVisible } = useToast();
  const {
    isVisible: isMoveFolderVisible,
    toggleToast: toggleMoveFolderToast,
    setIsVisible: setIsMoveFolderVisible,
  } = useToast();

  const initState = { name: "", folderID: currentFolderID };
  const [folderForm, setFolderForm] = useState<IFormState>(initState);

  const isSiteView = !!currentSiteID;
  const isTabGlobal = selectedTab === "global";
  const siteID: number | "global" = !isSiteView || isTabGlobal ? "global" : currentSiteID;
  const hasFolders = !!folders.length;
  const isRoot = !breadcrumb.length;
  const isGrid = displayMode === "grid";
  const validFormats = ["pdf", "doc", "docx", "xls", "xlsx", "zip"];

  const allowedToAccessGlobalFromSite = usePermission("mediaGallery.accessToGlobalFileDriveFromSite");

  const allowedToAddSiteFile = usePermission("mediaGallery.addFiles");
  const allowedToAddGlobalFile = usePermission("global.mediaGallery.addGlobalFiles");
  const allowedToAddGlobalFileFromSite = usePermission("mediaGallery.addGlobalFilesFromSite");
  const allowedToAddFile = isTabGlobal
    ? allowedToAddGlobalFileFromSite
    : isSiteView
    ? allowedToAddSiteFile
    : allowedToAddGlobalFile;

  const allowedToEditSiteFile = usePermission("mediaGallery.editFiles");
  const allowedToEditGlobalFile = usePermission("global.mediaGallery.editGlobalFiles");
  const allowedToEditGlobalFileFromSite = usePermission("mediaGallery.editGlobalFilesInSite");
  const allowedToEditFile = isTabGlobal
    ? allowedToEditGlobalFileFromSite
    : isSiteView
    ? allowedToEditSiteFile
    : allowedToEditGlobalFile;

  const allowedToDeleteSiteFile = usePermission("mediaGallery.deleteFiles");
  const allowedToDeleteGlobalFile = usePermission("global.mediaGallery.deleteGlobalFiles");
  const allowedToDeleteGlobalFileFromSite = usePermission("mediaGallery.deleteGlobalFilesInSite");
  const allowedToDeleteFile = isTabGlobal
    ? allowedToDeleteGlobalFileFromSite
    : isSiteView
    ? allowedToDeleteSiteFile
    : allowedToDeleteGlobalFile;

  const getParams = useCallback(() => {
    const params = {
      siteID,
      folderID: currentFolderID,
    };

    return params;
  }, [currentFolderID, selectedTab]);

  useLayoutEffect(() => {
    return () => {
      updateCurrentFolder(null);
      updateTab("local");
    };
  }, []);

  useEffect(() => {
    const handleGetContent = async () => {
      const params = getParams();
      await getFolderContent(params);
    };
    handleGetContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams]);

  useEffect(() => {
    const handleGetTree = async () => await getFoldersTree(siteID);
    handleGetTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    const filesIds: number[] = items.map((file: any) => file.id) || [];
    setGalleryItems(filesIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const {
    resetBulkSelection,
    selectedItems,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
  } = useBulkSelection(filesIds);

  const handleSelectedTab = (tab: "local" | "global") => {
    if (tab !== selectedTab) {
      updateCurrentFolder(null);
      updateTab(tab);
    }
  };

  const handleNewFolder = () => {
    setFolderForm(initState);
    !isNewOpen && toggleNewModal();
  };

  const changeDisplayMode = (mode: "grid" | "list") => updateDisplayMode(mode);

  const selectItems = () => (checkState.isAllSelected ? resetBulkSelection() : selectAllItems());

  const handleBackClick = () => {
    const parentID = breadcrumb.length >= 2 ? breadcrumb[breadcrumb.length - 2].id : null;
    updateCurrentFolder(parentID);
  };

  const togglePanel = () => setPanelOpen(!isPanelOpen);

  const handleCreateNewFolder = async () => {
    const isCreated = await createNewFolder({
      site: siteID,
      folderName: folderForm.name,
      parentId: folderForm.folderID || undefined,
    });
    if (isCreated) {
      isNewOpen && toggleNewModal();
      toggleNewToast();
    }
  };

  const handleDeleteFolder = async (folder: IFolder) => {
    const isDeleted = await deleteFolder(folder);
    if (isDeleted) {
      toggleToast();
    }
  };

  const handleClick = (file: IFile) => {
    setFileSelected(file);
    toggleModal();
  };

  /*const handleSingleUpload = async (file: IFile) => {
    setFileSelected(file);
    isUploadOpen && toggleUploadModal();
    toggleModal();
    const params = getParams();
    await getFolderContent(params);
  };*/

  const handleMultipleUpload = async (files: IFile[]) => {
    setFileSelected(files[0]);
    isUploadOpen && toggleUploadModal();
    toggleModal();
    const params = getParams();
    await getFolderContent(params);
  };

  const handleCloseModal = () => {
    setFileSelected(null);
    setGalleryItems(filesIds);
    setGalleryDelete(true);
    toggleModal();
  };

  const handleDeleteFile = async (fileID: number) => {
    const isDeleted = await deleteFile(fileID, siteID);
    if (isDeleted) {
      setNumDocs(1);
      toggleDeleteToast();
    }
  };

  const handleBulkDeleteFile = async () => {
    const isDeleted = await deleteFile(selectedItems.all, siteID);
    if (isDeleted) {
      setNumDocs(selectedItems.all.length);
      toggleDeleteModal();
      toggleDeleteToast();
      resetBulkSelection();
    }
  };

  const handleMoveFile = async (fileID: number, folderID: number) => {
    const isMoved = await moveFile(fileID, folderID, siteID);
    if (isMoved) {
      setNumDocs(1);
      toggleMoveToast();
    }
  };

  const handleBulkMoveFile = async () => {
    const isMoved = await moveFile(selectedItems.all, selectedFolder, siteID);
    if (isMoved) {
      setNumDocs(selectedItems.all.length);
      toggleMoveModal();
      toggleMoveToast();
      setSelectedFolder(0);
      resetBulkSelection();
    }
  };

  const handleBulkEditFile = () => {
    const currentFile = items.find((file: IFile) => file.id === selectedItems.all[0]);
    if (currentFile) {
      setGalleryItems(selectedItems.all);
      setFileSelected(currentFile);
      setGalleryDelete(false);
      toggleModal();
    }
  };

  const handleModalClose = () => {
    setSelectedFolder(0);
    isMoveOpen && toggleMoveModal();
  };

  const handleSelectFile = (id: number) => {
    const currentFile = items.find((file: IFile) => file.id === id);
    if (currentFile) {
      setFileSelected(currentFile);
    }
  };

  let bulkActions: IBulkAction[] = [];

  if (allowedToEditFile) {
    bulkActions = [
      {
        icon: "edit",
        text: "edit",
        action: handleBulkEditFile,
      },
      {
        icon: "change",
        text: "Move to",
        action: toggleMoveModal,
      },
    ];
  }

  if (allowedToDeleteFile) {
    bulkActions = [
      ...bulkActions,
      {
        icon: "delete",
        text: "delete",
        action: toggleDeleteModal,
      },
    ];
  }

  const Header = (
    <BulkListHeader
      showBulk={areItemsSelected(filesIds)}
      checkState={checkState}
      selectAllItems={selectItems}
      totalItems={totalItems}
      selectItems={selectItems}
      bulkActions={bulkActions}
    />
  );

  const GridList = () => (
    <S.GridWrapper>
      {items.map((file: IFile, i: number) => {
        const isItemSelected = isSelected(file.id);
        return (
          <GridItem
            file={file}
            isSelected={isItemSelected}
            onChange={addToBulkSelection}
            key={file.fileName}
            onClick={handleClick}
            onDelete={handleDeleteFile}
            onMove={handleMoveFile}
            currentFolderID={currentFolderID}
            isAllowedToDelete={allowedToDeleteFile}
            isAllowedToEdit={allowedToEditFile}
            isAllowedToMove={!isRoot || (isRoot && folders.length > 0)}
          />
        );
      })}
    </S.GridWrapper>
  );

  const ListTable = () => (
    <TableList tableHeader={Header} hasFixedHeader={true} tableRef={tableRef} className="no-padding">
      {items.map((file: IFile, i: number) => {
        const isItemSelected = isSelected(file.id);
        return (
          <ListItem
            file={file}
            isSelected={isItemSelected}
            onChange={addToBulkSelection}
            key={file.fileName}
            onClick={handleClick}
            onDelete={handleDeleteFile}
            onMove={handleMoveFile}
            currentFolderID={currentFolderID}
            isAllowedToDelete={allowedToDeleteFile}
            isAllowedToEdit={allowedToEditFile}
            isAllowedToMove={!isRoot || (isRoot && folders.length > 0)}
          />
        );
      })}
    </TableList>
  );

  const toastDeleteFolderProps = {
    setIsVisible,
    message: "1 folder deleted",
  };

  const toastNewProps = {
    setIsVisible: setIsNewVisible,
    message: "1 new folder created",
  };

  const toastDeleteFileProps = {
    setIsVisible: setIsDeleteVisible,
    message: `${numDocs} Document${numDocs > 1 ? "s" : ""} deleted`,
  };

  const toastMoveFileProps = {
    setIsVisible: setIsMoveVisible,
    message: `${numDocs} Document${numDocs > 1 ? "s" : ""} moved to a folder`,
  };

  const toastMoveFolderProps = {
    setIsVisible: setIsMoveFolderVisible,
    message: "1 Folder moved to another folder",
  };

  const rightButtonProps = allowedToAddFile
    ? {
        label: "Upload",
        action: () => toggleUploadModal(),
      }
    : undefined;

  const emptyProps = {
    message: allowedToAddFile ? "To start using files in your site, upload as many documents as you need" : "",
    button: allowedToAddFile ? "Upload file" : undefined,
    action: () => toggleUploadModal(),
  };

  const mainNewModalAction = {
    title: isSaving ? "Saving..." : "Create New Folder",
    onClick: () => handleCreateNewFolder(),
    disabled: folderForm.name.trim().length === 0 || isSaving,
  };

  const secondaryNewModalAction = { title: "Cancel", onClick: toggleNewModal };

  const mainDeleteModalAction = {
    title: "Delete documents",
    onClick: () => handleBulkDeleteFile(),
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const mainMoveModalAction = {
    title: "Move",
    onClick: () => handleBulkMoveFile(),
  };

  const secondaryMoveModalAction = { title: "Cancel", onClick: handleModalClose };

  const foldersIcon = isPanelOpen ? <Icon name="closePanel" size="24" /> : <Icon name="openPanel" size="24" />;

  const tabs = ["local", "global"];

  const NewFolderButton = () =>
    allowedToAddFile ? (
      <S.ButtonWrapper>
        <Button type="button" onClick={handleNewFolder} buttonStyle="line" icon="NewFolder">
          New Folder
        </Button>
      </S.ButtonWrapper>
    ) : (
      <></>
    );

  return (
    <MainWrapper backLink={false} title="File Drive Manager" rightButton={rightButtonProps}>
      <S.Wrapper>
        <S.FolderPanel isOpen={isPanelOpen}>
          <S.FolderPanelContent>
            <FolderTree
              folderID={currentFolderID || 0}
              onClick={updateCurrentFolder}
              title="Folders"
              createAction={allowedToAddFile ? handleNewFolder : undefined}
              trimNames={true}
            />
          </S.FolderPanelContent>
        </S.FolderPanel>
        <S.ContentWrapper>
          <ErrorToast />
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <S.FiltersBar isSite={isSiteView && allowedToAccessGlobalFromSite}>
                {isSiteView && allowedToAccessGlobalFromSite && (
                  <S.TabsWrapper>
                    <Tabs tabs={tabs} active={selectedTab} setSelectedTab={handleSelectedTab} noMargins />
                  </S.TabsWrapper>
                )}
                <S.DisplayModeWrapper>
                  <IconAction icon="Grid2" onClick={() => changeDisplayMode("grid")} active={displayMode === "grid"} />
                  <IconAction
                    icon="BulletList"
                    onClick={() => changeDisplayMode("list")}
                    active={displayMode === "list"}
                  />
                </S.DisplayModeWrapper>
              </S.FiltersBar>
              {!isRoot && <Breadcrumb breadcrumb={breadcrumb} onClick={updateCurrentFolder} />}
              {(hasFolders || !isRoot) && (
                <S.SectionWrapper>
                  <S.SectionHeader>
                    <S.SectionTitle>
                      <div>Folders</div>
                      <S.FoldersIconWrapper onClick={togglePanel}>
                        <Tooltip content="Open folder panel">{foldersIcon}</Tooltip>
                      </S.FoldersIconWrapper>
                    </S.SectionTitle>
                    <NewFolderButton />
                  </S.SectionHeader>
                  <S.FoldersWrapper>
                    {!isRoot && <BackFolder onClick={handleBackClick} />}
                    <S.FoldersGrid>
                      {folders.map((folder: IFolder) => (
                        <FolderItem
                          folder={folder}
                          onClick={updateCurrentFolder}
                          onDelete={handleDeleteFolder}
                          key={folder.folderName}
                          toggleToast={toggleMoveFolderToast}
                          isAllowedToDelete={allowedToDeleteFile}
                          isAllowedToEdit={allowedToEditFile}
                          isAllowedToMove={!isRoot || (isRoot && folders.length > 1)}
                        />
                      ))}
                    </S.FoldersGrid>
                  </S.FoldersWrapper>
                </S.SectionWrapper>
              )}
              <S.SectionWrapper>
                <S.SectionHeader>
                  <S.SectionTitle>Documents</S.SectionTitle>
                  {totalItems > 0 && isGrid && (
                    <BulkGridHeader
                      showBulk={areItemsSelected(filesIds)}
                      checkState={checkState}
                      selectAllItems={selectItems}
                      totalItems={totalItems}
                      selectItems={selectItems}
                      bulkActions={bulkActions}
                    />
                  )}
                  {!hasFolders && isRoot && <NewFolderButton />}
                </S.SectionHeader>
                <S.DocumentsWrapper>
                  {items.length > 0 ? (
                    isGrid ? (
                      <GridList />
                    ) : (
                      <ListTable />
                    )
                  ) : (
                    <S.EmptyStateWrapper data-testid="empty-state">
                      <EmptyState {...emptyProps} />
                    </S.EmptyStateWrapper>
                  )}
                </S.DocumentsWrapper>
              </S.SectionWrapper>
            </>
          )}
        </S.ContentWrapper>
      </S.Wrapper>
      {isNewOpen && (
        <NewFolderModal
          isOpen={isNewOpen}
          toggleModal={toggleNewModal}
          mainModalAction={mainNewModalAction}
          secondaryModalAction={secondaryNewModalAction}
          form={folderForm}
          setForm={setFolderForm}
        />
      )}
      <Modal isOpen={isUploadOpen} hide={toggleUploadModal} size="XL" title="Upload file">
        {isUploadOpen && (
          <FileDragAndDrop
            validFormats={validFormats}
            folderID={currentFolderID}
            handleUpload={handleMultipleUpload}
            siteID={siteID}
          />
        )}
      </Modal>
      <Modal isOpen={isOpen} hide={handleCloseModal} size="XL" title="Document details">
        {isOpen && fileSelected && (
          <FileModal
            file={fileSelected}
            toggleModal={handleCloseModal}
            onDelete={handleDeleteFile}
            items={galleryItems}
            setFile={handleSelectFile}
            activeDelete={galleryDelete && allowedToDeleteFile}
            setFileSelected={setFileSelected}
            isAllowedToEdit={allowedToEditFile}
          />
        )}
      </Modal>
      {isDeleteOpen && (
        <DeleteFileModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
          title="these documents"
        />
      )}
      {isMoveOpen && (
        <MoveItemModal
          isOpen={isMoveOpen}
          toggleModal={handleModalClose}
          mainModalAction={mainMoveModalAction}
          secondaryModalAction={secondaryMoveModalAction}
          folder={selectedFolder}
          setFolder={setSelectedFolder}
        />
      )}
      {isVisible && <Toast {...toastDeleteFolderProps} />}
      {isDeleteVisible && <Toast {...toastDeleteFileProps} />}
      {isNewVisible && <Toast {...toastNewProps} />}
      {isMoveVisible && <Toast {...toastMoveFileProps} />}
      {isMoveFolderVisible && <Toast {...toastMoveFolderProps} />}
    </MainWrapper>
  );
};

interface IProps {
  isLoading: boolean;
  isSaving: boolean;
  currentFolderContent: IFilesFolder | null;
  currentFolderID: number | null;
  currentSiteID: number | null;
  breadcrumb: IFolderTree[];
  displayMode: "grid" | "list";
  selectedTab: "local" | "global";
  getFolderContent(params: IGetFolderParams): Promise<void>;
  getFoldersTree(siteID: number | "global"): Promise<void>;
  updateCurrentFolder(folderID: number | null): void;
  createNewFolder(data: { folderName: string; site: number | "global"; parentId?: number }): Promise<boolean>;
  deleteFolder(folder: IFolder): Promise<boolean>;
  deleteFile(fileID: number | number[], siteID: number | "global"): Promise<boolean>;
  moveFile(fileID: number | number[], folderID: number, siteID: number | "global"): Promise<boolean>;
  updateDisplayMode(displayMode: "grid" | "list"): Promise<void>;
  updateTab(tab: "local" | "global"): Promise<void>;
}

export interface IFormState {
  name: string;
  folderID: number | null;
}

const mapDispatchToProps = {
  getFolderContent: fileDriveActions.getFolderContent,
  getFoldersTree: fileDriveActions.getFoldersTree,
  updateCurrentFolder: fileDriveActions.updateCurrentFolder,
  createNewFolder: fileDriveActions.createNewFolder,
  deleteFolder: fileDriveActions.deleteFolder,
  deleteFile: fileDriveActions.deleteFile,
  moveFile: fileDriveActions.moveFile,
  updateDisplayMode: fileDriveActions.updateDisplayMode,
  updateTab: fileDriveActions.updateTab,
};

const mapStateToProps = (state: IRootState) => ({
  isLoading: state.app.isLoading,
  isSaving: state.app.isSaving,
  currentFolderContent: state.fileDrive.currentFolderContent,
  currentFolderID: state.fileDrive.currentFolderID,
  breadcrumb: state.fileDrive.breadcrumb,
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  displayMode: state.fileDrive.displayMode,
  selectedTab: state.fileDrive.selectedTab,
});

export default connect(mapStateToProps, mapDispatchToProps)(FileDrive);
