
import { useState } from "react";

const useSortedListStatus = () => {
  const sortedInitialState: { isAscending: boolean; sortedByName: boolean; sortedByDate: boolean; sortedBySize: boolean; } = {
    isAscending: false,
    sortedByName: false,
    sortedByDate: false,
    sortedBySize: false
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus
  };
};


const useFilterQuery = () => {
  const initialQueryValues = {
    orientation: "",
    order: "",
    format: ""
  };

  const [query, setQuery] = useState(initialQueryValues);

  const setFilterQuery = (filterValues: any) => {
    const { orientation, order, format } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: string) => {
      return filterQuery
        ? filterQuery.concat(`&${pointer}=${values}`)
        : `&${pointer}=${values}`;
    };

    if (orientation) {
      filterQuery = currentQuery("orientation", orientation);
    }

    if (order) {
      filterQuery = currentQuery("order", order);
    }

    if (format) {
      filterQuery = currentQuery("format", format);
    }

    return filterQuery;
  };

  const setFiltersSelection = (pointer: string, filter: string, isAscendent?: boolean) => {
    const { orientation, order, format } = query;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues = {
      orientation: pointer === "orientation" ? filter : orientation,
      order: pointer === "order" ? `${filter}-${orderMethod}` : order,
      format: pointer === "format" ? filter : format,
    };

    setQuery(filterValues);

    return filterValues;
  };

  return {
    setFiltersSelection,
    setFilterQuery
  };
};

export {
  useSortedListStatus,
  useFilterQuery
};
