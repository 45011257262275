import { useEffect, useState } from "react";
import { IStructuredData, IStructuredDataQueryValues, IStructuredDataSortedInitialState } from "@ax/types";

const useSortedListStatus = (): IUseSortedListStatus => {
  const sortedInitialState: IStructuredDataSortedInitialState = {
    isAscending: false,
    sortedByTitle: false,
    sortedByURL: false,
    sortedByDate: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = (
  currentStructuredData: IStructuredData | null,
  values: Record<string, IStructuredDataQueryValues> | null
): IUseFilterQuery => {
  const structuredDataType = currentStructuredData ? currentStructuredData.id : "all";
  const initialQueryValues = {
    types: "all",
    translated: "all",
    liveStatus: "all",
    order: "",
    filterSites: "all",
    categories: "all",
    related: "all",
  };

  const getFilterQuery = (filterValues: IStructuredDataQueryValues) => {
    const { types, translated, liveStatus, order, filterSites, categories, related } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: string) => {
      return filterQuery ? filterQuery.concat(`&${pointer}=${values}`) : `&${pointer}=${values}`;
    };

    const isNotInitialValue = (pointer: keyof IStructuredDataQueryValues) => {
      return filterValues[pointer] && initialQueryValues[pointer] !== filterValues[pointer];
    };

    if (isNotInitialValue("types")) filterQuery = currentQuery("filterStructuredData", types);
    if (isNotInitialValue("translated")) filterQuery = currentQuery("translated", translated);
    if (isNotInitialValue("liveStatus")) filterQuery = currentQuery("liveStatus", liveStatus);
    if (isNotInitialValue("order")) filterQuery = currentQuery("order", order);
    if (isNotInitialValue("filterSites")) filterQuery = currentQuery("filterSites", filterSites);
    if (isNotInitialValue("categories")) filterQuery = currentQuery("categories", categories);
    if (isNotInitialValue("related")) filterQuery = currentQuery("related", related);

    return filterQuery;
  };

  const initState = values ? values : { all: initialQueryValues };
  const [state, setState] = useState<Record<string, IStructuredDataQueryValues>>(initState);

  const initQuery = values && values[structuredDataType] ? getFilterQuery(values[structuredDataType]) : "";
  const [query, setQuery] = useState(initQuery);

  useEffect(() => {
    if (!state[structuredDataType]) {
      setState((state) => ({ [structuredDataType]: initialQueryValues, ...state }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structuredDataType]);

  const setFiltersSelection = (pointer: string, filter: string, isAscendent?: boolean) => {
    const { types, translated, liveStatus, order, filterSites, categories, related } =
      state[structuredDataType] || initialQueryValues;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues = {
      types: pointer === "types" ? filter : types,
      translated: pointer === "translated" ? filter : translated,
      liveStatus: pointer === "liveStatus" ? filter : liveStatus,
      order: pointer === "order" ? `${filter}-${orderMethod}` : order,
      filterSites: pointer === "filterSites" ? filter : filterSites,
      categories: pointer === "categories" ? filter : categories,
      related: pointer === "related" ? filter : related,
    };

    setState((state) => ({ ...state, [structuredDataType]: filterValues }));
    const filterQuery = getFilterQuery(filterValues);
    setQuery(filterQuery);
  };

  const resetFilterQuery = () => {
    setState({ [structuredDataType]: initialQueryValues });
    setQuery("");
  };

  return {
    setFiltersSelection,
    resetFilterQuery,
    filterValues: state,
    query,
  };
};

interface IUseSortedListStatus {
  sortedListStatus: IStructuredDataSortedInitialState;
  setSortedListStatus: React.Dispatch<React.SetStateAction<IStructuredDataSortedInitialState>>;
}

interface IUseFilterQuery {
  setFiltersSelection(pointer: string, filter: string, isAscendent?: boolean): void;
  resetFilterQuery(): void;
  filterValues: Record<string, IStructuredDataQueryValues>;
  query: string;
}

export { useSortedListStatus, useFilterQuery };
