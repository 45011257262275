import styled from "styled-components";

import { Cell, Row } from "@ax/components/TableList/TableItem/style";
import { ActionMenu } from "@ax/components";

const CheckCell = styled(Cell)<{ hasHandle: boolean }>`
  width: 24px;
  padding: ${(p) => p.hasHandle ? `${p.theme.spacing.s} ${p.theme.spacing.xs}`: `0 ${p.theme.spacing.s} 0 ${p.theme.spacing.m}`};
  label {
    margin-bottom: 18px;
  }
`;

const NameCell = styled(Cell)<{ clickable: boolean }>`
  ${(p) => p.theme.textStyle.uiM};
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
  color: ${(p) => p.theme.color.textHighEmphasis};
  width: 350px;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
`;

const Order = styled.div`
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-right: ${(p) => p.theme.spacing.xxs};
`;

const DescriptionCell = styled(Cell)<{ clickable: boolean }>`
  ${(p) => p.theme.textStyle.uiXS};
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  flex: 1;
`;

const AppliedOnCell = styled(Cell)<{ clickable: boolean }>`
  ${(p) => p.theme.textStyle.uiXS};
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  flex: 1;
  display: inline;
  align-self: center;
`;

const ContentPresence = styled.span`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

const StateCell = styled(Cell)`
  width: 170px;
`;

const ActionsCell = styled(Cell)`
  width: 125px;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const ItemRow = styled(Row)`
  &:hover {
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
`;

const HandleWrapper = styled.div<{ hidden?: boolean }>`
  padding-left: ${(p) => p.theme.spacing.xs};
  display: ${(p) => (p.hidden ? "none" : "flex")};
  align-items: center;
`;

const IconHandleWrapper = styled.div`
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  svg {
    path {
      fill: ${(p) => p.theme.color.textLowEmphasis};
    }
  }
`;

export {
  CheckCell,
  NameCell,
  DescriptionCell,
  AppliedOnCell,
  StateCell,
  ActionsCell,
  ItemRow,
  ContentPresence,
  ModalContent,
  StyledActionMenu,
  HandleWrapper,
  IconHandleWrapper,
  Order,
};
