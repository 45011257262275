import React, { createContext, useState, useCallback, useContext, ReactElement } from "react";
import { moveElement } from "@ax/forms";
import { moveArrayElement } from "@ax/helpers";
import { IDataSource, IStructuredDataContent } from "@ax/types";

const initState: IReferenceState = {
  mode: "auto",
  order: "recent",
  orderDirection: "ASC",
  quantity: 0,
  items: [],
  selectedItems: [],
  fixed: [],
  search: "",
  showSelected: false,
  source: [],
  filter: [],
  sourceTitles: [],
  fullRelations: false,
  allLanguages: false,
  preferenceLanguage: false,
};

const ReferenceContext = createContext({ state: { ...initState } });

const ReferenceProvider = ({ modes, children }: { modes?: string[]; children: ReactElement }): JSX.Element => {
  const reference = useReferenceProvider(modes);

  return <ReferenceContext.Provider value={reference}>{children}</ReferenceContext.Provider>;
};

const useReference = (): any => {
  return useContext(ReferenceContext);
};

const useReferenceProvider = (modes?: string[]) => {
  const mode = modes && modes.length ? modes[0] : "auto";
  const [state, setState] = useState<IReferenceState>({ ...initState, mode });

  const setModeAndSource = useCallback(
    (mode: string, source: string[]) => {
      const defaultState = {
        ...state,
        mode,
        source,
      };
      setState(defaultState);
    },
    [setState, state]
  );

  const setReorderElements = (item: IStructuredDataContent, newIndex: number) => {
    const { selectedItems, fixed, fullRelations = false } = state;
    const newItems = moveElement(item.id, selectedItems, newIndex, "id");
    const originalItemId = item.relatedPage?.originalStructuredDataId;
    const itemId = originalItemId && state.fixed.includes(originalItemId) ? originalItemId : item.id;
    const newFixed: number[] = moveArrayElement(itemId, fixed, newIndex);
    const newState: IReferenceState = {
      ...state,
      selectedItems: newItems,
      fixed: newFixed,
      fullRelations,
    };
    setState(newState);
  };

  return {
    state: { ...state },
    setState,
    setModeAndSource,
    setReorderElements,
  };
};

export interface IReferenceState {
  mode: string;
  order: string;
  orderDirection: string;
  quantity: number;
  items: IStructuredDataContent[];
  selectedItems: IStructuredDataContent[];
  fixed: number[];
  search: string;
  showSelected: boolean;
  source: string[];
  filter: IFilter[];
  sourceTitles: IDataSource[];
  fullRelations: boolean;
  allLanguages: boolean;
  preferenceLanguage: boolean;
  lang?: number;
  site?: number;
}

export interface IFilter {
  id: number;
  label: string;
  source: string;
}

export { ReferenceProvider, useReference };
