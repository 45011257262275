import React from "react";
import { themes } from "components";

import { Icon, NoteField, Tabs } from "@ax/components";
import { INotification, ISchema } from "@ax/types";
import { useGlobalPermission } from "@ax/hooks";

import ConnectedField from "../Form/ConnectedField";

import * as S from "./style";

const noteText = "This is Global content and you cannot edit it here. To do so, you must go to the Global page";
const noteTitle = "Global content";
const errorText = "You don't have the permissions to edit the original content."

const GlobalPageForm = (props: IGlobalPageFormProps): JSX.Element => {
  const { selectedTab, setSelectedTab, schema, pageTitle, setHistoryPush, actions, header, footer } = props;
  const tabs = ["content", "config"];

  const isAllowedToEditGlobalData = useGlobalPermission("global.globalData.editAllGlobalData");

  const handleGetGlobalPage = async () => {
    actions.saveCurrentSiteInfoAction();
    await actions.getGlobalFromLocalPageAction();
  };

  const handleClick = async () => {
    if (isAllowedToEditGlobalData) {
      await handleGetGlobalPage();
      const path = "/data/pages/editor";
      setHistoryPush && setHistoryPush(path, true);
    } else {
      actions.setNotificationAction({
        type: "error",
        text: errorText,
      });
    }
  };

  const parentField = {
    title: "Parent",
    type: "AsyncSelect",
    entity: "pages",
    key: "parent",
    options: { excludeDetailPages: true },
  };

  const themeField = {
    title: "Page Options",
    type: "FieldGroup",
    key: "pageOptions",
    collapsed: false,
    fields: [
      {
        title: "Theme",
        type: "Select",
        key: "theme",
        options: themes,
        helptext: "It affects the whole page: Header, Content, and footer.",
      },
      {
        title: "Customize header and footer themes",
        type: "ConditionalField",
        key: "customizeThemes",
        defaultValue: false,
        mandatory: true,
        options: [
          {
            value: true,
            title: "Yes",
            name: "Yes",
          },
          {
            value: false,
            title: "No",
            name: "No",
          },
        ],
        fields: [
          {
            title: "Header Theme",
            type: "Select",
            key: "headerTheme",
            options: themes,
            condition: true,
          },
          {
            title: "Footer Theme",
            type: "Select",
            key: "footerTheme",
            options: themes,
            condition: true,
          },
        ],
      },
    ],
  };

  const handleRestoreHeader = () =>
    actions.restorePageNavigationAction && actions.restorePageNavigationAction("header");

  const handleRestoreFooter = () =>
    actions.restorePageNavigationAction && actions.restorePageNavigationAction("footer");

  return (
    <section data-testid="global-page-section">
      <Tabs tabs={tabs} active={selectedTab} setSelectedTab={setSelectedTab} />
      <S.NoteWrapper>
        <NoteField value={{ text: noteText, title: noteTitle }} />
      </S.NoteWrapper>
      {selectedTab === "content" && header === 0 && (
        <S.FieldWrapper>
          This page doesn&apos;t have a header. Click{" "}
          <S.Link data-testid="header-link" onClick={handleRestoreHeader}>
            here
          </S.Link>{" "}
          to restore it.
        </S.FieldWrapper>
      )}
      {selectedTab === "content" && footer === 0 && (
        <S.FieldWrapper>
          This page doesn&apos;t have a footer. Click{" "}
          <S.Link data-testid="footer-link" onClick={handleRestoreFooter}>
            here
          </S.Link>{" "}
          to restore it.
        </S.FieldWrapper>
      )}
      {selectedTab === "content" && (
        <S.DataWrapper>
          <S.IconWrapper>
            <Icon name="data" size="24" />
          </S.IconWrapper>
          <S.DataTitle>{pageTitle}</S.DataTitle>
          <S.StyledButton type="button" onClick={handleClick} buttonStyle="text">
            Edit
          </S.StyledButton>
        </S.DataWrapper>
      )}
      {selectedTab === "config" && (
        <>
          <ConnectedField
            selectedTab={selectedTab}
            key="parent"
            objKey="parent"
            field={parentField}
            componentType={schema.schemaType}
            isPage={true}
            isGlobal={true}
          />
          <ConnectedField
            selectedTab={selectedTab}
            key="pageOptions"
            objKey="pageOptions"
            field={themeField}
            componentType={schema.schemaType}
            isPage={true}
            isGlobal={true}
          />
          <ConnectedField
            selectedTab={selectedTab}
            key="integrations"
            objKey="integrations"
            field={{ type: "IntegrationsField", key: "integrations" }}
            componentType={schema.schemaType}
            isPage={true}
            isGlobal={true}
          />
        </>
      )}
    </section>
  );
};

export interface IGlobalPageFormProps {
  selectedTab: string;
  setSelectedTab(tab: string): void;
  schema: ISchema;
  pageTitle?: string;
  setHistoryPush?: (path: string, isEditor: boolean) => void;
  header?: number | null;
  footer?: number | null;
  actions: {
    getGlobalFromLocalPageAction(): Promise<void>;
    saveCurrentSiteInfoAction(): Promise<void>;
    restorePageNavigationAction(type: string): Promise<void>;
    setNotificationAction(notification: INotification): void;
  };
}

export default GlobalPageForm;
