import styled from "styled-components";

const BreadcrumbGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: ${p => `${p.theme.spacing.s} ${p.theme.spacing.m} 0 ${p.theme.spacing.m}`};
`;

const BreadcrumbItem = styled.div<{isLastItem: boolean}>`
  ${p => p.theme.textStyle.uiM};
  color: ${p => p.isLastItem ? p.theme.color.textMediumEmphasis : p.theme.color.interactive01};
  padding: 0 ${p => p.theme.spacing.xxs};
  cursor: ${p => p.isLastItem ? "default" : "pointer"};
  font-weight: 600;
`;

export { BreadcrumbGroup, BreadcrumbItem }
