import React, { memo, useEffect, useRef, useState } from "react";
import { Icon, IconAction, Gallery, Modal, Image } from "@ax/components";

import { formatBytes, getFormattedDateWithTimezone } from "@ax/helpers";
import { IImage, ISite } from "@ax/types";
import { useModal } from "@ax/hooks";

import * as S from "./style";

const ImageField = (props: IImageFieldProps) => {
  const {
    value,
    error,
    onChange,
    selectedContent,
    disabled,
    handleValidation,
    validators,
    site,
    setIsGalleryOpened,
    cropPreview = false,
    fullWidth = false,
  } = props;

  const isLinkableImage = selectedContent && selectedContent.component === "LinkableImage";
  const hasImage = value && Object.prototype.hasOwnProperty.call(value, "url");
  const isModalOpen = isLinkableImage && !hasImage;
  const { isOpen, toggleModal } = useModal(isModalOpen);
  const [previewSrc, setPreviewSrc] = useState<string>();
  const [imageHeight, setImageHeight] = useState<number>();
  const previewRef = useRef<HTMLDivElement>(null);

  const imageUrl = value ? (typeof value === "string" ? value : value.url) : "";
  const imagePosition = value && typeof value === "object" ? value.position : "center";

  useEffect(() => {
    setPreviewSrc(imageUrl);
  }, [imageUrl]);

  useEffect(() => {
    if (previewRef.current) {
      setImageHeight(previewRef.current.clientHeight);
    }
  }, [previewRef.current]);

  const handleDelete = () => {
    if (!disabled) {
      setPreviewSrc("");
      onChange({});
    }
  };

  const getImageSelected = (img: IImage | null | string) => {
    if (img) {
      const url = typeof img === "string" ? img : img.url;
      setPreviewSrc(url);
      onChange(img);
      setIsGalleryOpened && setIsGalleryOpened();
      error && handleValidation && handleValidation(url, validators);
    }
  };

  const handleClick = () => {
    if (!disabled) {
      setIsGalleryOpened && setIsGalleryOpened();
      toggleModal();
    }
  };

  const handleChange = () => {
    if (!disabled) {
      setIsGalleryOpened && setIsGalleryOpened();
      toggleModal();
    }
  };

  const handlePosition = (newPosition: string) => () => {
    if (value && typeof value === "object") {
      const newValue = { ...value, position: newPosition };
      onChange(newValue);
    }
  };

  const previewHeight = cropPreview || (imageHeight && imageHeight < 180) ? { height: 180 } : {};
  return (
    <>
      <S.FieldWrapper
        data-testid="fieldWrapper"
        error={error}
        preview={!!previewSrc}
        disabled={disabled}
        onClick={handleClick}
        fullWidth={fullWidth}
      >
        <S.IconWrapper>
          <Icon name="image" size="18" />
        </S.IconWrapper>
        <S.Text>Add image</S.Text>
      </S.FieldWrapper>
      {value && previewSrc && Object.keys(value).length > 1 && typeof value !== "string" && (
        <S.ImageDataWrapper data-testid="imageDataWrapper">
          <S.FileName>{value.name}</S.FileName>
          <S.ImageData>
            Uploaded: {!!value.published && getFormattedDateWithTimezone(value.published, "d MMM Y")}
            <br />
            {`${value.width}x${value.height}px - ${formatBytes(value.size)}`}
          </S.ImageData>
        </S.ImageDataWrapper>
      )}
      <S.Preview preview={!!previewSrc} data-testid="previewDiv" ref={previewRef}>
        {previewSrc && <Image url={previewSrc} width={320} {...previewHeight} />}
        <S.PositionWrapper>
          <S.PositionTitle>
            <Icon name="grid" size="16" />
            <span>POSITION</span>
          </S.PositionTitle>
          <S.PositionGrid>
            <S.Position onClick={handlePosition("left-top")} active={imagePosition === "left-top"} />
            <S.Position onClick={handlePosition("top")} active={imagePosition === "top"} />
            <S.Position onClick={handlePosition("right-top")} active={imagePosition === "right-top"} />
            <S.Position onClick={handlePosition("left")} active={imagePosition === "left"} />
            <S.Position onClick={handlePosition("center")} active={!imagePosition || imagePosition === "center"} />
            <S.Position onClick={handlePosition("right")} active={imagePosition === "right"} />
            <S.Position onClick={handlePosition("left-bottom")} active={imagePosition === "left-bottom"} />
            <S.Position onClick={handlePosition("bottom")} active={imagePosition === "bottom"} />
            <S.Position onClick={handlePosition("right-bottom")} active={imagePosition === "right-bottom"} />
          </S.PositionGrid>
        </S.PositionWrapper>
        <S.PreviewActions>
          <IconAction icon="change" onClick={handleChange} />
          <IconAction icon="delete" onClick={handleDelete} />
        </S.PreviewActions>
      </S.Preview>
      <Modal isOpen={isOpen} hide={toggleModal} size="XL" title="Select image">
        <Gallery getImageSelected={getImageSelected} toggleModal={toggleModal} site={site} />
      </Modal>
    </>
  );
};

export interface IImageFieldProps {
  value: IImage | null | string;
  error?: boolean;
  onChange: (value: any) => void;
  selectedContent: any;
  disabled?: boolean;
  setIsGalleryOpened?: () => void;
  handleValidation?: (value: string, validators?: Record<string, unknown>) => void;
  validators?: Record<string, unknown>;
  site: ISite;
  cropPreview?: boolean;
  fullWidth?: boolean;
}

export default memo(ImageField);
