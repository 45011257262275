import React from "react";
import styled from "styled-components";

export const Button = styled.button`
  ${(p) => p.theme.textStyle.uiButton};
  background-color: ${(p) => p.theme.color.interactive01};
  border-radius: 4px;
  z-index: 0;
  border: none;
  color: ${(p) => p.theme.colors.uiBackground02};
  min-height: calc(${(p) => p.theme.spacing.s} * 2);
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  :before {
    content: "";
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.1s;
  }
  :focus {
    outline: 1px solid ${(p) => p.theme.color.interactive02};
  }
  :hover:before {
    background-color: ${(p) => p.theme.color.overlayHoverDark};
    opacity: 1;
  }
  :focus:before {
    background-color: ${(p) => p.theme.color.overlayFocusDark};
    opacity: 1;
  }
  :active:before {
    background-color: ${(p) => p.theme.color.overlayPressedDark};
    opacity: 1;
  }
  :disabled {
    cursor: default;
    background-color: ${(p) => p.theme.color.interactiveDisabled};
    :hover:before,
    :focus:before,
    :active:before {
      background-color: transparent;
    }
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: ${(p) => p.theme.spacing.s};
    height: ${(p) => p.theme.spacing.s};
    path {
      fill: #ffffff;
    }
    circle {
      stroke: #ffffff;
    }
  }
`;

export const TextButton = styled((props) => <Button {...props} />)`
  background-color: transparent;
  color: ${(p) => p.theme.color.interactive01};
  padding: 0 ${(p) => p.theme.spacing.xs};
  :hover:before {
    background-color: ${(p) => p.theme.color.overlayHoverPrimary};
  }
  :focus:before {
    background-color: ${(p) => p.theme.color.overlayFocusPrimary};
  }
  :active:before {
    background-color: ${(p) => p.theme.color.overlayPressedPrimary};
  }
  :disabled {
    background-color: transparent;
    color: ${(p) => p.theme.color.interactiveDisabled};
    svg {
      path {
        fill: ${(p) => p.theme.color.interactiveDisabled};
      }
    }
  }
  svg {
    path {
      fill: ${(p) => p.theme.color.interactive01};
    }
  }
`;

export const LineButton = styled((props) => <Button {...props} />)<{ isInverse: boolean | undefined }>`
  background-color: ${(p) => (p.isInverse ? p.theme.color.uiMainMenuBackground : p.theme.color.interactiveBackground)};
  border: 1px solid ${(p) => (p.isInverse ? p.theme.color.interactiveInverse : p.theme.color.interactive01)};
  color: ${(p) => (p.isInverse ? p.theme.color.interactiveInverse : p.theme.color.interactive01)};
  :hover:before {
    background-color: ${(p) => (p.isInverse ? p.theme.color.uiMainMenuBackground : p.theme.color.overlayHoverPrimary)};
  }
  :focus:before {
    background-color: ${(p) => (p.isInverse ? p.theme.color.uiMainMenuBackground : p.theme.color.overlayFocusPrimary)};
  }
  :active:before {
    background-color: ${(p) =>
      p.isInverse ? p.theme.color.uiMainMenuBackground : p.theme.color.overlayPressedPrimary};
  }
  :disabled {
    background-color: transparent;
    border-color: ${(p) => p.theme.color.interactiveDisabled};
    color: ${(p) => p.theme.color.interactiveDisabled};
    svg {
      path {
        fill: ${(p) => p.theme.color.interactiveDisabled};
      }
    }
  }
  svg {
    path {
      fill: ${(p) => p.theme.color.interactive01};
    }
  }
`;

export const Label = styled.span<{ icon?: string; backIcon?: string }>`
  outline: none;
  position: relative;
  z-index: 1;
  span {
    padding-left: ${(p) => (p.icon ? `calc(${p.theme.spacing.s} + ${p.theme.spacing.xxs})` : `0`)};
    padding-right: ${(p) => (p.backIcon ? p.theme.spacing.xxs : `0`)};
  }
  padding-right: ${(p) => (p.backIcon ? p.theme.spacing.s : `0`)};
`;
