import styled from "styled-components";

const FieldWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.s};
  background-color: ${(p) => p.theme.color?.uiBackground03};
  padding: ${(p) => p.theme.spacing?.s};
  border-radius: ${(p) => p.theme.radii?.s};
  ${(p) => p.theme.textStyle?.uiXS};
  color: ${(p) => p.theme.color?.textMediumEmphasis};
`;

const Link = styled.span`
  cursor: pointer;
  font-weight: bold;
  color: ${(p) => p.theme.color?.interactive01};
`;

export { FieldWrapper, Link };
