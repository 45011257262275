import React, { useEffect, useState } from "react";

import { CheckGroup, FloatingMenu, Icon, ListTitle } from "@ax/components";
import { areEquals, isNumber, isReqOk } from "@ax/helpers";
import { checkgroups } from "@ax/api";
import { IFilterValue } from "@ax/types";

import * as S from "./style";

const CategoryFilter = (props: ICategoryFilterProps): JSX.Element => {
  const { filterItems, value, structuredData, siteID } = props;

  const filters: IFilterValue[] = [
    {
      name: "all",
      value: "all",
      title: "All",
    },
  ];

  const storedValue = value.split(",");
  const parsedValue = storedValue.map((value) => (isNumber(value) ? parseInt(value) : value));
  const [initialState] = useState(parsedValue);
  const selectAllOption = "all";
  const [selectedValue, setSelectedValue] = useState([...initialState]);
  const [options, setOptions] = useState(filters);

  const getSelectCategories = async () => {
    try {
      const site = siteID ? siteID : "global";
      const items = await checkgroups.getCheckGroupItems(site, structuredData.source);
      if (items && isReqOk(items.status)) {
        return items.data;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  useEffect(() => {
    getSelectCategories().then((items) => {
      items &&
        items.forEach((item: { value: string; title: string }) => {
          const newFilter = {
            name: item.value,
            value: item.value,
            title: item.title,
          };
          filters.push(newFilter);
        });
      setOptions(filters);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setQuery = (selection: any) => {
    if (!selection.length) {
      selection = initialState;
    }
    setSelectedValue(selection);
    const queryFilters = selection.join(",");
    filterItems("categories", queryFilters);
  };

  const isActive = !areEquals(selectedValue, [selectAllOption]);

  const Header = () => (
    <S.HeaderWrapper isActive={isActive}>
      {structuredData.title}
      <S.IconsWrapper>
        {isActive ? <Icon name="Filter" size="16" /> : <Icon name="DownArrow" size="16" />}
      </S.IconsWrapper>
    </S.HeaderWrapper>
  );

  return (
    <FloatingMenu Button={Header} position="left" closeOnSelect={false} isCheckGroup={true}>
      <ListTitle data-testid="category-filter-title">Filter by Category</ListTitle>
      <S.ChecksWrapper>
        <CheckGroup options={options} value={selectedValue} onChange={setQuery} selectAllOption={selectAllOption} />
      </S.ChecksWrapper>
    </FloatingMenu>
  );
};

export interface ICategoryFilterProps {
  filterItems(pointer: string, filter: string): void;
  value: string;
  structuredData: any;
  siteID: number | null;
}

export default CategoryFilter;
