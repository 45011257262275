import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

const FolderPanel = styled.div<{ isOpen: boolean }>`
  background-color: ${(p) => p.theme.color.uiBackground02};
  border-right: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  width: ${(p) => (p.isOpen ? "192px" : "0")};
  overflow: hidden;
  transition: width 0.8s ease-out;
  flex-shrink: 0;
`;

const FolderPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.spacing.m};
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  border-right: 1px solid ${(p) => p.theme.color.uiLine};
  padding: 0 ${(p) => p.theme.spacing.m};
  background-color: ${(p) => p.theme.color.uiBackground02};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Search = styled.div`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  border-right: 1px solid ${(p) => p.theme.color.uiLine};
  background-color: ${(p) => p.theme.color.uiBackground02};
`;

const Filters = styled.div`
  display: flex;
  height: ${(p) => p.theme.spacing.l};
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-right: ${(p) => p.theme.spacing.l};
    }
  }
`;

const TabsWrapper = styled.div`
  width: ${(p) => `calc(${p.theme.spacing.xl} * 3)`};
`;

const GalleryWrapper = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.color.uiBackground01};
  overflow: auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(144px, 144px));
  gap: ${(p) => p.theme.spacing.xxs};
  width: 100%;
  justify-content: start;
`;

const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  align-content: center;
`;

const NotificationWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${(p) => p.theme.spacing.m};
`;

const SectionHeader = styled.div`
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  align-items: center;
  justify-content: flex-end;
`;

const SectionTitle = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  display: flex;
  color: ${(p) => p.theme.colors.textHighEmphasis};
  margin-right: auto;
  font-weight: 600;
`;

const FoldersWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const FoldersGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(40px, 40px));
  gap: ${(p) => p.theme.spacing.xxs};
  justify-content: start;
`;

const FoldersIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: ${(p) => p.theme.spacing.xs};
  cursor: pointer;
`;

export {
  Wrapper,
  FolderPanel,
  FolderPanelContent,
  ContentWrapper,
  Header,
  Search,
  Filters,
  TabsWrapper,
  GalleryWrapper,
  Grid,
  LoadingWrapper,
  EmptyWrapper,
  NotificationWrapper,
  SectionWrapper,
  SectionHeader,
  SectionTitle,
  FoldersGrid,
  FoldersWrapper,
  FoldersIconWrapper
};
