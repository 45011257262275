import styled from "styled-components";

export const Table = styled.div`
  display: flex;
  height: 100%;
  padding-top: ${(p) => p.theme.spacing.m};
`;

export const Column = styled.div`
  width: calc(${(p) => p.theme.spacing.m} * 10);
  padding: 0 ${(p) => p.theme.spacing.m};
  height: calc(100% - ${(p) => p.theme.spacing.m});
  &:first-child {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 2px;
      height: 100%;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${(p) => p.theme.color.iconNonActive};
    }
    border-right: 1px solid ${(p) => p.theme.color.uiLine};
    overflow: scroll;
  }

  &:last-child {
    padding-bottom: ${(p) => p.theme.spacing.s};
  }
`;

export const NavLink = styled.a`
  text-transform: capitalize;
  &.selected {
    background-color: ${(p) => p.theme.color.overlayPressedDark};
  }
`;

export const ThumbnailWrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  padding: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.m};
  height: 220px;
  background-color: #ffffff;
`;

export const Thumbnail = styled.div<{ backgroundUrl: string | boolean }>`
  background: ${(p) => (p.backgroundUrl ? `url(${p.backgroundUrl}) no-repeat` : "")};
  background-size: cover;
  background-position: top;
  border-radius: 4px;
  min-width: calc(${(p) => p.theme.spacing.l} * 3);
  height: 100%;
`;

export const Link = styled.div<{ active: boolean }>`
  color: ${(p) => (p.active ? p.theme.color.textHighEmphasis : p.theme.color.textMediumEmphasis)};
`;

export const Actions = styled.div`
  position: fixed;
  bottom: ${(p) => p.theme.spacing.m};
  right: ${(p) => p.theme.spacing.m};
  button {
    margin-left: ${(p) => p.theme.spacing.s};
  }
`;