import { useEffect, useRef, useState } from "react";
import { ISitesQueryValues } from "@ax/types";

const useSortedListStatus = () => {
  const sortedInitialState: {
    isAscending: boolean;
    sortedByTitle: boolean;
    sortedByLastAccess: boolean;
    sortedByDateCreated: boolean;
  } = {
    isAscending: false,
    sortedByTitle: false,
    sortedByLastAccess: true,
    sortedByDateCreated: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = (defaultValues?: { order: string; liveStatus: string }): ISitesFilterQuery => {
  const initialQueryValues = {
    order: defaultValues ? defaultValues.order : "lastAccess-desc",
    liveStatus: defaultValues ? defaultValues.liveStatus : "all",
  };

  const [query, setQuery] = useState(initialQueryValues);

  const setFilterQuery = (filterValues: ISitesQueryValues) => {
    const { order, liveStatus } = filterValues;

    let filterQuery = "";
    const currentQuery = (pointer: string, values: string) => {
      return `&${pointer}=${values}`;
    };

    const isNotInitialValue = (pointer: keyof ISitesQueryValues) => {
      return filterValues[pointer] && initialQueryValues[pointer] !== filterValues[pointer];
    };

    if (isNotInitialValue("liveStatus")) {
      const query = currentQuery("liveStatus", liveStatus);
      filterQuery = `${filterQuery}${query}`;
    }

    if (isNotInitialValue("order")) {
      const query = currentQuery("order", order);
      filterQuery = `${filterQuery}${query}`;
    }

    return filterQuery;
  };

  const setFiltersSelection = (pointer: string, filter: string, orderMethod?: string) => {
    const { order, liveStatus } = query;
    const liveStatusValue: any = {
      all: "all",
      active: "online",
      offline: "offline",
    };

    const filterValues = {
      order: pointer === "order" ? `${filter}-${orderMethod}` : order,
      liveStatus: pointer === "liveStatus" ? liveStatusValue[filter] : liveStatus,
    };

    setQuery(filterValues);

    return filterValues;
  };

  const resetFilterQuery = () => setQuery(initialQueryValues);

  return {
    setFiltersSelection,
    setFilterQuery,
    resetFilterQuery,
    filterValues: query,
  };
};

const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

interface ISitesFilterQuery {
  setFiltersSelection(pointer: string, filter: string, orderMethod?: string): ISitesQueryValues;
  setFilterQuery(filterValues: ISitesQueryValues): string;
  resetFilterQuery(): void;
  filterValues: ISitesQueryValues;
}

export { useSortedListStatus, useFilterQuery, useIsMount };
