import styled from "styled-components";

const IconActionWrapper = styled.div`
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 0;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(p) => p.theme.spacing.xs};
  transform: translateY(-50%);

  &:active {
    border-radius: 50%;
    background-color: ${(p) => p.theme.color.overlayPressedPrimary};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: ${(p) => `calc((${p.theme.spacing.s} * 2) + ${p.theme.spacing.xxs})`};
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(p) => p.theme.spacing.xs};
  transform: translateY(-50%);
`;

const TypeContainer = styled.div`
  position: relative;
  width: 100%;
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  background: ${(p) => p.theme.color.uiBackground02};
  text-transform: capitalize;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  &:hover {
    ${IconActionWrapper}, ${IconWrapper} {
      opacity: 1;
    }
  }
  :focus {
    ${IconActionWrapper}, ${IconWrapper} {
      opacity: 1;
    }
  }
`;

const TypeWrapper = styled.div`
  position: relative;
`;

const TypeLabel = styled.div`
  display: block;
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const TypeName = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

const ActionMenu = styled.ul`
  padding: 0;
`;

const ActionText = styled.span`
  padding-left: ${(p) => p.theme.spacing.xs};
  margin-left: ${(p) => p.theme.spacing.xxs};
  text-transform: capitalize;
`;

const ActionItem = styled.li`
  display: flex;
  align-items: center;
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.l};
  &:focus {
    background: ${(p) => p.theme.color.overlayFocusPrimary};
  }

  &:hover {
    background: ${(p) => p.theme.color.overlayHoverPrimary};
  }

  svg {
    path {
      fill: ${(p) => p.theme.color.iconMediumEmphasis};
    }
  }
`;

const FiltersWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const ActionsFilterWrapper = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? `block` : `none`)};
  margin-top: ${(p) => p.theme.spacing.s};
  padding-top: ${(p) => p.theme.spacing.m};
  border-top: 1px solid ${(p) => p.theme.color.uiLine};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${(p) => p.theme.spacing.m};
`;

const ChecksWrapper = styled.div`
  max-height: ${(p) => `calc(${p.theme.spacing.l} * 4)`};
  overflow: auto;
`;

const SearchWrapper = styled.div`
  margin-top: ${(p) => `-${p.theme.spacing.s}`};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

export {
  IconActionWrapper,
  IconWrapper,
  TypeContainer,
  TypeWrapper,
  TypeLabel,
  TypeName,
  ActionItem,
  ActionMenu,
  ActionText,
  ActionsFilterWrapper,
  FiltersWrapper,
  ButtonWrapper,
  ChecksWrapper,
  SearchWrapper,
};
