import styled from "styled-components";

const Slider = styled.div`
  position: relative;
  margin-left: ${(p) => p.theme.spacing.s};
  margin-right: ${(p) => p.theme.spacing.s};
  padding-top: 28px;
  min-width: calc(${(p) => p.theme.spacing.xl} * 4);
  max-width: calc(${(p) => p.theme.spacing.xl} * 6);
`;

const Input = styled.input.attrs({ type: "range" })`
  width: 100%;
  background-color: transparent;
  -webkit-appearance: none;

  &:focus {
    outline: none;

    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb {
      box-shadow: 0px 0px 0px 10px rgb(80, 87, 255, 0.12);
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background: ${(p) => p.theme.color.interactive01};
    border-radius: 2px;
    border: 0px solid #000000;
  }

  &::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid ${(p) => p.theme.color.interactive01};
    height: 16px;
    width: 16px;
    border-radius: 25px;
    background: ${(p) => p.theme.color.interactiveBackground};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;

    &:hover {
      box-shadow: 0px 0px 0px 10px rgb(80, 87, 255, 0.12);
    }

    &:active {
      background: ${(p) => p.theme.color.interactive01};
    }
  }

  &:focus::-webkit-slider-runnable-track {
    background: ${(p) => p.theme.color.interactive01};
  }

  &::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background: ${(p) => p.theme.color.interactive01};
    border-radius: 2px;
    border: 0px solid #000000;
  }

  &::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid ${(p) => p.theme.color.interactive01};
    height: 16px;
    width: 16px;
    border-radius: 25px;
    background: ${(p) => p.theme.color.interactiveBackground};
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 0px 10px rgb(80, 87, 255, 0.12);
    }

    &:active {
      background: ${(p) => p.theme.color.interactive01};
    }
  }

  &::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: ${(p) => p.theme.color.interactive01};
    border: 0px solid #000000;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000;
  }

  &::-ms-fill-upper {
    background: ${(p) => p.theme.color.interactive01};
    border: 0px solid #000000;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000;
  }

  &::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid ${(p) => p.theme.color.interactive01};
    height: 16px;
    width: 16px;
    border-radius: 25px;
    background: ${(p) => p.theme.color.interactiveBackground};
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 0px 10px rgb(80, 87, 255, 0.12);
    }

    &:active {
      background: ${(p) => p.theme.color.interactive01};
    }
  }

  &:focus::-ms-fill-lower {
    background: ${(p) => p.theme.color.interactive01};
  }

  &:focus::-ms-fill-upper {
    background: ${(p) => p.theme.color.interactive01};
  }
`;

const Bubble = styled.output`
  position: absolute;
  top: 0;
  background-color: ${(p) => p.theme.color.interactive01};
  color: ${(p) => p.theme.color.textHighEmphasisInverse};
  border-radius: ${(p) => p.theme.radii.s};
  padding: ${(p) => p.theme.spacing.xxs};
  transform: translateX(-50%);

  &::after {
    content: '';
    position: absolute;
    transform: translateX(-50%);
    border-style: solid;
    border-color: ${(p) => p.theme.color.interactive01} transparent;
    border-width: 3px 3px 0 3px;
    bottom: -3px;
    left: 50%;
  }
`;

export { Slider, Input, Bubble };
