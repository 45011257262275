import React from "react";
import { IBulkAction } from "@ax/types";

import TableHeader from "./TableHeader";

import * as S from "./style";

const BulkListHeader = (props: IBulkHeaderProps): JSX.Element => {
  const { showBulk, checkState, selectItems, selectAllItems, totalItems, bulkActions } = props;

  return showBulk ? (
    <S.BulkWrapper>
      <S.StyledBulkSelectionOptions
        isScrolling={false}
        checkState={checkState}
        actions={bulkActions}
        selectItems={selectItems}
        totalItems={totalItems}
      />
    </S.BulkWrapper>
  ) : (
    <TableHeader isScrolling={false} selectAllItems={selectAllItems} checkState={checkState} totalItems={totalItems} />
  );
};

export interface IBulkHeaderProps {
  showBulk: boolean;
  checkState: any;
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  bulkActions: IBulkAction[];
}

export default BulkListHeader;
