import { useState, useEffect } from "react";

import { areEquals } from "@ax/helpers";
import { ICheck } from "@ax/types";

const useBulkSelection = (itemIDs: any[]) => {
  const selectedItemsInitialState: { all: any[]; notPublished: any[]; published: any[]; drafts: any[] } = {
    all: [],
    notPublished: [],
    published: [],
    drafts: [],
  };

  const [items, setItems] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState(selectedItemsInitialState);
  const [checkState, setCheckState] = useState<Record<string, boolean>>({
    isAllSelected: false,
    indeterminate: false,
  });

  useEffect(() => {
    const isAllSelected = areAllItemsSelected(items);
    const indeterminate = !isAllSelected && areItemsSelected(items);

    setCheckState({ isAllSelected, indeterminate });

    // eslint-disable-next-line
  }, [selectedItems, items]);

  useEffect(() => {
    if (items.length !== itemIDs.length) {
      setItems(itemIDs);
    }

    // eslint-disable-next-line
  }, [itemIDs]);

  const isSelected = (value: any) => selectedItems.all.includes(value);
  const areAllItemsSelected = (itemsAvailable: any) =>
    selectedItems.all.length > 0 && areEquals(itemsAvailable, selectedItems.all);
  const areItemsSelected = (itemsAvailable: any) =>
    !!selectedItems.all.find((element: any) => itemsAvailable.includes(element));
  const resetBulkSelection = () => setSelectedItems(selectedItemsInitialState);

  const addToBulkSelection = (item: ICheck, callback?: any) => {
    const { value, isChecked } = item;

    let bulkSelection: any = [...selectedItems.all];

    isChecked ? bulkSelection.push(value) : (bulkSelection = bulkSelection.filter((id: any) => id !== value));

    if (callback) {
      const filteredItems = callback(bulkSelection);
      setSelectedItems(filteredItems);
    } else {
      setSelectedItems({ ...selectedItems, all: bulkSelection });
    }
  };

  const selectAllItems = (callback?: any) => {
    const bulkSelection: any = [...selectedItems.all];
    itemIDs.forEach((item: any) => {
      !bulkSelection.includes(item) && bulkSelection.push(item);

      if (callback) {
        const filteredItems = callback(bulkSelection);
        setSelectedItems(filteredItems);
      } else {
        setSelectedItems({ ...selectedItems, all: bulkSelection });
      }
    });
  };

  return {
    resetBulkSelection,
    selectedItems,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
  };
};

export { useBulkSelection };
