import styled from "styled-components";

const IconWrapper = styled.div`
  background-color: ${(p) => p.theme.color.uiBackground03};
  border-radius: 4px;
  height: calc(${(p) => p.theme.spacing.s} * 2);
  width: calc(${(p) => p.theme.spacing.s} * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(p) => p.theme.spacing.xs};
`;

const HiddenButtonsWrapper = styled.span`
  margin-left: auto;
  display: none;
  opacity: 0;
`;

const Component = styled.span<{ disabled?: boolean }>`
  color: ${(p) => p.theme.color.textHighEmphasis};
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  background: ${(p) => p.theme.color.uiBackground02};
  border: 1px solid transparent;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  padding: calc(${(p) => p.theme.spacing.xs} - 1px) ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radii.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  ${(p) => p.theme.textStyle.fieldLabel};
  text-align: left;
  cursor: ${(p) => (p.disabled ? `default` : `pointer`)};

  &:hover ${HiddenButtonsWrapper} {
    display: flex;
    opacity: 1;
  }

  &:focus,
  &:active {
    background: ${(p) => (p.disabled ? p.theme.color.uiBackground02 : p.theme.color.overlayFocusPrimary)};
    border: 1px solid ${(p) => (p.disabled ? `transparent` : p.theme.color.interactive01)};
    outline: none;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(p) => p.theme.spacing.xs};
  justify-content: center;
`;

const Label = styled.span<{ containerInfo: boolean | undefined; disabled?: boolean }>`
  margin: ${(p) => p.theme.spacing.xxs} 0;
  ${(p) => (p.containerInfo ? p.theme.textStyle.headingXS : null)};
  color: ${(p) =>
    p.disabled
      ? p.theme.color.interactiveDisabled
      : p.containerInfo
      ? p.theme.color.textMediumEmphasis
      : p.theme.color.textHighEmphasis};
`;

const Title = styled.span<{ disabled?: boolean }>`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => (p.disabled ? p.theme.color.interactiveDisabled : p.theme.color.textMediumEmphasis)};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const ButtonsWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const HandleWrapper = styled.div<{ hidden?: boolean }>`
  display: ${(p) => (p.hidden ? "none" : "flex")};
  align-items: center;
`;

const IconHandleWrapper = styled.div`
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  svg {
    path {
      fill: ${(p) => p.theme.color.textLowEmphasis};
    }
  }
`;

export {
  IconWrapper,
  Component,
  Text,
  Label,
  Title,
  ButtonsWrapper,
  HiddenButtonsWrapper,
  HandleWrapper,
  IconHandleWrapper,
};
