import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { IAnalytics, INavItem, IRootState } from "@ax/types";
import { appActions } from "@ax/containers/App";
import { analyticsActions } from "@ax/containers/Analytics";
import { MainWrapper, Loading, Nav } from "@ax/components";
import { useIsDirty } from "@ax/hooks";
import { RouteLeavingGuard } from "@ax/guards";

import { Dimensions, Groups, ScriptCode, Warning } from "./atoms";
import * as S from "./style";

const Analytics = (props: IProps): JSX.Element => {
  const {
    navItems,
    currentNavItem,
    isSaving,
    isLoading,
    analytics,
    getAnalytics,
    setHistoryPush,
    site,
    updateScriptCode,
  } = props;
  const [showWarning, setShowWarning] = useState(false);
  const [scriptCode, setScriptCode] = useState(analytics.scriptCode);
  const { isDirty, resetDirty } = useIsDirty(scriptCode);

  useEffect(() => {
    site?.id && getAnalytics(site.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.id]);

  useEffect(() => {
    setScriptCode(analytics.scriptCode);
    setShowWarning(!analytics.siteScriptCodeExists);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics]);

  const handleSave = async () => {
    const isSaved = await updateScriptCode(scriptCode, site.id);
    if (isSaved) resetDirty();
  };

  const rightButtonProps = {
    label: isSaving ? "Saving" : "Save",
    disabled: isSaving,
    action: () => handleSave(),
  };

  const setRoute = (path: string) => setHistoryPush(path);
  const modalText = (
    <>
      Analytics Script Code <strong>is not saved</strong>.{" "}
    </>
  );

  const analyticsSettings = (
    <>
      {showWarning ? (
        <Warning setShowWarning={setShowWarning} />
      ) : (
        <S.AnalyticsWrapper>
          <ScriptCode scriptCode={scriptCode} setScriptCode={setScriptCode} />
          {analytics.dimensions && <Dimensions dimensions={analytics.dimensions} />}
          {analytics.groups && <Groups groups={analytics.groups} />}
        </S.AnalyticsWrapper>
      )}
    </>
  );

  return (
    <>
      <RouteLeavingGuard when={isDirty} action={setRoute} text={modalText} />
      <MainWrapper backLink={false} title="Analytics Settings" rightButton={rightButtonProps}>
        <S.Wrapper>
          <Nav current={currentNavItem} items={navItems} onClick={setRoute} />
          <S.ContentWrapper>{isLoading ? <Loading /> : analyticsSettings}</S.ContentWrapper>
        </S.Wrapper>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  isSaving: state.app.isSaving,
  isLoading: state.app.isLoading,
  analytics: state.analytics,
  site: state.sites.currentSiteInfo,
});

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  getAnalytics: analyticsActions.getAnalytics,
  updateScriptCode: analyticsActions.updateScriptCode,
};

interface IAnalyticsProps {
  navItems: INavItem[];
  currentNavItem: INavItem;
  isSaving: boolean;
  isLoading: boolean;
  analytics: IAnalytics;
  site: any;
}

interface IDispatchProps {
  getAnalytics(siteId?: number): void;
  setHistoryPush(path: string, isEditor?: boolean): void;
  updateScriptCode(scriptCode: string, siteId: number): Promise<boolean>;
}

type IProps = IAnalyticsProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
