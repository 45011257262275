import React, { useEffect, useState } from "react";
import { CheckGroup, FloatingMenu, Icon, ListTitle } from "@ax/components";
import { areEquals } from "@ax/helpers";

import * as S from "./style";

const TypeFilter = (props: ITypeFilterProps): JSX.Element => {
  const { filterItems, filters, pointer, value } = props;

  const initialState = ["all"];
  const selectAllOption = "all";
  const [selectedValue, setSelectedValue] = useState(initialState);

  useEffect(() => {
    value && setSelectedValue([value]);
  }, [value]);

  const setFilterQuery = (selection: any) => {
    if (!selection.length) {
      selection = initialState;
    }
    setSelectedValue(selection);
    const queryFilters = selection.join("&");
    filterItems(pointer, queryFilters);
  };

  const isActive = !areEquals(selectedValue, initialState);

  const Header = () => (
    <S.Types isActive={isActive}>
      Types
      <S.IconsWrapper>
        {isActive ? <Icon name="Filter" size="16" /> : <Icon name="DownArrow" size="16" />}
      </S.IconsWrapper>
    </S.Types>
  );

  return (
    <FloatingMenu Button={Header} position="left" closeOnSelect={true} isCheckGroup={true}>
      <ListTitle data-testid="type-filter-title">Filter by type</ListTitle>
      <S.ChecksWrapper>
        <CheckGroup
          options={filters}
          value={selectedValue}
          onChange={setFilterQuery}
          selectAllOption={selectAllOption}
          multipleSelection={false}
        />
      </S.ChecksWrapper>
    </FloatingMenu>
  );
};

interface IFilter {
  name: string;
  value: string;
  title: string;
}

export interface ITypeFilterProps {
  filterItems(pointer: string, filter: string): void;
  filters: IFilter[];
  pointer: string;
  value: any;
}

export default TypeFilter;
