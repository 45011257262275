import { IDataLanguage, IStructuredDataContent } from "@ax/types";

const getAllLangCategoriesIds = (
  currentCategories: IStructuredDataContent[],
  selectedItems: Record<string, number[]>,
  getAllVersions: boolean
): number[] => {
  let langsCategoryIds: number[] = [];
  if (getAllVersions) {
    const selectedCategories = currentCategories.filter((category) => selectedItems.all.includes(category.id));
    langsCategoryIds = selectedCategories.reduce((ids: number[], category: IStructuredDataContent) => {
      const langsCategoryIds = category.dataLanguages.map((lang: IDataLanguage) => lang.id);
      return [...ids, ...langsCategoryIds];
    }, []);
  }

  return langsCategoryIds.length ? langsCategoryIds : selectedItems.all;
};

export { getAllLangCategoriesIds }
