import { AxiosResponse } from "axios";
import { IGetGlobalPagesParams, IGetSitePagesParams, IGetSitesParams } from "@ax/types";
import { template } from "./config";
import { sendRequest, IServiceConfig, sendInitialRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_ALL_SITES: {
    ...template,
    endpoint: "/sites",
    method: "GET",
  },
  GET_SITE_INFO: {
    ...template,
    endpoint: "/site/",
    method: "GET",
  },
  GET_SITE_PAGES: {
    ...template,
    endpoint: ["/site/", "/pages"],
    method: "GET",
  },
  GET_GLOBAL_PAGES: {
    ...template,
    endpoint: ["/site/global/pages"],
    method: "GET",
  },
  GET_GLOBAL_PAGES_LIGHT: {
    ...template,
    endpoint: ["/site/global/pages/light"],
    method: "GET",
  },
  GET_SITE_PAGES_LIGHT: {
    ...template,
    endpoint: ["/site/", "/pages/light"],
    method: "GET",
  },
  GET_SITE_STRUCTURED_PAGES: {
    ...template,
    endpoint: ["/site/", "/pages/structured-data/"],
    method: "GET",
  },
  GET_SITE_IMAGES: {
    ...template,
    endpoint: ["/site/", "/images"],
    method: "GET",
  },
  GET_SITE_LANGUAGES: {
    ...template,
    endpoint: ["/site/", "/languages"],
    method: "GET",
  },
  DELETE_SITE: {
    ...template,
    endpoint: "/site/",
    method: "DELETE",
  },
  CREATE_SITE: {
    ...template,
    endpoint: "/site",
    method: "POST",
  },
  UPDATE_SITE_SETTINGS: {
    ...template,
    endpoint: ["/site/", "/settings"],
    method: "PUT",
  },
  PUBLISH_SITE: {
    ...template,
    endpoint: ["/site/", "/publish"],
    method: "POST",
  },
  PUBLISH_SITE_BULK: {
    ...template,
    endpoint: "/site/publish/bulk",
    method: "POST",
  },
  UNPUBLISH_SITE: {
    ...template,
    endpoint: ["/site/", "/unpublish"],
    method: "POST",
  },
  UNPUBLISH_SITE_BULK: {
    ...template,
    endpoint: "/site/unpublish/bulk",
    method: "POST",
  },
  IMPORT_PAGE: {
    ...template,
    endpoint: ["/site/", "/pages/global/imports/"],
    method: "POST",
  },
  IMPORT_PAGE_BULK: {
    ...template,
    endpoint: ["/site/", "/pages/global/imports/bulk"],
    method: "POST",
  },
  REMOVE_USERS_BULK: {
    ...template,
    endpoint: ["/site/", "/restrict"],
    method: "PUT",
  },
  REMOVE_PAGE: {
    ...template,
    endpoint: ["/site/", "/pages/global/imports/"],
    method: "DELETE",
  },
  REMOVE_PAGE_BULK: {
    ...template,
    endpoint: ["/site/", "/pages/global/imports/bulk"],
    method: "DELETE",
  },
};

const getAllSites = async (params: IGetSitesParams = { recentSitesNumber: 7 }) => {
  const { host, endpoint } = SERVICES.GET_ALL_SITES;
  const { token, language, recentSitesNumber, searchQuery, filterQuery, page, itemsPerPage, pagination } = params;

  const queryParams =
    language && recentSitesNumber
      ? `?recentSitesNumber=${recentSitesNumber}&language=${language}`
      : (language && `?language=${language}`) || `?recentSitesNumber=${recentSitesNumber}`;

  SERVICES.GET_ALL_SITES.dynamicUrl = `${host}${endpoint}${queryParams}`;

  if (pagination) {
    const paginationParams = `&pagination=true&page=${page}&itemsPerPage=${itemsPerPage}`;
    SERVICES.GET_ALL_SITES.dynamicUrl = `${SERVICES.GET_ALL_SITES.dynamicUrl}${paginationParams}`;
  }
  if (filterQuery) {
    SERVICES.GET_ALL_SITES.dynamicUrl = `${SERVICES.GET_ALL_SITES.dynamicUrl}${filterQuery}`;
  }
  if (searchQuery && searchQuery.trim() !== "") {
    SERVICES.GET_ALL_SITES.dynamicUrl = `${SERVICES.GET_ALL_SITES.dynamicUrl}${searchQuery}`;
  }
  
  if (token) {
    return sendInitialRequest(SERVICES.GET_ALL_SITES, token, { recentSitesNumber: 7 });
  } else {
    return sendRequest(SERVICES.GET_ALL_SITES);
  }
};

const getSiteInfo = async (siteID: number) => {
  const { host, endpoint } = SERVICES.GET_SITE_INFO;
  SERVICES.GET_SITE_INFO.dynamicUrl = `${host}${endpoint}${siteID}`;

  return sendRequest(SERVICES.GET_SITE_INFO);
};

const getStructuredSitePages = async (params: IGetSitePagesParams, structuredData: string, filterQuery?: string) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_SITE_STRUCTURED_PAGES;

  const { siteID, deleted, page, itemsPerPage, query, format } = params;

  const filters = filterQuery ? `${filterQuery}&` : "?";

  SERVICES.GET_SITE_STRUCTURED_PAGES.dynamicUrl = `${host}${prefix}${siteID}${suffix}${structuredData}${filters}deleted=${deleted}&page=${page}&itemsPerPage=${itemsPerPage}`;

  if (query && query.trim() !== "")
    SERVICES.GET_SITE_STRUCTURED_PAGES.dynamicUrl = SERVICES.GET_SITE_STRUCTURED_PAGES.dynamicUrl + `&query=${query}`;
  if (format)
    SERVICES.GET_SITE_STRUCTURED_PAGES.dynamicUrl = SERVICES.GET_SITE_STRUCTURED_PAGES.dynamicUrl + `&format=${format}`;

  return sendRequest(SERVICES.GET_SITE_STRUCTURED_PAGES);
};

const getSitePages = async (params: IGetSitePagesParams, filterQuery?: string): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_SITE_PAGES;

  const { siteID, deleted, page, itemsPerPage, query, filterStructuredData, lang, format, filterPages, ignoreLang } =
    params;

  const filters = filterQuery ? `${filterQuery}&` : "?";

  SERVICES.GET_SITE_PAGES.dynamicUrl = `${host}${prefix}${siteID}${suffix}${filters}deleted=${deleted}`;

  if (page && itemsPerPage)
    SERVICES.GET_SITE_PAGES.dynamicUrl =
      SERVICES.GET_SITE_PAGES.dynamicUrl + `&page=${page}&itemsPerPage=${itemsPerPage}`;
  if (query && query.trim() !== "")
    SERVICES.GET_SITE_PAGES.dynamicUrl = SERVICES.GET_SITE_PAGES.dynamicUrl + `&query=${query}`;
  if (filterStructuredData)
    SERVICES.GET_SITE_PAGES.dynamicUrl =
      SERVICES.GET_SITE_PAGES.dynamicUrl + `&filterStructuredData=${filterStructuredData}`;
  if (format) SERVICES.GET_SITE_PAGES.dynamicUrl = SERVICES.GET_SITE_PAGES.dynamicUrl + `&format=${format}`;
  if (filterPages)
    SERVICES.GET_SITE_PAGES.dynamicUrl = SERVICES.GET_SITE_PAGES.dynamicUrl + `&filterPages=${filterPages.join(",")}`;
  if (ignoreLang) SERVICES.GET_SITE_PAGES.dynamicUrl = SERVICES.GET_SITE_PAGES.dynamicUrl + `&ignoreLang=${ignoreLang}`;

  const dataHeader = {
    ...(lang && { lang }),
  };

  return sendRequest(SERVICES.GET_SITE_PAGES, null, dataHeader);
};

const getGlobalPages = async (params: IGetGlobalPagesParams, filterQuery?: string): Promise<AxiosResponse> => {
  const { host, endpoint } = SERVICES.GET_GLOBAL_PAGES;

  const { deleted, page, itemsPerPage, query, filterStructuredData, format } = params;

  SERVICES.GET_GLOBAL_PAGES.dynamicUrl = `${host}${endpoint}?deleted=${deleted}&page=${page}&itemsPerPage=${itemsPerPage}`;
  if (query && query.trim() !== "") SERVICES.GET_GLOBAL_PAGES.dynamicUrl += `&query=${query}`;
  if (filterStructuredData) SERVICES.GET_GLOBAL_PAGES.dynamicUrl += `&filterStructuredData=${filterStructuredData}`;
  if (filterQuery) SERVICES.GET_GLOBAL_PAGES.dynamicUrl += filterQuery;
  if (format) SERVICES.GET_GLOBAL_PAGES.dynamicUrl = SERVICES.GET_GLOBAL_PAGES.dynamicUrl + `&format=${format}`;

  return sendRequest(SERVICES.GET_GLOBAL_PAGES);
};

const getGlobalPagesLight = async (params: IGetGlobalPagesParams): Promise<AxiosResponse> => {
  const { host, endpoint } = SERVICES.GET_GLOBAL_PAGES_LIGHT;

  const { deleted, page, itemsPerPage, query, filterStructuredData, excludeSite, liveStatus } = params;

  SERVICES.GET_GLOBAL_PAGES_LIGHT.dynamicUrl = `${host}${endpoint}?deleted=${deleted}&page=${page}&itemsPerPage=${itemsPerPage}&excludeSite=${excludeSite}&liveStatus=${liveStatus}`;

  if (query && query.trim() !== "") SERVICES.GET_GLOBAL_PAGES_LIGHT.dynamicUrl += `&query=${query}`;
  if (filterStructuredData)
    SERVICES.GET_GLOBAL_PAGES_LIGHT.dynamicUrl += `&filterStructuredData=${filterStructuredData}`;

  return sendRequest(SERVICES.GET_GLOBAL_PAGES_LIGHT);
};

const importPage = async (siteId: number, pageId: number): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.IMPORT_PAGE;

  SERVICES.IMPORT_PAGE.dynamicUrl = `${host}${prefix}${siteId}${suffix}${pageId}`;

  return sendRequest(SERVICES.IMPORT_PAGE);
};

const importPageBulk = async (siteID: number, ids: number[]): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.IMPORT_PAGE_BULK;

  SERVICES.IMPORT_PAGE_BULK.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  return sendRequest(SERVICES.IMPORT_PAGE_BULK, { ids });
};

const removePage = async (siteId: number, pageId: number): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.REMOVE_PAGE;

  SERVICES.REMOVE_PAGE.dynamicUrl = `${host}${prefix}${siteId}${suffix}${pageId}`;

  return sendRequest(SERVICES.REMOVE_PAGE);
};

const removeUsersBulk = async (siteId: number, users: number[]): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.REMOVE_USERS_BULK;

  SERVICES.REMOVE_USERS_BULK.dynamicUrl = `${host}${prefix}${siteId}${suffix}`;

  return sendRequest(SERVICES.REMOVE_USERS_BULK, { users });
};

const removePageBulk = async (siteID: number, ids: number[]): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.REMOVE_PAGE_BULK;

  SERVICES.REMOVE_PAGE_BULK.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;

  return sendRequest(SERVICES.REMOVE_PAGE_BULK, { ids });
};

const getSitePagesLight = async (params: IGetSitePagesParams): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_SITE_PAGES_LIGHT;

  const { siteID, deleted, page, itemsPerPage, query, lang, filterStructuredData } = params;

  SERVICES.GET_SITE_PAGES_LIGHT.dynamicUrl = `${host}${prefix}${siteID}${suffix}?deleted=${deleted}&page=${page}&itemsPerPage=${itemsPerPage}&filterStructuredData=${filterStructuredData}`;
  if (query) {
    SERVICES.GET_SITE_PAGES_LIGHT.dynamicUrl = `${host}${prefix}${siteID}${suffix}?deleted=${deleted}&page=${page}&itemsPerPage=${itemsPerPage}&query=${query}`;
  }

  const dataHeader = {} as { lang: number };
  if (lang) dataHeader.lang = lang;

  return sendRequest(SERVICES.GET_SITE_PAGES_LIGHT, null, dataHeader);
};

const getSiteImages = async (
  site: number | string,
  page: number,
  itemsPerPage: number | null,
  thumbWidth?: number,
  thumbHeight?: number,
  query?: string,
  search?: string
): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_SITE_IMAGES;

  const items = itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : "";
  const thumb = thumbWidth && thumbHeight ? `&thumbWidth=${thumbWidth}&thumbHeight=${thumbHeight}` : "";
  SERVICES.GET_SITE_IMAGES.dynamicUrl = `${host}${prefix}${site}${suffix}?page=${page}${items}${thumb}${query}`;
  if (search) SERVICES.GET_SITE_IMAGES.dynamicUrl = SERVICES.GET_SITE_IMAGES.dynamicUrl + `&query=${search}`;

  return sendRequest(SERVICES.GET_SITE_IMAGES);
};

const deleteSite = async (siteID: number) => {
  const { host, endpoint } = SERVICES.DELETE_SITE;

  SERVICES.DELETE_SITE.dynamicUrl = `${host}${endpoint}${siteID}`;

  return sendRequest(SERVICES.DELETE_SITE);
};

const createSite = async (data: any) => sendRequest(SERVICES.CREATE_SITE, { ...data });

const updateSite = (siteID: number, data: any) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.UPDATE_SITE_SETTINGS;

  SERVICES.UPDATE_SITE_SETTINGS.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;
  return sendRequest(SERVICES.UPDATE_SITE_SETTINGS, { ...data });
};

const publishSite = (siteID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.PUBLISH_SITE;

  SERVICES.PUBLISH_SITE.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;
  return sendRequest(SERVICES.PUBLISH_SITE);
};

const publishSiteBulk = (ids: number[]) => sendRequest(SERVICES.PUBLISH_SITE_BULK, { sites: ids });

const unpublishSite = (siteID: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.UNPUBLISH_SITE;

  SERVICES.UNPUBLISH_SITE.dynamicUrl = `${host}${prefix}${siteID}${suffix}`;
  return sendRequest(SERVICES.UNPUBLISH_SITE);
};
const unpublishSiteBulk = (ids: number[]) => sendRequest(SERVICES.UNPUBLISH_SITE_BULK, { sites: ids });

export default {
  getAllSites,
  getSiteInfo,
  getSitePages,
  getSitePagesLight,
  getStructuredSitePages,
  getSiteImages,
  deleteSite,
  createSite,
  updateSite,
  publishSite,
  publishSiteBulk,
  unpublishSite,
  unpublishSiteBulk,
  getGlobalPages,
  getGlobalPagesLight,
  importPage,
  importPageBulk,
  removePage,
  removePageBulk,
  removeUsersBulk,
};
