import { useState } from "react";

const useSortedListStatus = () => {
  const sortedInitialState: { isAscending: boolean; sortedByTitle: boolean } = {
    isAscending: false,
    sortedByTitle: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = () => {
  const initialQueryValues = {
    order: "",
    filterApplication: "all",
    filterState: "all",
  };

  const [query, setQuery] = useState(initialQueryValues);

  const setFilterQuery = (filterValues: any) => {
    const { order, filterApplication, filterState } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: string) => {
      return filterQuery.concat(`&${pointer}=${values}`);
    };

    const isNotInitialValue = (pointer: "order" | "filterApplication" | "filterState") => {
      return filterValues[pointer] && initialQueryValues[pointer] !== filterValues[pointer];
    };

    if (isNotInitialValue("order")) filterQuery = currentQuery("order", order);
    if (isNotInitialValue("filterApplication")) filterQuery = currentQuery("filterApplication", filterApplication);
    if (isNotInitialValue("filterState")) filterQuery = currentQuery("filterState", filterState);

    return filterQuery;
  };

  const setFiltersSelection = (pointer: string, filter: string, isAscendent?: boolean) => {
    const { order, filterApplication, filterState } = query;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues = {
      order: pointer === "order" ? `${filter}-${orderMethod}` : order,
      filterApplication: pointer === "filterApplication" ? filter : filterApplication,
      filterState: pointer === "filterState" ? filter : filterState,
    };

    setQuery(filterValues);

    return filterValues;
  };

  const resetFilterQuery = () => setQuery(initialQueryValues);

  const isFiltered = Object.keys(query).some((key: any) => query[key as never] !== initialQueryValues[key as never]);

  return {
    setFiltersSelection,
    setFilterQuery,
    resetFilterQuery,
    filterValues: query,
    isFiltered,
  };
};

export { useSortedListStatus, useFilterQuery };
