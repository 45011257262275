import React, { useContext } from "react";

import { IRouter } from "@ax/routes";
import { Restricted } from "@ax/guards";
import { Icon } from "@ax/components";

import NavSubItem from "./NavSubItem";
import { NavContext } from "./../context";

import * as S from "./style";

const NavItem = (props: IProps) => {
  const {
    route: { component, path, name, icon, routesGroups, onClick, url, target = "_blank" },
    location,
    type,
    isOpened,
    setHistoryPush,
  } = props;

  const { state, toggleSubmenu } = useContext(NavContext)!;

  const isSelected = location && (location.pathname === path || location.pathname.includes(path));
  const isSelectedClass = isSelected ? "selected" : "";

  const hasSubmenu = routesGroups && routesGroups.length > 0;

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (url) {
      window.open(url, target);
    } else if (component && location.pathname !== path) {
      setHistoryPush(path);
    } else if (isOpened && hasSubmenu) {
      toggleSubmenu(name);
    }
  };

  const subMenuIcon = state[name] ? "UpArrow" : "DownArrow";

  const DropdownClass = isOpened ? "inline" : "floating";

  return (
    <S.Item key={name} type={type} isOpened={isOpened} isSubmenuOpen={state[name]} active={isSelected}>
      <S.NavLink onClick={handleClick} className={isSelectedClass}>
        <S.Icon>{icon && <Icon name={icon} size="24px" />}</S.Icon>
        <S.LinkName active={isSelected || state[name]}>{name}</S.LinkName>
        {hasSubmenu && isOpened && (
          <S.Arrow>
            <Icon name={subMenuIcon} />
          </S.Arrow>
        )}
      </S.NavLink>
      <S.Dropdown isSubmenu={hasSubmenu} isSubmenuOpen={state[name]} className={DropdownClass} type={type}>
        <S.Heading>{name}</S.Heading>
        {routesGroups &&
          routesGroups.map((subGroup: any, groupI: any) => (
            <React.Fragment key={groupI}>
              {subGroup.routes && (
                <>
                  {subGroup.routes.map((subLink: any, subI: any) => {
                    const goToSubLink = () => {
                      if (location.pathname !== subLink.path) {
                        const isEditor = subLink.isEditor ? true : false;
                        setHistoryPush(subLink.path, isEditor);
                      }
                      if (subLink.setDataAction) {
                        const setCurrentData = (id: string) => subLink.setDataAction(id);
                        setCurrentData(subLink.item.id);
                      }
                    };

                    const isSelectedSubItem = location.pathname === subLink.path ? true : false;

                    const navSubItem = (
                      <NavSubItem key={subI} active={isSelectedSubItem} type={type}>
                        <S.NavLink onClick={goToSubLink}>
                          <S.LinkName active={isSelected}>{subLink.name}</S.LinkName>
                        </S.NavLink>
                      </NavSubItem>
                    );

                    return subLink.permission ? (
                      <Restricted to={subLink.permission} key={subI}>{navSubItem}</Restricted>
                    ) : (
                      navSubItem
                    );
                  })}
                </>
              )}
            </React.Fragment>
          ))}
      </S.Dropdown>
    </S.Item>
  );
};

interface IProps {
  route: IRouter;
  location?: any;
  type: string;
  isOpened: boolean;
  setHistoryPush(path: string, isEditor?: boolean): void;
}

export default NavItem;
