import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";

import { useModal, useToast } from "@ax/hooks";
import { isEmptyContainer, getDisplayName, trimText } from "@ax/helpers";
import { Icon, SideModal, Toast } from "@ax/components";
import EmptyContainer from "./EmptyContainer";
import { ArrayContainerButtons, ContainerButtons } from "./atoms";

import * as S from "./style";

const ComponentContainer = (props: IComponentContainerProps): JSX.Element => {
  const {
    goTo,
    objKey,
    editorID,
    text,
    moduleTitle,
    selectedContent,
    whiteList,
    isArray,
    value,
    actions,
    title,
    categories,
    disabled,
    canDuplicate,
    parentKey,
    theme,
    canReplace,
    actionReplace,
    arrayLength,
    innerRef,
    provided,
    isModule,
  } = props;

  const { isVisible, toggleToast, setIsVisible } = useToast();

  let deleteModuleAction: any;
  let duplicateModuleAction: any;
  let copyModuleAction: any;

  if (actions) {
    deleteModuleAction = actions.deleteModuleAction;
    duplicateModuleAction = actions.duplicateModuleAction;
    copyModuleAction = actions.copyModuleAction;
  }

  const whiteListFirstItem: any = whiteList && whiteList[0];
  const hasMultipleOptions: boolean | undefined = whiteList && whiteList.length > 1;

  const containerOptions: any =
    parentKey && objKey ? selectedContent[parentKey][objKey] : objKey && selectedContent[objKey];

  let containerText: string = text ? text : getDisplayName(whiteListFirstItem);
  let componentID: number = editorID ? editorID : containerOptions?.editorID;

  const containerInfo: any = !isArray && value && isEmptyContainer(value, hasMultipleOptions);
  const isEmpty: boolean = containerInfo?.isEmpty;

  const currentComponent: any = containerInfo?.containedComponent;

  if (currentComponent) {
    containerText = currentComponent.containerText;
    componentID = currentComponent.componentID;
  }

  const { isOpen, toggleModal } = useModal();

  const handleEmptyContainerClick = () => actions.addComponentAction && actions.addComponentAction(value);

  const handleClick = () => {
    if (!disabled) {
      goTo(componentID);
    }
  };

  const removeItem = () => deleteModuleAction(editorID, parentKey);
  const duplicateItem = () => parentKey && duplicateModuleAction(editorID, parentKey);
  const copyItem = () => {
    const isCopied = copyModuleAction(editorID);
    isCopied && toggleToast();
  };

  const copyOpt = {
    label: "copy",
    icon: "copy",
    action: copyItem,
  };

  const duplicateOpt = {
    label: "duplicate",
    icon: "duplicate",
    action: duplicateItem,
  };

  const deleteOpt = {
    label: "delete",
    icon: "delete",
    action: removeItem,
  };

  const replaceOpt = {
    label: "replace",
    icon: "change",
    action: () => actionReplace && actionReplace(),
  };

  const actionArrayMenuOptions = [
    ...(isModule ? [copyOpt] : []),
    ...(canDuplicate ? [duplicateOpt] : []),
    deleteOpt,
    ...(canReplace ? [replaceOpt] : []),
  ];

  const actionMenuOptions = [
    {
      label: "replace",
      icon: "change",
      action: toggleModal,
    },
  ];

  const actionMenuIcon = "more";

  const handleOptionClick = (option: any) => actions.addComponentAction(option);

  const compoundKey = parentKey ? `${parentKey}.${objKey}` : objKey;
  const handleReplace = (option: any) => actions.replaceModuleAction(option, selectedContent, compoundKey);

  const arrayContainerButtonsProps = {
    options: actionArrayMenuOptions,
    icon: actionMenuIcon,
  };

  return isEmpty ? (
    <EmptyContainer
      whiteList={whiteList}
      categories={categories}
      hasMultipleOptions={hasMultipleOptions}
      goTo={handleEmptyContainerClick}
      handleAdd={handleOptionClick}
      componentOptions={containerOptions}
      title={title}
      theme={theme}
    />
  ) : (
    <>
      <S.Component
        disabled={disabled}
        className={`editorId-${editorID}`}
        onClick={handleClick}
        ref={innerRef}
        data-testid="component-container"
        {...provided?.draggableProps}
      >
        {isArray && provided && (
          <S.HandleWrapper {...provided?.dragHandleProps} hidden={arrayLength < 2} data-testid="handle-wrapper">
            <S.IconHandleWrapper>
              <Icon name="drag" size="16" />
            </S.IconHandleWrapper>
          </S.HandleWrapper>
        )}
        {containerInfo && !disabled && (
          <S.IconWrapper data-testid="icon-wrapper">
            <Icon name={containerText} />
          </S.IconWrapper>
        )}
        <S.Text>
          <S.Label containerInfo={containerInfo} disabled={disabled}>
            {trimText(containerText, 35)}
          </S.Label>
          {moduleTitle && <S.Title disabled={disabled}>{trimText(moduleTitle, 35)}</S.Title>}
        </S.Text>
        {isArray && !disabled && <ArrayContainerButtons {...arrayContainerButtonsProps} />}
        {!isArray && !disabled && hasMultipleOptions && (
          <ContainerButtons options={actionMenuOptions} icon={actionMenuIcon} />
        )}
      </S.Component>
      {!isArray && hasMultipleOptions && (
        <SideModal
          optionsType={"components"}
          whiteList={whiteList}
          categories={categories}
          handleClick={handleReplace}
          toggleModal={toggleModal}
          isOpen={isOpen}
          componentOptions={containerOptions}
          current={currentComponent}
          theme={theme}
        />
      )}
      {isVisible && <Toast message="1 module copied to clipboard" setIsVisible={setIsVisible} />}
    </>
  );
};

export interface IComponentContainerProps {
  text: string;
  editorID: number;
  whiteList: any[] | undefined;
  categories?: any[];
  goTo: any;
  isArray?: boolean | undefined;
  value?: any;
  title?: string;
  moduleTitle?: string;
  selectedContent?: any;
  objKey?: string;
  actions: any;
  disabled?: boolean;
  canDuplicate?: boolean;
  parentKey?: string;
  theme: string;
  canReplace?: boolean;
  actionReplace?: () => void;
  arrayLength: number;
  innerRef?: any;
  provided?: DraggableProvided;
  isModule?: boolean;
}

export default ComponentContainer;
