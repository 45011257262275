import styled from "styled-components";

const CategoryListWrapper = styled.div`
  display: flex;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: calc(100vh - ${(p) => p.theme.spacing.xl});
  overflow: auto;
`;

const EmptyWrapper = styled.div`
  height: ${p => (`calc(100vh - (${p.theme.spacing.xl} * 3))`)};
  display: flex;
  align-items: center;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

export { CategoryListWrapper, TableWrapper, EmptyWrapper, ModalContent };
