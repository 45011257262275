import React, { useEffect, useState } from "react";

import { Icon, FloatingMenu, ListTitle, ListItem } from "@ax/components";

import * as S from "./style";

const StateFilter = (props: IProps): JSX.Element => {
  const { value, filterItems } = props;

  const [selectedValue, setSelectedValue] = useState<string>("all");

  const isFilterActived = selectedValue !== "all";

  useEffect(() => {
    value && setSelectedValue(value);
  }, [value]);

  const setFilterQuery = (selection: any) => {
    if (!selection.length) {
      selection = "";
    }
    setSelectedValue(selection);
    filterItems("filterState", selection);
  };

  const sortAllStates = () => setFilterQuery("all");
  const sortEnableState = () => setFilterQuery("enable");
  const sortDisableState = () => setFilterQuery("disable");

  const Header = () => (
    <S.State isActive={isFilterActived}>
      State
      <S.IconsWrapper>
        {isFilterActived ? (
          <Icon name="Filter" size="16" />
        ) : (
          <S.InteractiveArrow>
            <Icon name="DownArrow" size="16" />
          </S.InteractiveArrow>
        )}
      </S.IconsWrapper>
    </S.State>
  );

  return (
    <FloatingMenu Button={Header} position="center">
      <ListTitle>Filter by state</ListTitle>
      <ListItem isSelected={!isFilterActived} onClick={sortAllStates}>
        ALL
      </ListItem>
      <ListItem isSelected={selectedValue === "enable"} onClick={sortEnableState}>
        Enabled
      </ListItem>
      <ListItem isSelected={selectedValue === "disable"} onClick={sortDisableState}>
        Disabled
      </ListItem>
    </FloatingMenu>
  );
};

interface IProps {
  filterItems(pointer: string, filter: string): void;
  value: string;
}

export default StateFilter;
