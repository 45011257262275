import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: ${p => p.theme.spacing.xxs};
  align-items: center;
`;

const ColorWrapper = styled.div<{background: string}>`
  position: absolute;
  top: 50%;
  left: ${p => p.theme.spacing.s};
  background-color: ${(p) => p.background};
  width: ${p => p.theme.spacing.m};
  height: ${p => p.theme.spacing.m};
  border-radius: 2px;
  border: 1px solid ${(p) => p.theme.color.uiLine};
  transform: translateY(-50%);
`;

const Input = styled.input<{error?: boolean}>`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};
  background-color: ${(p) => p.theme.color.interactiveBackground};
  width: 100%;
  min-width: ${p => `calc(${p.theme.spacing.l} * 5)`};
  height: ${p => p.theme.spacing.l};
  border: 1px solid;
  border-color: ${(p) => (p.error ? p.theme.color.error : p.theme.color.uiLine)};
  border-radius: ${p => p.theme.radii.s};
  padding: 0 ${p => p.theme.spacing.l};

  &:active,
  &:focus {
    outline: none;
    border-color: ${(p) => (p.error === true ? p.theme.color.error : p.theme.color.uiLine)};
  }

  &::placeholder {
    color: ${(p) => p.theme.color.textLowEmphasis};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: ${p => p.theme.spacing.s};
  transform: translateY(-50%);
`;

export { Wrapper, InputWrapper, ColorWrapper, Input, IconWrapper };
