import { useState } from "react";
import { IUsersQueryValues } from "@ax/types";

const useSortedListStatus = () => {
  const sortedInitialState: { isAscending: boolean; sortedByName: boolean; sortedByDateCreated: boolean } = {
    isAscending: false,
    sortedByName: false,
    sortedByDateCreated: true,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = (): IUseFilterQuery => {
  const initialQueryValues = {
    order: "",
    filterSites: "noFilter",
    filterRoles: "all",
  };

  const [query, setQuery] = useState(initialQueryValues);

  const setFilterQuery = (filterValues: IUsersQueryValues) => {
    const { order, filterSites, filterRoles } = filterValues;

    const filterRolesValue = filterRoles === "all" ? "" : filterRoles;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: string) => {
      return filterQuery ? filterQuery.concat(`&${pointer}=${values}`) : `?${pointer}=${values}`;
    };

    const isNotInitialValue = (pointer: keyof IUsersQueryValues) => {
      return filterValues[pointer] && initialQueryValues[pointer] !== filterValues[pointer];
    };

    if (isNotInitialValue("order")) filterQuery = currentQuery("order", order);
    if (isNotInitialValue("filterSites")) filterQuery = currentQuery("filterSites", filterSites);
    if (isNotInitialValue("filterRoles")) filterQuery = currentQuery("filterRoles", filterRolesValue);

    return filterQuery;
  };

  const setFiltersSelection = (pointer: string, filter: string, isAscendent?: boolean) => {
    const { order, filterSites, filterRoles } = query;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues = {
      order: pointer === "order" ? `${filter}-${orderMethod}` : order,
      filterSites: pointer === "filterSites" ? filter : filterSites,
      filterRoles: pointer === "filterRoles" ? filter : filterRoles,
    };

    setQuery(filterValues);

    return filterValues;
  };

  const resetFilterQuery = () => setQuery(initialQueryValues);

  return {
    setFiltersSelection,
    setFilterQuery,
    resetFilterQuery,
    filterValues: query,
  };
};

interface IUseFilterQuery {
  setFiltersSelection(pointer: string, filter: string, isAscendent?: boolean): IUsersQueryValues;
  setFilterQuery(filterValues: IUsersQueryValues): string;
  resetFilterQuery(): void;
  filterValues: IUsersQueryValues;
}

export { useSortedListStatus, useFilterQuery };
