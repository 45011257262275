import React from "react";

import { trimText } from "@ax/helpers";
import { Tooltip, Image } from "@ax/components";

import * as S from "./style";

export const ItemName = (props: IItemNameProps): JSX.Element => {
  const { name, length } = props;
  return name.length > length ? (
    <Tooltip content={name} left={120}>
      <S.ItemName data-testid="trimmed-item-name">{trimText(name, length)}</S.ItemName>
    </Tooltip>
  ) : (
    <S.ItemName data-testid="sites-item-name">{name}</S.ItemName>
  );
};

export const ItemThumbnail = (props: IItemThumbnailProps): JSX.Element => {
  const { src, width, height, borderRadius } = props;
  const thumbnailPlaceholder = "/img/placeholder/thumbnail@1x.png";
  return src ? (
    <S.ImageWrapper data-testid="sites-item-thumbnail-wrapper" borderRadius={borderRadius}>
      <Image width={width} height={height} url={src} />
    </S.ImageWrapper>
  ) : (
    <S.Thumbnail
      data-testid="sites-item-thumbnail"
      backgroundUrl={thumbnailPlaceholder}
      width={width}
      height={height}
      borderRadius={borderRadius}
    />
  );
};

interface IItemNameProps {
  name: string;
  length: number;
}

interface IItemThumbnailProps {
  src?: string;
  width: number;
  height: number;
  borderRadius?: boolean;
}
