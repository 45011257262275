import React from "react";
import { ISite } from "@ax/types";

import * as S from "./style";

const ArrayFieldItem = (props: IProps): JSX.Element => {
  const { fields, item, name, index, onChange, handleDelete, isOpen, setIsOpen, site } = props;

  const handleClick = () => (isOpen === index ? setIsOpen(null) : setIsOpen(index));

  const deleteItem = () => handleDelete(index);

  const menuOptions = [
    {
      label: "Delete",
      icon: "delete",
      action: deleteItem,
    },
  ];

  const getItemTitle = (fields: any[]) => {
    const itemTitle = fields.find((field: any) => field.props.isTitle);
    if (itemTitle) {
      const title: any = item[itemTitle.key];
      if (title && typeof title === "string" && title.length) return title;
    }
    return `${name} ${index + 1}`;
  };

  const getFields = (fields: any[]): any[] => {
    return fields.map((field: any) => {
      const key = field.props.objKey;
      const handleChange = (newValue: any) => onChange({ [key]: newValue });

      const innerFields = field.props.innerFields ? getFields(field.props.innerFields) : undefined;

      return {
        ...field,
        props: { ...field.props, value: item[key], objKey: key, onChange: handleChange, innerFields, site },
        key,
      };
    });
  };

  return (
    <S.Wrapper data-testid="arrayFieldItem" isOpen={isOpen === index}>
      <S.Title onClick={handleClick} isOpen={isOpen === index}>
        {getItemTitle(fields)}
        <S.StyledActionMenu icon="more" options={menuOptions} />
      </S.Title>
      <S.Content isOpen={isOpen === index}>{getFields(fields)}</S.Content>
    </S.Wrapper>
  );
};

interface IProps {
  name: string;
  fields: any[];
  item: Record<string, unknown>;
  index: number;
  onChange: (value: Record<string, unknown>) => void;
  handleDelete: (index: number) => void;
  isOpen: number | null;
  setIsOpen: (index: number | null) => void;
  site: ISite | null;
}

export default ArrayFieldItem;
