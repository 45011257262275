import React from "react";

import { BulkSelectionOptions } from "@ax/components";
import { usePermission } from "@ax/hooks";

import TableHeader from "./TableHeader";

import * as S from "./style";

const BulkHeader = (props: IBulkHeaderProps): JSX.Element => {
  const {
    showBulk,
    checkState,
    bulkPublish,
    bulkUnpublish,
    selectItems,
    selectAllItems,
    totalItems,
    sortItems,
    filterItems,
    sortedListStatus,
    filterValues,
  } = props;

  const allowedToPublishSite = usePermission("general.publishSite");
  const allowedToUnpublishSite = usePermission("general.unpublishSite");

  const publishAction = allowedToPublishSite
    ? {
        icon: "upload-pending",
        text: "publish",
        action: bulkPublish,
      }
    : undefined;

  const unpublishAction = allowedToUnpublishSite
    ? {
        icon: "offline",
        text: "unpublish",
        action: bulkUnpublish,
      }
    : undefined;

  const actions = [publishAction, unpublishAction];

  return showBulk ? (
    <S.BulkWrapper data-testid="sites-list-bulk-wrapper">
      <BulkSelectionOptions
        isScrolling={false}
        checkState={checkState}
        actions={actions}
        selectItems={selectItems}
        totalItems={totalItems}
      />
    </S.BulkWrapper>
  ) : (
    <TableHeader
      isScrolling={false}
      selectAllItems={selectAllItems}
      checkState={checkState}
      sortItems={sortItems}
      filterItems={filterItems}
      sortedListStatus={sortedListStatus}
      totalItems={totalItems}
      filterValues={filterValues}
    />
  );
};

export interface IBulkHeaderProps {
  showBulk: boolean;
  checkState: any;
  bulkPublish: () => void;
  bulkUnpublish: () => void;
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  sortItems: (orderPointer: string, isAscending: any) => void;
  filterItems: (filterPointer: string, filtersSelected: string) => void;
  sortedListStatus: any;
  filterValues: any;
}

export default BulkHeader;
