import React, { memo } from "react";

import { useToast } from "@ax/hooks";
import { Tooltip, IconAction, Toast } from "@ax/components";
import { INotification } from "@ax/types";

const PasteModuleButton = (props: IPasteModuleProps): JSX.Element => {
  const { pasteModule, setNotification, setHistoryPush, editorID, isModuleCopyUnavailable, arrayKey } = props;

  const { isVisible, toggleToast, setIsVisible } = useToast();

  const handlePasteModule = async () => {
    if (!isModuleCopyUnavailable) {
      const pasteResult = await pasteModule(editorID, arrayKey);
      if (pasteResult.error) {
        const { type, text } = pasteResult.error;
        setNotification && setNotification({ type, text });
      } else {
        toggleToast();
      }
    } else {
      const notification: INotification = {
        type: "error",
        text: "You are trying to paste a module that is part of a disabled content type package. To copy it, you must first activate it.",
        btnText: "Activate package",
        onClick: () => setHistoryPush && setHistoryPush("/sites/settings/content-types", false),
      };
      setNotification && setNotification(notification);
    }
  };

  return (
    <>
      <Tooltip content="Paste from clipboard">
        <IconAction icon="paste" onClick={handlePasteModule} />
      </Tooltip>
      {isVisible && <Toast message="Module pasted from clipboard" setIsVisible={setIsVisible} />}
    </>
  );
};

export interface IPasteModuleProps {
  pasteModule: (editorID: number, key: string) => Promise<{ error?: INotification }>;
  setNotification?: (notification: INotification) => void;
  setHistoryPush?: (path: string, isEditor: boolean) => void;
  editorID: number;
  isModuleCopyUnavailable: boolean;
  arrayKey: string;
}

export default memo(PasteModuleButton);
