import React, { useEffect, useLayoutEffect, useState } from "react";

import { CheckGroup, FloatingMenu, Icon, ListTitle } from "@ax/components";
import { areEquals, isNumber, isReqOk } from "@ax/helpers";
import { selects } from "@ax/api";
import { IFilterValue } from "@ax/types";

import * as S from "./style";

const SiteFilter = (props: ISiteFilterProps): JSX.Element => {
  const {
    filterItems,
    value,
    pointer,
    center = true,
    label = "Site",
    selectAllOption = "all",
    filters = [
      {
        name: "all",
        value: "all",
        title: "All",
      },
    ],
  } = props;

  const initialState = [selectAllOption];
  const [selectedValue, setSelectedValue] = useState([selectAllOption]);
  const [options, setOptions] = useState(filters);

  useLayoutEffect(() => {
    const storedValue = value && value[pointer] ? value[pointer].split(",") : [];
    const parsedValue = storedValue.map((value: any) => (isNumber(value) ? parseInt(value) : value));
    setSelectedValue(parsedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getSelectSites = async () => {
    try {
      const items = await selects.getSelectSites();
      if (items && isReqOk(items.status)) {
        return items.data;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  useEffect(() => {
    getSelectSites()
      .then((items) => {
        items &&
          items.forEach((item: { value: string; label: string }) => {
            const newFilter = {
              name: item.value,
              value: item.value,
              title: item.label,
            };
            filters.push(newFilter);
          });

        setOptions(filters);
      })
      .catch((apiError) => console.log(apiError));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setQuery = (selection: any) => {
    if (!selection.length) {
      selection = initialState;
    }
    setSelectedValue(selection);
    const queryFilters = selection.join(",");
    filterItems(pointer, queryFilters);
  };

  const isActive = !areEquals(selectedValue, initialState);

  const Header = () => (
    <S.Site isActive={isActive} center={center}>
      {label}
      <S.IconsWrapper>
        {isActive ? <Icon name="Filter" size="16" /> : <Icon name="DownArrow" size="16" />}
      </S.IconsWrapper>
    </S.Site>
  );

  return (
    <FloatingMenu Button={Header} position="left" closeOnSelect={false} isCheckGroup={true}>
      <ListTitle>Filter by Site</ListTitle>
      <S.ChecksWrapper>
        <CheckGroup options={options} value={selectedValue} onChange={setQuery} selectAllOption={selectAllOption} />
      </S.ChecksWrapper>
    </FloatingMenu>
  );
};

export interface ISiteFilterProps {
  filterItems(pointer: string, filter: string): void;
  value: any;
  pointer: string;
  center?: boolean;
  label?: string;
  selectAllOption?: string;
  filters?: IFilterValue[];
}

export default SiteFilter;
