import styled, { keyframes } from "styled-components";

const animation1 = keyframes`
  from { left: 5%; bottom: 10%; }
  to { left: 15%; bottom: 15%; }
`;

const animation2 = keyframes`
  from { right: 5%; top: 10%; }
  to { right: 15%; top: 15%; }
`;

const animations = [animation1, animation2];
const colors = ["#A950CF", "#FBE15C"];


const Circle = styled.div<{ size: number; animation: number }>`
  position: absolute;
  width: ${(p) => `${p.size}px`};
  height: ${(p) => `${p.size}px`};
  border-radius: 50%;
  filter: blur(50px);
  background-color: ${(p) => colors[p.animation - 1]};
  animation: ${(p) => animations[p.animation - 1]} 5s linear infinite alternate;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  opacity: 0.6;
`;

export { Circle };
