import React from "react";

import { IDataPack, IStructuredData } from "@ax/types";
import { SubNav } from "@ax/components";
import { sortBy } from "@ax/helpers";
import NavItem from "./NavItem";

const CategoryNav = (props: IProps): JSX.Element => {
  const { current, categories, onClick, dataPacks, setSelectedCategory, scope } = props;

  return (
    <SubNav>
      {dataPacks &&
        dataPacks.reduce((result: JSX.Element[], dataPack: IDataPack) => {
          const dataPackCategories = categories.filter((cat: IStructuredData) => cat.dataPacks.includes(dataPack.id));
          const hasCategories = dataPackCategories.length > 0;
          if (hasCategories) {
            const isFirst = result.length === 0;
            const orderedCategories = dataPackCategories.sort(sortBy("title", false));
            if (isFirst && !current) {
              setSelectedCategory(orderedCategories[0].id, scope);
            }
            result.push(
              <NavItem
                key={dataPack.title}
                isFirst={isFirst}
                dataPack={dataPack}
                categories={orderedCategories}
                current={current}
                onClick={onClick}
              />
            );
          }
          return result;
        }, [])}
    </SubNav>
  );
};

interface IProps {
  current: IStructuredData | null;
  categories: IStructuredData[];
  onClick(id: string): void;
  dataPacks: IDataPack[];
  setSelectedCategory(id: string, scope: string): void;
  scope: string;
}

export default CategoryNav;
