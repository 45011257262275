import React from "react";

import { Icon, FloatingMenu, ListTitle, ListItem } from "@ax/components";

import * as S from "./style";

const LastAccessFilter = (props: ILastAccessFilterProps): JSX.Element => {
  const { sortItems, sortedState } = props;
  const { isAscending, sortedByLastAccess } = sortedState;

  const sortAscendingLastAccess = () => sortItems("lastAccess", true);
  const sortDescendingLastAccess = () => sortItems("lastAccess", false);


  const SortedStateArrow = () =>
    isAscending ? <Icon name="FullArrowUp" size="16" /> : <Icon name="FullArrowDown" size="16" />;

  const Header = () => (
    <S.NameHeader isActive={sortedByLastAccess} data-testid="name-filter-header">
      Last access
      <S.IconsWrapper>
        {sortedByLastAccess ? (
          <SortedStateArrow />
        ) : (
          <S.InteractiveArrow data-testid="name-filter-interactive-arrow">
            <Icon name="DownArrow" size="16" />
          </S.InteractiveArrow>
        )}
      </S.IconsWrapper>
    </S.NameHeader>
  );

  return (
    <FloatingMenu Button={Header} position="left">
      <ListTitle>Access Sorting</ListTitle>
      <ListItem isSelected={sortedByLastAccess && isAscending} onClick={sortAscendingLastAccess}>
        Ascendent
      </ListItem>
      <ListItem isSelected={sortedByLastAccess && !isAscending} onClick={sortDescendingLastAccess}>
        Descendent
      </ListItem>
    </FloatingMenu>
  );
};

export interface ILastAccessFilterProps {
  sortedState: any;
  sortItems(orderPointer: string, isAscendent: boolean): void;
  urlSorting?: boolean;
}

export default LastAccessFilter;
