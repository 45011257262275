import React from "react";

import { CheckField, NameFilter, TableCounter, CheckGroupFilter, StateFilter } from "@ax/components";
import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const { totalItems, selectAllItems, isScrolling, filterItems, filterValues, sortItems, sortedListStatus } = props;

  const filters = [
    {
      name: "all",
      value: "all",
      title: "ALL",
    },
    {
      name: "allpages",
      value: "allpages",
      title: "All pages",
    },
    {
      name: "somepages",
      value: "somepages",
      title: "Some pages",
    },
    {
      name: "none",
      value: "none",
      title: "None",
    },
  ];

  const sortByName = (orderPointer: string, isAscendent: boolean) => sortItems("name", isAscendent);

  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <CheckField
          key="selectAll"
          name="selectAll"
          value="selectAll"
          onChange={selectAllItems}
          checked={false}
          disabled={false}
          error={false}
        />
      </S.CheckHeader>
      <S.NameHeader>
        Name
      </S.NameHeader>
      <S.DescriptionHeader></S.DescriptionHeader>
      <S.AppliedOnHeader>
        <CheckGroupFilter
          filterItems={filterItems}
          value={filterValues.filterApplication}
          pointer="filterApplication"
          label="Applied on"
          description="Filter by applicated"
          initialState={["all"]}
          selectAllOption="all"
          filters={filters}
        />
      </S.AppliedOnHeader>
      <StateFilter filterItems={filterItems} value={filterValues.filterState} />
      <S.ActionsHeader>
        <TableCounter totalItems={totalItems} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  totalItems: number;
  isScrolling: boolean;
  selectAllItems: () => void;
  filterItems: (filterPointer: string, filtersSelected: string) => void;
  filterValues: any;
  sortItems: (orderPointer: string, isAscending: boolean) => void;
  sortedListStatus: { isAscending: boolean; sortedByTitle: boolean };
}

export default TableHeader;
