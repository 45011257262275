import React, { useState } from "react";
import { connect } from "react-redux";

import { IDataSource, ILanguage, IRootState, ISite, IStructuredData } from "@ax/types";
import {
  Button,
  FloatingMenu,
  IconAction,
  RadioGroup,
  Select,
  FieldsBehavior,
  AsyncSelect,
  ToggleField,
  UniqueCheck,
} from "@ax/components";

import { IFilter, IReferenceState, useReference } from "../Context";
import AutoItem from "./AutoItem";

import * as S from "./style";

const AutoPanel = (props: IProps): JSX.Element => {
  const { onChange, site, structuredData, validators, categories, globalLangs, lang } = props;

  const { state, setState } = useReference();

  const init = state.quantity ? "number" : "all";
  const [allItems, setAllItems] = useState<string | boolean>(init);
  const initConfig = {
    isConfigOpen: false,
    isLanguageOpen: true,
    isDataOpen: true,
    isAllLangOpen: true,
    isCustomLangOpen: true,
  };
  const [configState, setConfigState] = useState<IConfigState>(initConfig);
  const initToggle = {
    isSiteActive: state.site ? true : false,
    isLangActive: state.lang ? true : false,
    errorSite: false,
    errorLang: false,
    errorMsg: "",
  };
  const [toggleState, setToggleState] = useState<IToggleState>(initToggle);

  const langOptions = globalLangs
    .filter((language) => lang.id !== language.id)
    .map((language) => ({ label: language.label, value: language.id.toString() }));

  const structuredDataValues: any[] = [];
  for (const [, dataType] of Object.entries(structuredData) as any[]) {
    structuredDataValues.push(...dataType);
  }

  const options = [
    {
      title: "Most recent",
      value: "recent",
      name: "recent",
    },
    {
      title: "Alphabetical order",
      value: "alpha",
      name: "alpha",
    },
  ];

  const sourcesFields = state.source.map((source: any) => {
    const foundData = structuredDataValues.find((value) => value.id === source);
    const indexableFields = foundData.schema.fields.filter((field: any) => field.indexable);
    return indexableFields;
  });

  const indexableFields =
    !!sourcesFields.length &&
    sourcesFields[0].filter((field: any) => sourcesFields.every((f: any) => f.some((_f: any) => _f.key === field.key)));
  if (indexableFields) {
    indexableFields.forEach((field: any) => {
      const newField = {
        title: field.title,
        value: field.key,
        name: field.key,
      };
      options.push(newField);
    });
  }

  const orderOptions = [
    { label: "Ascendent", value: "ASC" },
    { label: "Descendent", value: "DESC" },
  ];

  const handleOrderDirection = (orderDirection: string) =>
    setState((state: IReferenceState) => ({ ...state, orderDirection }));

  const optionsOnSelect = (
    <S.SelectWrapper>
      <Select
        type="inline"
        name="orderDirection"
        value={state.orderDirection}
        options={orderOptions}
        onChange={handleOrderDirection}
        mandatory
        alignRight
      />
    </S.SelectWrapper>
  );

  const radioGroupProps = {
    name: "Sort",
    options,
    value: state.order,
    onChange: (order: string | boolean) => setState((state: IReferenceState) => ({ ...state, order })),
    optionsOnSelect,
  };

  const numberFieldProps = {
    fieldType: "NumberField",
    title: "",
    value: state.quantity,
    onChange: (quantity: number) => setState((state: IReferenceState) => ({ ...state, quantity })),
    maxValue: validators && validators.maxValue ? validators.maxValue : undefined,
    minValue: validators && validators.minValue ? validators.minValue : undefined,
  };

  const checkErrors = (): boolean => {
    let errorSite = false;
    let errorLang = false;
    let errorMsg = "";
    if (toggleState.isSiteActive && !state.site) {
      errorSite = true;
      errorMsg = "You need to select site before apply.";
    }
    if (toggleState.isLangActive && !state.lang) {
      errorLang = true;
      errorMsg = "You need to select language before apply.";
    }
    if (errorLang && errorSite) {
      errorMsg = "You need to define some settings before apply.";
    }
    setToggleState({ ...toggleState, errorLang, errorSite, errorMsg });
    return errorSite || errorLang;
  };

  const handleApply = () => {
    if (!checkErrors()) {
      const order = state.order.concat("-", state.orderDirection);
      const newValue = {
        mode: "auto",
        quantity: state.quantity,
        order,
        source: state.source,
        filter: state.filter,
        fullRelations: state.fullRelations,
        allLanguages: state.allLanguages,
        preferenceLanguage: state.preferenceLanguage,
        lang: state.lang,
        site: state.site,
      };

      onChange(newValue);
    }
  };

  const handleAddSource = (value: string) => {
    if (!state.source.includes(value)) {
      const newSources = state.source;
      newSources.push(value);
      setState((state: IReferenceState) => ({ ...state, source: newSources }));
    }
  };

  const sourceMenuOption = (option: IDataSource) => {
    const handleAddOption = () => handleAddSource(option.id);
    return (
      <S.SourceItem key={option.id} onClick={handleAddOption}>
        {option.title}
      </S.SourceItem>
    );
  };

  const sourceMenu = (menu: any) => (
    <S.SourceMenu>{menu.map((option: IDataSource) => sourceMenuOption(option))}</S.SourceMenu>
  );

  const addSourceButton = () => (
    <S.IconWrapper>
      <IconAction icon="add" />
    </S.IconWrapper>
  );

  const addSource =
    state.sourceTitles.length > 1 ? (
      <FloatingMenu Button={addSourceButton}>{sourceMenu(state.sourceTitles)}</FloatingMenu>
    ) : null;

  const removeItem = (item: string) => {
    if (state.source.includes(item)) {
      const newSources = state.source.filter((e: string) => e !== item);
      setState((state: IReferenceState) => ({ ...state, source: newSources }));
    }
  };

  const handleAddFilter = (newFilters: any[], source: string) => {
    const oldFilters = state.filter.filter((f: any) => f.source !== source);
    setState((state: IReferenceState) => ({ ...state, filter: [...oldFilters, ...newFilters] }));
  };

  const quantityOptions = [
    {
      title: "Choose number of items",
      name: "number",
      value: "number",
    },
    {
      title: "All items",
      name: "all",
      value: "all",
    },
  ];

  const handleItemsChange = (value: string | boolean) => {
    setAllItems(value);
    if (value === "all") {
      setState((state: IReferenceState) => ({ ...state, quantity: 0 }));
    }
  };

  const handleAllLanguagesChange = (value: any) => {
    const preferenceLanguage = value ? state.preferenceLanguage : false;
    setState((state: IReferenceState) => ({ ...state, allLanguages: value, preferenceLanguage }));
  };

  const handlePreferenceLanguageChange = (value: any) =>
    setState((state: IReferenceState) => ({ ...state, preferenceLanguage: value }));

  const toggleConfig = (value: keyof IConfigState) => setConfigState({ ...configState, [value]: !configState[value] });

  const handleSiteChange = (value: string | number | null) => {
    setState((state: IReferenceState) => ({ ...state, site: value || undefined }));
    if (value) {
      setToggleState({ ...toggleState, isSiteActive: true, errorSite: false, errorMsg: "" });
    }
  };

  const handleLangChange = (value: string) => {
    const lang = value.length ? parseInt(value) : undefined;
    setState((state: IReferenceState) => ({ ...state, lang }));
    if (value) {
      setToggleState({ ...toggleState, isLangActive: true, errorLang: false, errorMsg: "" });
    }
  };

  const handleLangToggleChange = (value: boolean) => {
    setToggleState({ ...toggleState, isLangActive: value });
    if (!value) {
      setState((state: IReferenceState) => ({ ...state, lang: undefined }));
    }
  };

  const handleSiteToggleChange = (value: boolean) => {
    setToggleState({ ...toggleState, isSiteActive: value });
    if (!value) {
      setState((state: IReferenceState) => ({ ...state, site: undefined }));
    }
  };

  return (
    <S.Wrapper data-testid="auto-panel-wrapper">
      <S.FormWrapper>
        <S.DataLabel>Data source</S.DataLabel>
        <S.SourcesWrapper>
          <S.SourceActions>
            {state.source && `${state.source.length} items`} {addSource}
          </S.SourceActions>
          {state.source &&
            state.source.length > 0 &&
            state.source.map((singleSource: string) => {
              const sourceFilters = state.filter.filter((f: IFilter) => f.source === singleSource);
              const source = state.sourceTitles.find((el: IDataSource) => el.id === singleSource);
              return source ? (
                <AutoItem
                  key={singleSource}
                  source={source}
                  canDelete={state.sourceTitles.length > 1}
                  handleDelete={removeItem}
                  filter={sourceFilters}
                  addFilter={handleAddFilter}
                  currentSite={site}
                  structuredDataSite={[...structuredData.site, ...categories.site]}
                  siteID={state.site || site?.id}
                />
              ) : (
                <></>
              );
            })}
        </S.SourcesWrapper>
        <S.RadioWrapper>
          <S.FieldLabel>Sort</S.FieldLabel>
          <RadioGroup {...radioGroupProps} />
        </S.RadioWrapper>
        <S.FieldLabel>Number of items</S.FieldLabel>
        <RadioGroup name="quantityGroup" options={quantityOptions} value={allItems} onChange={handleItemsChange} />
        {allItems !== "all" && <FieldsBehavior {...numberFieldProps} />}

        <S.AdvancedWrapper>
          <S.ConfigLabel
            onClick={() => toggleConfig("isConfigOpen")}
            isOpen={configState.isConfigOpen}
            data-testid="advanced-config-label"
          >
            Advanced Settings
          </S.ConfigLabel>
          <S.ConfigWrapper isOpen={configState.isConfigOpen}>
            <S.OptionLabel
              onClick={() => toggleConfig("isLanguageOpen")}
              isOpen={configState.isLanguageOpen}
              data-testid="advanced-config-label"
            >
              <S.LabelContent>
                <S.OptionText>Language options</S.OptionText>
              </S.LabelContent>
            </S.OptionLabel>

            <S.ConfigWrapper isOpen={configState.isLanguageOpen}>
              <S.ConfigContent smallMargin={toggleState.isLangActive}>
                <S.OptionLabel
                  onClick={() => toggleConfig("isAllLangOpen")}
                  isOpen={configState.isAllLangOpen}
                  data-testid="advanced-config-label"
                >
                  <S.LabelContent>
                    <S.SubOptionText>Include content in any language</S.SubOptionText>
                    <ToggleField
                      name="allLanguages"
                      value={state.allLanguages}
                      onChange={handleAllLanguagesChange}
                      size="s"
                    />
                  </S.LabelContent>
                </S.OptionLabel>

                <S.ConfigWrapper isOpen={configState.isAllLangOpen}>
                  <S.SubConfigContent hasMargin={true}>
                    <S.OptionDescription>
                      By default, data is displayed in the page's language. Enable this option if you want the content
                      to <strong>include data in any available language</strong>.
                    </S.OptionDescription>
                    {state.allLanguages && (
                      <S.CheckWrapper>
                        <UniqueCheck
                          name="preferenceLanguageCheck"
                          options={[{ value: false, name: "preferenceLanguage", title: "Language page first" }]}
                          value={state.preferenceLanguage}
                          onChange={handlePreferenceLanguageChange}
                        />
                      </S.CheckWrapper>
                    )}
                  </S.SubConfigContent>
                </S.ConfigWrapper>

                {langOptions.length > 0 ? (
                  <>
                    <S.OptionLabel
                      onClick={() => toggleConfig("isCustomLangOpen")}
                      isOpen={configState.isCustomLangOpen}
                      data-testid="advanced-config-label"
                    >
                      <S.LabelContent>
                        <S.SubOptionText>Specify different language</S.SubOptionText>
                        <ToggleField
                          name="customLang"
                          value={toggleState.isLangActive}
                          onChange={handleLangToggleChange}
                          size="s"
                        />
                      </S.LabelContent>
                    </S.OptionLabel>

                    <S.ConfigWrapper>
                      <S.SubConfigContent>
                        <S.OptionDescription isOpen={configState.isCustomLangOpen}>
                          By default, data is displayed in the page's language. Enable this option if you wish to{" "}
                          <strong>specify a different language</strong> for the content of this distributor.
                        </S.OptionDescription>
                        {toggleState.isLangActive && (
                          <Select
                            name="select"
                            options={langOptions}
                            onChange={handleLangChange}
                            value={state.lang?.toString() || ""}
                            type="inline"
                            placeholder="Select language"
                            error={toggleState.errorLang}
                          />
                        )}
                      </S.SubConfigContent>
                    </S.ConfigWrapper>
                  </>
                ) : (
                  <></>
                )}
              </S.ConfigContent>
            </S.ConfigWrapper>

            <S.OptionLabel
              onClick={() => toggleConfig("isDataOpen")}
              isOpen={configState.isDataOpen}
              data-testid="advanced-config-label"
            >
              <S.LabelContent>
                <S.OptionText>Data from specific site</S.OptionText>
                <ToggleField
                  name="site-toggle"
                  value={toggleState.isSiteActive}
                  onChange={handleSiteToggleChange}
                  size="s"
                />
              </S.LabelContent>
            </S.OptionLabel>

            <S.ConfigWrapper>
              <S.ConfigContent smallMargin={toggleState.isSiteActive} isLast={true}>
                <S.OptionDescription isOpen={configState.isDataOpen}>
                  Data is retrieved from the site you are on.{" "}
                  <strong>If you prefer to display content from another site</strong>, configure this option to select
                  the specific site.
                </S.OptionDescription>
                {toggleState.isSiteActive && (
                  <AsyncSelect
                    name="select"
                    entity="sites"
                    onChange={handleSiteChange}
                    value={state.site || null}
                    type="inline"
                    placeholder="Select site"
                    error={toggleState.errorSite}
                  />
                )}
              </S.ConfigContent>
            </S.ConfigWrapper>
          </S.ConfigWrapper>
        </S.AdvancedWrapper>
      </S.FormWrapper>
      <S.ActionsWrapper>
        <S.ErrorWrapper>{toggleState.errorMsg.length > 0 && toggleState.errorMsg}</S.ErrorWrapper>
        <Button type="button" onClick={handleApply}>
          Apply
        </Button>
      </S.ActionsWrapper>
    </S.Wrapper>
  );
};

interface IProps {
  structuredData: { global: IStructuredData[]; site: IStructuredData[] };
  categories: { global: IStructuredData[]; site: IStructuredData[] };
  onChange: (value: any) => void;
  site: ISite | null;
  validators?: Record<string, unknown>;
  globalLangs: ILanguage[];
  lang: { locale: string; id: number };
}

interface IConfigState {
  isConfigOpen: boolean;
  isLanguageOpen: boolean;
  isDataOpen: boolean;
  isAllLangOpen: boolean;
  isCustomLangOpen: boolean;
}

interface IToggleState {
  isSiteActive: boolean;
  isLangActive: boolean;
  errorSite: boolean;
  errorLang: boolean;
  errorMsg: string;
}

const mapStateToProps = (state: IRootState) => ({
  globalLangs: state.app.globalLangs,
  lang: state.app.lang,
});

export default connect(mapStateToProps)(AutoPanel);
