import React, { memo } from "react";

import { IBreadcrumbItem } from "@ax/types";
import { Breadcrumb, Toast } from "@ax/components";
import { useToast } from "@ax/hooks";

import * as S from "./style";

const Header = (props: IHeaderProps) => {
  const { breadcrumb, schema, selectedParent, activatedModules, actions, setSelectedContent } = props;
  const { duplicateModuleAction, deleteModuleAction, copyModuleAction } = actions;
  const title = breadcrumb[breadcrumb.length - 1].displayName;
  const component = breadcrumb[breadcrumb.length - 1].component;
  const editorID = breadcrumb[breadcrumb.length - 1].editorID;
  const parentID = breadcrumb.length > 1 ? breadcrumb[breadcrumb.length - 2].editorID : breadcrumb[0].editorID;
  const isModule = schema.schemaType === "module";
  const isInArray = Array.isArray(selectedParent);

  const { isVisible, toggleToast, setIsVisible } = useToast();

  const removeItem = () => {
    setSelectedContent(parentID);
    deleteModuleAction(editorID);
  };

  const duplicateItem = () => {
    duplicateModuleAction(editorID);
    setSelectedContent(parentID);
  };

  const copyItem = () => {
    const isCopied = copyModuleAction(editorID);
    isCopied && toggleToast();
  };

  const duplicateOpt = {
    label: "duplicate",
    icon: "duplicate",
    action: duplicateItem,
  };

  const deleteOpt = {
    label: "delete",
    icon: "delete",
    action: removeItem,
  };

  const copyOpt = {
    label: "copy",
    icon: "copy",
    action: copyItem,
  };

  const isModuleDeactivated = isModule && isInArray && activatedModules && !activatedModules.includes(component);
  const canDuplicate = !isModuleDeactivated;

  let menuOptions = !canDuplicate ? [deleteOpt] : [duplicateOpt, deleteOpt];
  menuOptions = isModule ? [...menuOptions, copyOpt] : menuOptions;

  return (
    <>
      <S.HeaderWrapper data-testid="header-config-wrapper">
        <S.Title>{title}</S.Title>
        <Breadcrumb breadcrumb={breadcrumb} setSelectedContent={setSelectedContent} />
        {isInArray && <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Actions" />}
      </S.HeaderWrapper>
      {isVisible && <Toast message="1 module copied to clipboard" setIsVisible={setIsVisible} />}
    </>
  );
};

export interface IHeaderProps {
  schema: any;
  actions: any;
  breadcrumb: IBreadcrumbItem[];
  selectedParent: any;
  activatedModules: string[];
  setSelectedContent(editorID: number): void;
}

export default memo(Header);
