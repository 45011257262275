import styled from "styled-components";

import { Cell, Row } from "@ax/components/TableList/TableItem/style";
import { ActionMenu } from "@ax/components";

const CheckCell = styled(Cell)`
  padding-left: ${(p) => p.theme.spacing.m};
  width: 32px;
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
`;

const NameCell = styled(Cell)`
  width: 60%;
`;

const ActionCell = styled(Cell)`
  width: 92px;
`;

const TransCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  width: 25%;
  position: relative;
  align-items: center;
`;

const DefaultCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  width: 15%;
  align-items: center;
`;

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const DefaultRow = styled(Row)<{ clickable: boolean }>`
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
  &:hover {
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
`;

const FlagsWrapper = styled.div`
  svg {
    margin-right: 2px;
    margin-bottom: -3px; // TODO
  }
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

export {
  CheckCell,
  NameCell,
  ActionCell,
  TransCell,
  DefaultCell,
  StyledActionMenu,
  DefaultRow,
  FlagsWrapper,
  ModalContent,
};
