import { useSelector } from "react-redux";
import { IRootState } from "@ax/types";

const usePermission = (permission: string | string[] | undefined): boolean => {
  const userPermissions = useSelector((state: IRootState) => state.users.currentPermissions);
  const isSuperAdmin = useSelector(
    (state: IRootState) => state.users.currentUser && state.users.currentUser.isSuperAdmin
  );

  const isAllowedTo = (permissions: string[]) => userPermissions && permissions.some((permission: string) => userPermissions.includes(permission));

  if (!permission || isSuperAdmin) {
    return true;
  }

  const arrayPermission = Array.isArray(permission) ? permission : [permission];

  return isAllowedTo(arrayPermission);
};

const useGlobalPermission = (permission: string | string[] | undefined): boolean => {
  const userPermissions = useSelector((state: IRootState) => state.users.globalPermissions);
  const isSuperAdmin = useSelector(
    (state: IRootState) => state.users.currentUser && state.users.currentUser.isSuperAdmin
  );

  const isAllowedTo = (permissions: string[]) => userPermissions && permissions.some((permission: string) => userPermissions.includes(permission));

  if (!permission || isSuperAdmin) {
    return true;
  }

  const arrayPermission = Array.isArray(permission) ? permission : [permission];

  return isAllowedTo(arrayPermission);
};

export { usePermission, useGlobalPermission };
