import { Dispatch } from "redux";

const isReqOk = (reqStatus: number) => reqStatus >= 200 && reqStatus < 400;

function handleRequest(callback: any, responseActions: any, loadingActions: any[]): (dispatch: Dispatch) => Promise<boolean> {
  return async (dispatch: any) => {
    const {
      handleSuccess,
      handleError
    } = responseActions;

    loadingActions.map(action => dispatch(action(true)));

    const response = await callback();

    const responseArr = Array.isArray(response) ? response : [response];

    let result = true;

    responseArr.forEach((response) => {
      if (!isReqOk(response.status)) {
        result = false;
        handleError(response);
      }
    })

    if (result) await handleSuccess(response.data);

    loadingActions.map(action => dispatch(action(false)));

    return result;
  };
}

export {
  isReqOk,
  handleRequest
};
