import React from "react";

import {
  CheckField,
  TableCounter,
  CustomizeFilters,
  CategoryFilter,
  NameFilter,
  TypeFilter,
  StatusFilter,
  LiveFilter,
  TranslationsFilter,
} from "@ax/components";
import { IColumn } from "@ax/types";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const {
    isScrolling,
    isStructuredData,
    selectAllItems,
    checkState,
    sortItems,
    filterItems,
    sortedListStatus,
    totalItems,
    categoryColumns,
    columns,
    setColumns,
    isGlobalPages,
    filterValues,
    siteID,
  } = props;

  const activeColumns = Object.keys(columns).filter((col: string) => columns[col].show);

  const CategoryColumns =
    isGlobalPages &&
    categoryColumns.map(
      (col: any) =>
        activeColumns.includes(col.key) && (
          <S.HeaderWrapper key={col.key}>
            <CategoryFilter
              filterItems={filterItems}
              structuredData={col}
              value={filterValues.categories}
              siteID={siteID}
            />
          </S.HeaderWrapper>
        )
    );

  const typeFilters = [
    {
      name: "all",
      value: "all",
      title: "All content",
    },
    {
      name: "unique",
      value: "unique",
      title: "Basic templates",
    },
    {
      name: "structuredData",
      value: "structuredData",
      title: "Content types",
    },
  ];

  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <CheckField
          key="selectAll"
          name="selectAll"
          value="selectAll"
          onChange={selectAllItems}
          checked={checkState.isAllSelected}
          disabled={false}
          error={false}
        />
      </S.CheckHeader>
      {isStructuredData ? (
        <>
          <S.NameHeader>Name</S.NameHeader>
          <S.LiveHeader>Live</S.LiveHeader>
          <S.StatusHeader>Status</S.StatusHeader>
          <S.TransHeader>Translations</S.TransHeader>
          <S.ActionsHeader>
            <TableCounter totalItems={totalItems} />
          </S.ActionsHeader>
        </>
      ) : (
        <>
          <S.NameWrapper>
            <NameFilter sortItems={sortItems} sortedState={sortedListStatus} />
          </S.NameWrapper>
          {CategoryColumns}
          {activeColumns.includes("type") && (
            <S.HeaderWrapper>
              <TypeFilter filterItems={filterItems} filters={typeFilters} value={filterValues.type} pointer="type" />
            </S.HeaderWrapper>
          )}
          {activeColumns.includes("live") && (
            <S.HeaderWrapper>
              <LiveFilter filterItems={filterItems} value={filterValues.liveStatus} />
            </S.HeaderWrapper>
          )}
          {activeColumns.includes("status") && (
            <S.HeaderWrapper>
              <StatusFilter sortItems={sortItems} sortedState={sortedListStatus} />
            </S.HeaderWrapper>
          )}
          {activeColumns.includes("translation") && (
            <S.HeaderWrapper>
              <TranslationsFilter filterItems={filterItems} value={filterValues.translated} />
            </S.HeaderWrapper>
          )}
          {activeColumns.includes("seo") && <S.SeoHeader>SEO</S.SeoHeader>}
          <S.GlobalMark />
          <S.ActionsHeader>
            <TableCounter totalItems={totalItems} />
            <CustomizeFilters columns={columns} setColumns={setColumns} value={activeColumns} />
          </S.ActionsHeader>
        </>
      )}
    </S.TableHeader>
  );
};

interface IProps {
  isScrolling: boolean;
  isStructuredData: boolean;
  totalItems: number;
  selectAllItems: () => void;
  checkState: Record<string, boolean>;
  sortItems: (orderPointer: string, isAscending: boolean) => void;
  filterItems: (filterPointer: string, filtersSelected: string) => void;
  sortedListStatus: { isAscending: boolean; sortedByDate: boolean; sortedByTitle: boolean; sortedByURL: boolean };
  categoryColumns: any[];
  columns: Record<string, IColumn>;
  setColumns: (columns: Record<string, IColumn>) => void;
  isGlobalPages: boolean;
  filterValues: any;
  siteID: number;
}

export default TableHeader;
