import {
  SET_ORIGINAL_MENUS,
  SET_ORIGINAL_MENU,
  SET_EDITOR_MENU,
  SET_TYPE,
  SET_ID,
  SET_ITEM,
  SET_MENU_FORM_DATA,
  SET_TOTAL_ITEMS,
} from "./constants";

import { MenuActionsCreators } from "./interfaces";
import { IMenuItem, IMenuForm } from "@ax/types";

export interface IMenuState {
  savedMenus: IMenuItem[] | null;
  savedMenu: any;
  editorMenu: any;
  id: number | null;
  type: string;
  item: IMenuItem | null;
  form: IMenuForm;
  totalItems: number;
}

const initialState = {
  savedMenus: [],
  savedMenu: [],
  editorMenu: {
    elements: [],
  },
  id: null,
  type: "MainMenu",
  item: null,
  form: {
    url: null,
    label: "",
    type: "link",
    headerStyle: "",
    footerStyle: "",
  },
  totalItems: 0,
};

function reducer(state = initialState, action: MenuActionsCreators): IMenuState {
  switch (action.type) {
    case SET_ORIGINAL_MENUS:
    case SET_ORIGINAL_MENU:
    case SET_EDITOR_MENU:
    case SET_TYPE:
    case SET_ID:
    case SET_ITEM:
    case SET_MENU_FORM_DATA:
    case SET_TOTAL_ITEMS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as menuInitialState, reducer as menuReducer };
