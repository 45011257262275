import React, { useReducer, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";

import { IRootState, IStructuredData } from "@ax/types";
import { getThumbnailProps } from "@ax/helpers";
import { MenuItem, RadioGroup } from "@ax/components";
import { structuredDataActions } from "@ax/containers/StructuredData";
import { SecondaryActionButton, MainActionButton } from "./../atoms";

import { reducer, IOptionTableStore, setColumnValues, setShowThumbnail, setSelectedType, setOption } from "./store";

import * as S from "./style";

const OptionTable = (props: IOptionTableProps): JSX.Element => {
  const {
    selectData,
    selectPage,
    filters,
    values,
    selectedValue,
    setIsStructuredData,
    theme,
    mainAction,
    secondaryAction,
    structuredData,
  } = props;

  const filterOptions = (value: string, objKey: string) => values.filter((item: any) => item[objKey] === value);

  const filterOptionsByDataPack = (value: string) => {
    return values.filter((item: any) => {
      return  item.editable !== false && (item.dataPacks ? item.dataPacks.includes(value.toUpperCase()) : item.type === value);
    });
  };

  const currentOption = filterOptions(selectedValue, "value");
  const currentType = currentOption[0] ? currentOption[0].type : "static";

  const initialState: IOptionTableStore = {
    columnValues: filterOptions(currentType, "type"),
    showThumbnail: true,
    selectedOption: selectedValue,
    selectedType: currentType,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const selectOption = (option: any, currentOptions?: any) => {
    const { columnValues } = state;
    const availableOptions = currentOptions ? currentOptions : columnValues;

    const optionObj = availableOptions.find((item: any) => item.value === option);
    setSelectedOption(option);
    if (optionObj) {
      const { isData } = optionObj;
      setValue(option, !!isData);
    }
  };

  let filteredOptionsByDataPack: any = filterOptionsByDataPack(state.selectedType);

  useLayoutEffect(() => {
    displayOptions({ value: state.selectedType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const firstOption = filteredOptionsByDataPack[0] && filteredOptionsByDataPack[0].value;
    selectOption(firstOption, filteredOptionsByDataPack);
    // eslint-disable-next-line
  }, []);

  const setValue = (value: string, isStructuredData: boolean) => {
    setIsStructuredData(isStructuredData);
    isStructuredData ? selectData(value) : selectPage(value);
  };

  const showThumbnail = (isDisplayed: boolean) => dispatch(setShowThumbnail(isDisplayed));
  const setType = (type: string) => dispatch(setSelectedType(type));
  const setValues = (columns: any) => dispatch(setColumnValues(columns));
  const setSelectedOption = (selectedOption: any) => dispatch(setOption({ selectedOption }));

  const isOptionInType = (column: any) => {
    const optionValues = filterOptions(state.selectedOption, "value");
    return column.includes(optionValues[0]);
  };

  const thumbnailProps =
    state.showThumbnail &&
    isOptionInType(filteredOptionsByDataPack) &&
    getThumbnailProps(state.selectedOption, true, theme);

  const displayOptions = (item: any) => {
    const { value } = item;
    setType(value);
    filteredOptionsByDataPack = filterOptionsByDataPack(value);

    filteredOptionsByDataPack.forEach((option: any) => {
      const { name } = option;

      const currentGlobalStructuredData = structuredData.global.find((data: any) =>
        data.schema.templates?.includes(name)
      );

      if (currentGlobalStructuredData) {
        filteredOptionsByDataPack = filteredOptionsByDataPack.map((option: any) => {
          if (option.mode !== "detail") return option;
          const title = `Get ${currentGlobalStructuredData.title} from Global`;
          return { ...option, title };
        });
        const globalOptionIdx = filteredOptionsByDataPack.findIndex((option: any) => option.mode === "detail");
        const globalOption = filteredOptionsByDataPack.splice(globalOptionIdx, 1);
        filteredOptionsByDataPack.push(...globalOption);
      }
    });

    setValues(filteredOptionsByDataPack);
    const firstOption = filteredOptionsByDataPack[0] && filteredOptionsByDataPack[0].value;
    selectOption(firstOption, filteredOptionsByDataPack);

    const optionList = filterOptions(state.selectedOption, "value");
    const displayThumbnail = optionList.type !== value;

    showThumbnail(displayThumbnail);
  };

  const hasThumbnail = state.showThumbnail && thumbnailProps && isOptionInType(filteredOptionsByDataPack);

  return (
    <S.Table>
      <S.Column>
        {filters.map((item: any, i: number) => {
          const displayFilteredOptions = () => state.selectedType !== item.type && displayOptions(item);
          const isSelected = item.value === state.selectedType;
          const selectedClass = isSelected ? "selected" : "";
          return (
            <MenuItem key={`${item.type}${i}`}>
              <S.NavLink onClick={displayFilteredOptions} className={selectedClass}>
                <S.Link active={isSelected}>{item.label}</S.Link>
              </S.NavLink>
            </MenuItem>
          );
        })}
      </S.Column>
      <S.Column>
        <RadioGroup options={state.columnValues} onChange={selectOption} value={state.selectedOption} name="" />
      </S.Column>
      <S.Column>
        {hasThumbnail && (
          <S.ThumbnailWrapper>
            {thumbnailProps && <S.Thumbnail backgroundUrl={thumbnailProps.src} />}
          </S.ThumbnailWrapper>
        )}
        <S.Actions>
          <SecondaryActionButton onClick={secondaryAction.onClick} title={secondaryAction.title} />
          <MainActionButton onClick={mainAction.onClick} title={mainAction.title} />
        </S.Actions>
      </S.Column>
    </S.Table>
  );
};

interface IAction {
  title: string;
  onClick: any;
}

interface IOptionTableProps {
  selectData: (value: string) => void;
  selectPage: (value: string) => void;
  setIsStructuredData: (isActive: boolean) => void;
  filters: any;
  values: any;
  selectedValue: string;
  theme: string;
  mainAction: IAction;
  secondaryAction: IAction;
  structuredData: { global: IStructuredData[]; site: IStructuredData[] };
}

const mapStateToProps = (state: IRootState) => ({
  structuredData: state.structuredData.structuredData,
});

const mapDispatchToProps = {
  setIsStructuredData: structuredDataActions.setIsActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionTable);
