import React from "react";

import { NoteField } from "@ax/components";
import Field from "./Field";

import * as S from "./style";

const noteText = "To create a new header or configure one of them, go to Navigation/Navigations modules";

const NavigationForm = (props: INavigationFormProps): JSX.Element => {
  const { schema, theme } = props;

  return (
    <>
      <S.Title>Content</S.Title>
      <S.FieldWrapper>
        <NoteField value={{ text: noteText }} />
      </S.FieldWrapper>
      <S.FieldWrapper>
        <Field type={schema.type} theme={theme} />
      </S.FieldWrapper>
    </>
  );
};

export interface INavigationFormProps {
  schema: any;
  theme: string;
}

export default NavigationForm;
