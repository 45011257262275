import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: 100%;
`;

const TitleWrapper = styled.div`
  padding: ${(p) => p.theme.spacing.m};
  border-bottom: ${(p) => `1px solid ${p.theme.color.uiLine}`};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const Description = styled.div`
  ${(p) => p.theme.textStyle.uiM};
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: calc(100vh - ${(p) => p.theme.spacing.xl});
  overflow: auto;
`;

const EmptyWrapper = styled.div`
  height: ${(p) => `calc(100vh - (${p.theme.spacing.xl} * 3))`};
  display: flex;
  align-items: center;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

const ModalCenterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.spacing.m};
`;

const ModalContentWrapper = styled.div`
  width: 350px;
`;

const ModalIcon = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.color.uiBackground03};
  width: ${(p) => p.theme.spacing.xl};
  height: ${(p) => p.theme.spacing.xl};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: ${(p) => `0 auto ${p.theme.spacing.m} auto`};
`;

const ModalIconWrapper = styled.div`
  width: ${(p) => p.theme.spacing.l};
  height: ${(p) => p.theme.spacing.l};
  margin-top: ${(p) => p.theme.spacing.xl};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const ModalUploadingText = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) => p.theme.color.interactive01};
  text-align: center;
`;

const ModalTitle = styled.div`
  ${(p) => p.theme.textStyle.headingS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  text-align: center;
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const ModalText = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  text-align: center;
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const ModalImportResult = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const ModalImportWrapper = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  border-bottom: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const ModalImportSection = styled.div`
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const ModalImportItem = styled.div`
  display: flex;
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const ModalItemIcon = styled.div<{ type: "error" | "info" | "online" }>`
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  margin-right: ${(p) => p.theme.spacing.xs};
  svg {
    path {
      fill: ${(p) => p.theme.color[p.type]};
    }
  }
`;

const ModalItemArrow = styled.div`
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  margin-right: ${(p) => p.theme.spacing.xs};
  margin-left: ${(p) => p.theme.spacing.xs};
`;

const ModalUrl = styled.div`
  width: 50%;
`;

export {
  Wrapper,
  ContentWrapper,
  TitleWrapper,
  Title,
  Description,
  TableWrapper,
  EmptyWrapper,
  ModalContent,
  ModalCenterContent,
  ModalIcon,
  ModalTitle,
  ModalText,
  ModalButtons,
  ModalContentWrapper,
  ModalImportResult,
  ModalImportSection,
  ModalImportWrapper,
  ModalImportItem,
  ModalItemIcon,
  ModalItemArrow,
  ModalUrl,
  ModalIconWrapper,
  ModalUploadingText,
};
