import { useState } from "react";

const useSortedListStatus = () => {
  const sortedInitialState: {
    isAscending: boolean;
    sortedByTitle: boolean;
    sortedByURL: boolean;
    sortedByDate: boolean;
  } = {
    isAscending: false,
    sortedByTitle: false,
    sortedByURL: false,
    sortedByDate: false,
  };

  const [sortedListStatus, setSortedListStatus] = useState(sortedInitialState);

  return {
    sortedListStatus,
    setSortedListStatus,
  };
};

const useFilterQuery = (values?: Record<string, string> | null) => {
  const initialQueryValues = {
    type: "all",
    translated: "all",
    liveStatus: "all",
    order: "",
    categories: "all",
  };

  const getFilterQuery = (filterValues: any) => {
    const { type, translated, liveStatus, order, categories } = filterValues;
    let filterQuery = "";

    const currentQuery = (pointer: string, values: string) => {
      return filterQuery ? filterQuery.concat(`&${pointer}=${values}`) : `?${pointer}=${values}`;
    };

    if (type) {
      filterQuery = currentQuery("type", type);
    }

    if (translated) {
      filterQuery = currentQuery("translated", translated);
    }

    if (liveStatus) {
      filterQuery = currentQuery("liveStatus", liveStatus);
    }

    if (order) {
      filterQuery = currentQuery("order", order);
    }

    if (categories) {
      filterQuery = currentQuery("categories", categories);
    }

    return filterQuery;
  };

  const initState = values ? values : initialQueryValues;
  const [state, setState] = useState(initState);

  const initQuery = values ? getFilterQuery(values) : "";
  const [query, setQuery] = useState(initQuery);

  const setFiltersSelection = (pointer: string, filter: string, isAscendent?: boolean) => {
    const { type, translated, liveStatus, order, categories } = state;
    const orderMethod = isAscendent ? "asc" : "desc";
    const filterValues = {
      type: pointer === "type" ? filter : type,
      translated: pointer === "translated" ? filter : translated,
      liveStatus: pointer === "liveStatus" ? filter : liveStatus,
      order: pointer === "order" ? `${filter}-${orderMethod}` : order,
      categories: pointer === "categories" ? filter : categories,
    };

    setState(filterValues);
    const filterQuery = getFilterQuery(filterValues);
    setQuery(filterQuery);
  };

  const resetFilterQuery = () => {
    setState(initialQueryValues);
    setQuery("");
  };

  return {
    setFiltersSelection,
    resetFilterQuery,
    filterValues: state,
    query,
  };
};

export { useSortedListStatus, useFilterQuery };
