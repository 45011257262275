import styled from "styled-components";

const DetailPanelWrapper = styled.div`
  position: relative;
  opacity: ${(p) => (p.hidden ? "0" : "1")};
  transition: opacity 0.1s;
  height: 100%;
  width: 100%;
`;

const PanelActions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  background-color: ${(p) => p.theme.color.uiBarBackground};
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  border-top: 1px solid ${(p) => p.theme.color.uiLine};
  button:not(:first-child) {
    margin-left: ${(p) => p.theme.spacing.s};
  }
`;

const PanelForm = styled.div`
  position: relative;
  width: 100%;
  height: calc(${(p) => p.theme.spacing.xl} * 8);
  overflow: auto;
`;

const InfoRow = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const InfoWrapper = styled.div`
  padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.s} ${p.theme.spacing.m} ${p.theme.spacing.s}`};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  strong {
    font-weight: 600;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: ${(p) => p.theme.spacing.s};
  align-items: center;

  button:first-child {
    margin-right: ${(p) => p.theme.spacing.xs};
  }
`;

const Name = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  overflow-wrap: break-word;
`;

const FormWrapper = styled.div`
  padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.s} 0 ${p.theme.spacing.s}`};
`;

const Url = styled.div`
  display: flex;
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  background-color: ${(p) => p.theme.color.uiBackground03};
  border-radius: 4px;
  width: 100%;
  height: ${(p) => p.theme.spacing.l};
  padding: 0 ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.m};
  overflow: hidden;
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  width: 42px;
  height: 40px;
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const Label = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const FieldText = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.colors.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

export {
  DetailPanelWrapper,
  PanelActions,
  PanelForm,
  InfoRow,
  InfoWrapper,
  ButtonsWrapper,
  Name,
  FormWrapper,
  Url,
  IconWrapper,
  Label,
  FieldText,
};
