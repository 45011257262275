import React from "react";
import differenceInSeconds from "date-fns/differenceInSeconds";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import { IModule } from "@ax/types";
import { ComponentContainer, SideModal } from "@ax/components";
import { useModal } from "@ax/hooks";

import { getComponentProps, containerToComponentArray, getTypefromKey } from "../helpers";
import AddItemButton from "./AddItemButton";
import PasteModuleButton from "./PasteModuleButton";

import * as S from "./style";

const MixableComponentArray = (props: IMixableComponentArrayProps): JSX.Element => {
  const {
    whiteList,
    title,
    value,
    goTo,
    selectedContent,
    editorID,
    actions,
    categories,
    maxItems,
    disabled,
    activatedModules,
    objKey,
    field,
    mandatory,
    theme,
    moduleCopy,
    availableDataPacks,
    template,
    setHistoryPush,
  } = props;

  const moduleCopyComponent = moduleCopy?.element.component;

  const availableDataPackModule = availableDataPacks?.reduce((prev: any, curr: any) => {
    const packModule = curr.modules.find((module: any) => module.id === moduleCopyComponent);
    return prev || packModule;
  }, null);

  const isModuleCopyUnavailable =
    availableDataPackModule &&
    !whiteList.includes(moduleCopyComponent) &&
    !!availableDataPackModule?.sectionList[template.component]?.includes(field.key);

  const type = getTypefromKey(objKey);
  const { contentType = type } = field;
  const { isOpen, toggleModal } = useModal();

  let addModuleAction: any;
  let addComponentAction: any;
  let deleteModuleAction: any;
  let replaceElementsInCollectionAction: any;

  if (actions) {
    addModuleAction = actions.addModuleAction;
    addComponentAction = actions.addComponentAction;
    deleteModuleAction = actions.deleteModuleAction;
    replaceElementsInCollectionAction = actions.replaceElementsInCollectionAction;
  }

  // fix for old not array values
  const fixedValue = Array.isArray(value) ? value : containerToComponentArray(value);

  const getText = (name: string, index: number) => {
    return fixedValue.length > 1 ? `#${index + 1} ${name}` : name;
  };

  const isComponentModule = contentType === "components";
  const isModuleArr = contentType === "modules";

  const handleAddModule = (moduleType: string) => addModuleAction(moduleType, objKey, editorID, isComponentModule);

  const handleAddComponent = (componentType: string) => addComponentAction && addComponentAction(componentType, objKey);

  const handleAdd = isModuleArr ? handleAddModule : handleAddComponent;

  const handleModuleReplace = (moduleType: string) => {
    const { modules } = selectedContent;
    if (isModuleArr) {
      const currentModule = modules[0];
      deleteModuleAction(currentModule?.editorID, contentType);
      handleAddModule(moduleType);
    } else {
      replaceElementsInCollectionAction(moduleType);
    }
  };

  const getTimeSinceModuleCopy = (moduleCopy: { date: string; element: Record<string, any> }) =>
    differenceInSeconds(new Date(), new Date(moduleCopy.date));

  const showAddItemButton = (!maxItems || fixedValue.length < maxItems) && !disabled;
  const eightHoursInSeconds = 8 * 60 * 60;

  const showPasteModuleButton: boolean =
    showAddItemButton &&
    (isModuleArr || objKey === "componentModules") &&
    !!moduleCopy &&
    getTimeSinceModuleCopy(moduleCopy) < eightHoursInSeconds &&
    (whiteList.includes(moduleCopyComponent) || isModuleCopyUnavailable);

  const canReplace = maxItems === 1 && whiteList.length > 1;
  const displayReplaceSideModal = fixedValue.length > 0 && canReplace;
  const optionsType = isModuleArr ? "modules" : "components";

  const Asterisk = () => (mandatory ? <S.Asterisk>*</S.Asterisk> : null);

  const ComponentList = React.memo(function ComponentList({ components }: any) {
    return components.map((element: any, i: number) => {
      const { editorID } = element;
      const { moduleTitle, isModuleDeactivated, componentTitle, displayName, isModule } = getComponentProps(
        element,
        activatedModules,
        isModuleArr
      );
      const text = getText(componentTitle || displayName, i);
      return (
        <Draggable draggableId={`${editorID}`} index={i} key={editorID}>
          {(provided) => (
            <ComponentContainer
              actionReplace={toggleModal}
              canReplace={canReplace}
              isArray={true}
              key={editorID}
              editorID={editorID}
              goTo={goTo}
              text={text}
              moduleTitle={moduleTitle}
              whiteList={whiteList}
              categories={categories}
              actions={actions}
              selectedContent={selectedContent}
              disabled={disabled}
              canDuplicate={showAddItemButton && !isModuleDeactivated}
              parentKey={objKey}
              theme={theme}
              arrayLength={components.length}
              innerRef={provided.innerRef}
              provided={provided}
              isModule={isModule}
            />
          )}
        </Draggable>
      );
    });
  });

  const onDragEnd = (result: DropResult) => {
    const { moveModuleAction } = actions;

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    moveModuleAction(parseInt(result.draggableId), selectedContent, result.destination.index, objKey);
  };

  return (
    <S.Wrapper>
      <S.Title>
        {title} <Asterisk />
      </S.Title>
      <S.ItemRow>
        <S.Subtitle>{fixedValue && fixedValue.length} items</S.Subtitle>
        <S.ActionsWrapper data-testid="mixableComponentWrapper">
          {showPasteModuleButton && (
            <PasteModuleButton
              editorID={editorID}
              isModuleCopyUnavailable={isModuleCopyUnavailable}
              pasteModule={actions.pasteModuleAction}
              setNotification={actions.setNotificationAction}
              setHistoryPush={setHistoryPush}
              arrayKey={objKey}
            />
          )}
          {showAddItemButton && !disabled && (
            <AddItemButton
              isOpen={isOpen}
              toggleModal={toggleModal}
              whiteList={whiteList}
              categories={categories}
              handleClick={handleAdd}
              isModuleArr={isModuleArr}
              theme={theme}
            />
          )}
        </S.ActionsWrapper>
      </S.ItemRow>
      {fixedValue && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <ComponentList components={fixedValue} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {displayReplaceSideModal && isOpen && (
        <SideModal
          optionsType={optionsType}
          whiteList={whiteList}
          categories={categories}
          toggleModal={toggleModal}
          isOpen={isOpen}
          handleClick={handleModuleReplace}
          theme={theme}
          showSearch
        />
      )}
    </S.Wrapper>
  );
};

export interface IMixableComponentArrayProps {
  maxItems: number;
  title: string;
  whiteList: any[];
  value?: IModule[];
  selectedContent: any;
  editorID: number;
  goTo: (editorID: string) => void;
  actions: any;
  categories?: any;
  disabled?: boolean;
  activatedModules: string[];
  objKey: string;
  field: any;
  mandatory?: boolean;
  theme: string;
  moduleCopy: { date: string; element: Record<string, any> } | null;
  availableDataPacks: Record<string, any>[];
  template: any;
  setHistoryPush?: (path: string, isEditor: boolean) => void;
}

export default MixableComponentArray;
