import React, { useState } from "react";
import { connect } from "react-redux";

import { structuredDataActions } from "@ax/containers/StructuredData";
import { useModal, usePermission } from "@ax/hooks";
import { IStructuredDataContent, ICategory, ICheck, IRootState, IDataLanguage } from "@ax/types";
import { CheckField, FloatingMenu, Flag, LanguageMenu } from "@ax/components";
import CategoryPanel from "../CategoryPanel";
import { DeleteModal } from "../atoms";

import * as S from "./style";

const CategoryItem = (props: ICategoryItemProps): JSX.Element => {
  const {
    category,
    setCategoryValues,
    deleteStructuredDataContent,
    resetCategoryValues,
    setEntity,
    getTranslatedCategory,
    lang,
    languages,
    isTranslatable,
    isSelected,
    onChange,
    toggleToast,
    setDeletedItem,
    getContents,
    currentSiteID,
  } = props;

  const { isOpen, toggleModal } = useModal();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const [deleteAllVersions, setDeleteAllVersions] = useState(false);

  const allowedToEditSiteCategory = usePermission("categories.editSiteTaxonomies");
  const allowedToEditGlobalCategory = usePermission("global.globalData.editTaxonomies");
  const allowedToDeleteSiteCategory = usePermission("categories.deleteSiteTaxonomies");
  const allowedToDeleteGlobalCategory = usePermission("global.globalData.deleteTaxonomies");
  const allowedToEditTaxonomy = currentSiteID ? allowedToEditSiteCategory : allowedToEditGlobalCategory;
  const allowedToDeleteTaxonomy = currentSiteID ? allowedToDeleteSiteCategory : allowedToDeleteGlobalCategory;

  const { locale } = lang;
  const { dataLanguages, content } = category;
  const isTranslated = dataLanguages.length > 1;

  const handleClick = () => {
    resetCategoryValues();
    setCategoryValues({ name: content.title, code: content.code, lang, isTranslation: false, isNew: false });
    toggleModal();
  };

  const handleOnChange = (value: ICheck) => onChange(value);

  const removeItem = async () => {
    const allPageVersions = dataLanguages.map((lang: IDataLanguage) => lang.id);
    const catIds = deleteAllVersions ? allPageVersions : category.id;
    const deleted = await deleteStructuredDataContent(catIds);

    if (deleted) {
      setDeletedItem(catIds);
      isDeleteOpen && toggleDeleteModal();
      toggleToast();
    }
  };

  const menuOptions = allowedToDeleteTaxonomy
    ? [
        {
          label: "delete",
          icon: "delete",
          action: isTranslated ? toggleDeleteModal : removeItem,
        },
      ]
    : [];

  const getCurrentLanguages = () => {
    const availables: any[] = [];

    dataLanguages.forEach(
      (dataLang: any) =>
        languages &&
        languages.forEach((language) => {
          if (language.id === dataLang.language) {
            availables.push(language);
          }
        })
    );

    return availables;
  };

  const currentLanguages = getCurrentLanguages();

  const handleLanguage = (language: any) => () => {
    const {
      category: { content, entity, id: idCat },
    } = props;

    const { locale, id } = language;

    const lang = {
      locale,
      id,
    };

    const isNew = currentLanguages.find((l) => l.id === language.id) ? false : true;

    resetCategoryValues();
    if (isNew) {
      setCategoryValues({ name: content.title, code: content.code, lang, isTranslation: true, isNew });
    } else {
      getTranslatedCategory(idCat, lang);
    }
    setEntity(entity);
    toggleModal();
  };

  const languageMenu = () => (
    <LanguageMenu
      language={locale}
      availableLanguages={languages}
      setLanguage={handleLanguage}
      currentLanguages={currentLanguages}
    />
  );

  const FlagsButton = () => (
    <S.FlagsWrapper>
      {currentLanguages.slice(0, 2).map((pageLanguage: any, i: number) => (
        <Flag key={`${pageLanguage.language}${i}`} name={pageLanguage.locale} size="15" />
      ))}
      <span>({currentLanguages.length})</span>
    </S.FlagsWrapper>
  );

  const translations = isTranslatable ? (
    <FloatingMenu Button={FlagsButton}>{languageMenu()}</FloatingMenu>
  ) : (
    "Not translatable"
  );

  const mainDeleteModalAction = {
    title: "Delete category",
    onClick: removeItem,
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  return (
    <>
      <S.CategoryRow role="rowgroup" selected={isSelected}>
        <S.CheckCell role="cell">
          <CheckField name="check" value={category.id} checked={isSelected} onChange={handleOnChange} />
        </S.CheckCell>
        <S.NameCell role="cell" onClick={handleClick} clickable={allowedToEditTaxonomy}>
          {content.title}
        </S.NameCell>
        <S.CodeCell role="cell" onClick={handleClick} clickable={allowedToEditTaxonomy}>
          {content.code}
        </S.CodeCell>
        <S.TransCell role="cell">{translations}</S.TransCell>
        <S.ActionsCell role="cell">
          <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Actions" />
        </S.ActionsCell>
      </S.CategoryRow>
      <CategoryPanel isOpen={isOpen} toggleModal={toggleModal} item={category} getContents={getContents} />
      {isDeleteOpen && (
        <DeleteModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
          {...{ deleteAllVersions, setDeleteAllVersions, title: category.title }}
        />
      )}
    </>
  );
};

interface IProps {
  category: IStructuredDataContent;
  languages: any[];
  lang: { locale: string; id: number | null };
  isTranslatable: boolean;
  isSelected: boolean;
  onChange: (e: any) => void;
  toggleToast(): void;
  setDeletedItem(item: number | number[]): void;
  getContents(dataId: string): void;
  currentSiteID: number | null;
}

interface IDispatchProps {
  setCategoryValues(value: ICategory): void;
  deleteStructuredDataContent(id: number | number[]): Promise<boolean>;
  resetCategoryValues(): void;
  setEntity(entity: string): void;
  getTranslatedCategory(id: number, lang: { locale: string; id: number }): any;
}

type ICategoryItemProps = IProps & IDispatchProps;

const mapStateToProps = (state: IRootState) => ({
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
});

const mapDispatchToProps = {
  setCategoryValues: structuredDataActions.setCategoryValues,
  deleteStructuredDataContent: structuredDataActions.deleteStructuredDataContent,
  resetCategoryValues: structuredDataActions.resetCategoryValues,
  setEntity: structuredDataActions.setEntity,
  getTranslatedCategory: structuredDataActions.getTranslatedCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryItem);
