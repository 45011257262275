import React, { useState } from "react";

import { FloatingMenu, Icon, ListTitle, ListItem } from "@ax/components";

import { areEquals } from "@ax/helpers";

import * as S from "./style";

const Type = ({ filterItems }: ITypeProps): JSX.Element => {
  const initialState = ["all"];
  const [selectedValue, setSelectedValue] = useState(initialState);

  const setQuery = (selection: any) => {
    if (!selection.length) {
      selection = initialState;
    }
    setSelectedValue([selection]);
    const pointer = "format";
    filterItems(pointer, selection);
  };

  const isActive = !areEquals(selectedValue, initialState);

  const Header = () => (
    <S.Type data-testid="type-wrapper" isActive={isActive}>
      Media type
      <S.IconsWrapper>
        {isActive && <Icon name="Filter" size="16" />}
        <S.InteractiveArrow>
          <Icon name="DownArrow" size="16" />
        </S.InteractiveArrow>
      </S.IconsWrapper>
    </S.Type>
  );

  return (
    <FloatingMenu Button={Header} position="center" closeOnSelect={true}>
      <ListTitle>Filter by type</ListTitle>
      <ListItem isSelected={selectedValue.includes("all")} onClick={() => setQuery("all")}>All</ListItem>
      <ListItem isSelected={selectedValue.includes("bitmap")} onClick={() => setQuery("bitmap")}>Image</ListItem>
      <ListItem isSelected={selectedValue.includes("vectorial")} onClick={() => setQuery("vectorial")}>Icons</ListItem>
    </FloatingMenu>
  );
};

export interface ITypeProps {
  filterItems(pointer: string, filter: string): void;
}

export default Type;
