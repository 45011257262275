import React, { useEffect, useState, memo, useRef, useCallback, useLayoutEffect } from "react";
import { connect } from "react-redux";

import { IFile, IFilesFolder, IFolder, IFolderTree, IGetFolderParams, IRootState, ISite } from "@ax/types";
import {
  Loader,
  Tabs,
  SearchField,
  EmptyState,
  ErrorToast,
  Notification,
  Tooltip,
  Icon,
  BackFolder,
} from "@ax/components";
import { fileDriveActions } from "@ax/containers/FileDrive";
import { usePermission } from "@ax/hooks";

import GalleryPanel from "./GalleryPanel";
import GridItem from "./GridItem";
import FolderItem from "./FolderItem";

import * as S from "./style";

// refactor
import Breadcrumb from "@ax/modules/FileDrive/Breadcrumb";
import FolderTree from "@ax/modules/FileDrive/FolderTree";

const FileGallery = (props: IProps): JSX.Element => {
  const {
    currentFolderContent,
    site,
    currentFolderID,
    validFormats,
    breadcrumb,
    toggleModal,
    getFolderContent,
    getFoldersTree,
    addFile,
    updateCurrentFolder,
  } = props;

  const {
    files: { items },
    folders,
  } = currentFolderContent || { files: { items: [] }, folders: [] };

  const [selectedTab, setSelectedTab] = useState(site ? "local" : "global");
  const [selectedFile, setSelectedFile] = useState<IFile | null>(null);
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isLocalTab = selectedTab === "local";
  const isGlobalTab = selectedTab === "global";
  const galleryScope = isLocalTab && site ? site.id : "global";
  const hasFolders = !!folders.length;
  const isRoot = !breadcrumb.length;

  const galleryRef = useRef<HTMLDivElement>(null);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const isSearching = searchQuery.length > 0;

  const isSiteView = !!site;
  const isTabGlobal = selectedTab === "global";
  const siteID: number | "global" = !isSiteView || isTabGlobal ? "global" : site.id;

  const allowedToAccessGlobalFromSite = usePermission("mediaGallery.accessToGlobalFileDriveFromSite");

  const allowedToAddSiteFile = usePermission("mediaGallery.addFiles");
  const allowedToAddGlobalFile = usePermission("global.mediaGallery.addGlobalFiles");
  const allowedToAddGlobalFileFromSite = usePermission("mediaGallery.addGlobalFilesFromSite");
  const allowedToAddFile = isTabGlobal
    ? allowedToAddGlobalFileFromSite
    : isSiteView
    ? allowedToAddSiteFile
    : allowedToAddGlobalFile;

  const allowedToEditSiteFile = usePermission("mediaGallery.editFilesss");
  const allowedToEditGlobalFile = usePermission("global.mediaGallery.editGlobalFiles");
  const allowedToEditGlobalFileFromSite = usePermission("mediaGallery.editGlobalFilesInSite");
  const allowedToEditFile = isTabGlobal
    ? allowedToEditGlobalFileFromSite
    : isSiteView
    ? allowedToEditSiteFile
    : allowedToEditGlobalFile;

  const tabs: string[] = [];
  if (isSiteView && allowedToAccessGlobalFromSite) {
    tabs.unshift(...["local", "global"]);
  }

  const getParams = useCallback(() => {
    const params = {
      siteID,
      folderID: currentFolderID,
      search: searchQuery,
      loading: false,
    };

    return params;
  }, [currentFolderID, searchQuery, selectedTab]);

  useLayoutEffect(() => {
    return () => {
      updateCurrentFolder(null);
    };
  }, []);

  useEffect(() => {
    const handleGetContent = async () => {
      setIsLoading(true);
      const params = getParams();
      await getFolderContent(params);
      setIsLoading(false);
    };
    handleGetContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams]);

  useEffect(() => {
    const handleGetTree = async () => await getFoldersTree(siteID);
    handleGetTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const handleClick = (item: IFile) => {
    if (item.id !== selectedFile?.id) {
      setSelectedFile(item);
    } else {
      setSelectedFile(null);
    }
  };

  const setFile = (fileData: any) => {
    const updatedImage = { ...selectedFile, ...fileData };
    addFile(updatedImage);
    toggleModal();
  };

  const handleSelectedTab = (tab: string) => {
    if (tab !== selectedTab) {
      updateCurrentFolder(null);
      setSelectedFile(null);
      setSelectedTab(tab);
    }
  };

  const togglePanel = () => setPanelOpen(!isPanelOpen);

  const handleBackClick = () => {
    const parentID = breadcrumb.length >= 2 ? breadcrumb[breadcrumb.length - 2].id : null;
    updateCurrentFolder(parentID);
  };

  const handleUpload = async (file: IFile[]) => {
    setSelectedFile(file[0]);
    const params = getParams();
    await getFolderContent(params);
  };

  const foldersIcon = isPanelOpen ? <Icon name="closePanel" size="24" /> : <Icon name="openPanel" size="24" />;

  const emptySearchStateProps = {
    icon: "search",
    title: "Oh! No Results Found",
    message: "We couldn’t find what you are looking for. Please, try another search.",
  };

  const emptyFolderStateProps = {
    title: "Oh! This looks so empty",
    message: "No documents found in this folder.",
  };

  return (
    <S.Wrapper data-testid="file-gallery-wrapper">
      <S.FolderPanel isOpen={isPanelOpen}>
        <S.FolderPanelContent>
          <FolderTree folderID={currentFolderID || 0} onClick={updateCurrentFolder} title="Folders" trimNames={true} />
        </S.FolderPanelContent>
      </S.FolderPanel>
      <S.ContentWrapper>
          {!!tabs.length && <S.Header>
            {!!tabs.length && (
              <S.TabsWrapper>
                <Tabs tabs={tabs} active={selectedTab} setSelectedTab={handleSelectedTab} noMargins />
              </S.TabsWrapper>
            )}
            <S.Filters></S.Filters>
          </S.Header>}
          <S.Search>
            <SearchField onChange={setSearchQuery} placeholder="Type file’s name, title, or #tag" />
          </S.Search>
          <S.GalleryWrapper ref={galleryRef}>
            {isGlobalTab && isSiteView && (
              <S.NotificationWrapper>
                <Notification
                  type="info"
                  text="This is a global Library. All the changes you make will be applied to all the sites."
                />
              </S.NotificationWrapper>
            )}
            <ErrorToast size="l" />
            {isLoading ? (
              <S.LoadingWrapper>
                <Loader name="circle" />
              </S.LoadingWrapper>
            ) : (
              <>
                {!isRoot && !isSearching && <Breadcrumb breadcrumb={breadcrumb} onClick={updateCurrentFolder} />}
                {(hasFolders || (!isRoot && !isSearching)) && (
                  <S.SectionWrapper>
                    <S.SectionHeader>
                      <S.SectionTitle>
                        <div>Folders</div>
                        <S.FoldersIconWrapper onClick={togglePanel}>
                          <Tooltip content="Open folder panel">{foldersIcon}</Tooltip>
                        </S.FoldersIconWrapper>
                      </S.SectionTitle>
                    </S.SectionHeader>
                    <S.FoldersWrapper>
                      {!isRoot && !isSearching && <BackFolder onClick={handleBackClick} size="S" />}
                      <S.FoldersGrid>
                        {folders.map((folder: IFolder) => (
                          <FolderItem folder={folder} onClick={updateCurrentFolder} key={folder.folderName} />
                        ))}
                      </S.FoldersGrid>
                    </S.FoldersWrapper>
                  </S.SectionWrapper>
                )}
                <S.SectionWrapper>
                  <S.SectionHeader>
                    <S.SectionTitle>Documents</S.SectionTitle>
                  </S.SectionHeader>
                  {!items.length ? (
                    <S.EmptyWrapper>
                      {isSearching ? (
                        <EmptyState {...emptySearchStateProps} />
                      ) : (
                        <EmptyState {...emptyFolderStateProps} />
                      )}
                    </S.EmptyWrapper>
                  ) : (
                    <S.Grid>
                      {items &&
                        items.map((item: IFile) => {
                          const isSelected = item.id === selectedFile?.id;
                          return (
                            <GridItem file={item} onClick={handleClick} key={item.fileName} isSelected={isSelected} />
                          );
                        })}
                    </S.Grid>
                  )}
                </S.SectionWrapper>
              </>
            )}
          </S.GalleryWrapper>
      </S.ContentWrapper>
      <GalleryPanel
        selectedFile={selectedFile}
        validFormats={validFormats}
        setFile={setFile}
        scope={galleryScope}
        currentFolderID={currentFolderID}
        handleUpload={handleUpload}
        isAllowedToAdd={allowedToAddFile}
        isAllowedToEdit={allowedToEditFile}
      />
    </S.Wrapper>
  );
};

export interface IGalleryProps {
  site: ISite | null;
  currentFolderContent: IFilesFolder | null;
  currentFolderID: number | null;
  isLoading: boolean;
  validFormats: string[];
  breadcrumb: IFolderTree[];
  toggleModal: () => void;
  addFile: (file: IFile) => void;
}

const mapStateToProps = (state: IRootState) => ({
  isLoading: state.app.isLoading,
  currentFolderContent: state.fileDrive.currentFolderContent,
  currentFolderID: state.fileDrive.currentFolderID,
  breadcrumb: state.fileDrive.breadcrumb,
});

export interface IDispatchProps {
  getFolderContent(params: IGetFolderParams): Promise<void>;
  getFoldersTree(siteID: number | "global"): Promise<void>;
  updateCurrentFolder(folderID: number | null): void;
}

const mapDispatchToProps = {
  getFolderContent: fileDriveActions.getFolderContent,
  getFoldersTree: fileDriveActions.getFoldersTree,
  updateCurrentFolder: fileDriveActions.updateCurrentFolder,
};

type IProps = IGalleryProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(memo(FileGallery));
