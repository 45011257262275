import React from "react";

import { Icon, FloatingMenu, ListTitle, ListItem } from "@ax/components";

import * as S from "./style";

const NameFilter = (props: INameFilterProps): JSX.Element => {
  const { sortItems, sortedState, urlSorting, pointer = "title" } = props;
  const { isAscending, sortedByTitle, sortedByURL } = sortedState;

  const sortByName = (isAscending: boolean) => sortItems(pointer, isAscending);
  const sortAscendingURL = () => sortItems("slug", true);
  const sortAscendingName = () => sortByName(true);
  const sortDescendingName = () => sortByName(false);

  const SortedStateArrow = () =>
    isAscending ? <Icon name="FullArrowUp" size="16" /> : <Icon name="FullArrowDown" size="16" />;
  const isActive = sortedByTitle || sortedByURL;

  const title = urlSorting ? "Name & URL" : "Name";

  const Header = () => (
    <S.NameHeader isActive={isActive} data-testid="name-filter-header">
      {title}
      <S.IconsWrapper>
        {isActive ? (
          <SortedStateArrow />
        ) : (
          <S.InteractiveArrow data-testid="name-filter-interactive-arrow">
            <Icon name="DownArrow" size="16" />
          </S.InteractiveArrow>
        )}
      </S.IconsWrapper>
    </S.NameHeader>
  );

  return (
    <FloatingMenu Button={Header} position="left">
      <ListTitle>Name Sorting</ListTitle>
      <ListItem isSelected={sortedByTitle && isAscending} onClick={sortAscendingName}>
        Ascendent
      </ListItem>
      <ListItem isSelected={sortedByTitle && !isAscending} onClick={sortDescendingName}>
        Descendent
      </ListItem>
      {urlSorting && (
        <>
          <ListTitle>URL Sorting</ListTitle>
          <ListItem isSelected={sortedByURL} onClick={sortAscendingURL}>
            Ascendent
          </ListItem>
        </>
      )}
    </FloatingMenu>
  );
};

export interface INameFilterProps {
  sortedState: any;
  sortItems(orderPointer: string, isAscendent: boolean): void;
  urlSorting?: boolean;
  pointer?: string;
}

export default NameFilter;
