import React from "react";
import { useModal } from "@ax/hooks";

import { HiddenField, SideModal } from "@ax/components";

const EmptyContainer = (props: IProps) => {
  const { hasMultipleOptions, whiteList, handleAdd, goTo, componentOptions, title, categories, theme } = props;
  const { isOpen, toggleModal } = useModal();

  const handleClick = () => (hasMultipleOptions ? toggleModal() : goTo());

  const displayName = title ? title : whiteList && whiteList[0];

  return (
    <>
      <HiddenField title={displayName} showField={handleClick} hasMultipleOptions={hasMultipleOptions} />
      {hasMultipleOptions && (
        <SideModal
          optionsType={"components"}
          whiteList={whiteList}
          categories={categories}
          toggleModal={toggleModal}
          handleClick={handleAdd}
          isOpen={isOpen}
          componentOptions={componentOptions}
          theme={theme}
        />
      )}
    </>
  );
};

interface IProps {
  hasMultipleOptions: boolean | undefined;
  whiteList: any[] | undefined;
  goTo?: any;
  componentOptions: any;
  title?: string;
  categories?: any;
  handleAdd: any;
  theme: string;
}

export default EmptyContainer;
