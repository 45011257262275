import React, { useEffect } from "react";
import { connect } from "react-redux";

import { useModal } from "@ax/hooks";
import { IStructuredDataContent, IDataSource, ISite, IRootState, IStructuredData } from "@ax/types";
import { structuredData } from "@ax/api";
import { capitalize, isReqOk, splitCamelCase } from "@ax/helpers";
import { IconAction, FloatingPanel, Select, Tag } from "@ax/components";
import AutoPanel from "./AutoPanel";
import ItemList from "./ItemList";
import ManualPanel from "./ManualPanel";
import { useReference, ReferenceProvider, IReferenceState } from "./Context";

import * as S from "./style";

const ReferenceFieldWithProvider = (props: IReferenceFieldProps) => (
  <ReferenceProvider modes={props.selectionType}>
    <ReferenceField {...props} />
  </ReferenceProvider>
);

const ReferenceField = (props: IReferenceFieldProps) => {
  const {
    source,
    value,
    onChange,
    disabled,
    site,
    selectionType,
    structuredDataValues,
    categories,
    validators,
    maxItems,
    resetValidation,
    handleValidation,
    mandatory,
    editorID,
  } = props;

  const { isOpen, toggleModal } = useModal();

  const { state, setState, setModeAndSource } = useReference();
  const { mode } = state;
  const singleMode = selectionType && selectionType.length === 1;
  const isAuto = mode === "auto";
  const hasMaxItems = !!(value && value.fixed && maxItems && !isAuto && value.fixed.length >= maxItems);

  const handleMode = (mode: string) => {
    const { fixed, order, quantity, filter, allLanguages, preferenceLanguage, fullRelations = false } = state;

    const manualSources: string[] = state.selectedItems.reduce(
      (unique: string[], selItem: IStructuredDataContent) =>
        unique.includes(selItem.structuredData) ? unique : [...unique, selItem.structuredData],
      []
    );

    const resetSource = mode === "manual" || source.length === 1 ? source : manualSources;

    const newValue =
      mode === "auto"
        ? {
            mode,
            source,
            order,
            quantity,
            filter,
            fullRelations,
            allLanguages,
            preferenceLanguage,
          }
        : {
            mode,
            source,
            fixed,
            fullRelations,
          };
    onChange(newValue);
    setModeAndSource(mode, resetSource);
    handleValidation && handleValidation(value.fixed, validators);
  };

  useEffect(() => {
    const getSourcesTitles = async () => {
      const response = await structuredData.getDataTitles(source);
      if (isReqOk(response?.status)) {
        return response.data;
      } else {
        console.log("Error en getSourcesTitles");
        return [];
      }
    };

    let isMounted = true;
    if (isMounted) {
      getSourcesTitles().then((result: IDataSource[]) => {
        let newState = { ...state, ...value, sourceTitles: result };
        if (value?.order) {
          const [order, orderDirection = "ASC"] = value.order.split("-");
          newState = { ...newState, order, orderDirection };
        }
        setState({ ...newState });
      });
    }

    return function cleanup() {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorID]);

  const handleAddItems = () => {
    setState((state: IReferenceState) => ({ ...state, showSelected: false }));
    toggleModal();
  };

  const options = [
    { label: "Manual", value: "manual" },
    { label: "Automatic", value: "auto" },
  ];

  const handleOnChange = (newValue: any) => {
    onChange(newValue);
    toggleModal();
  };

  const handleListDelete = (fixed: string[]) => {
    const newValue = {
      mode,
      fixed,
    };
    onChange(newValue);
    resetValidation && resetValidation();
  };

  const icon = isAuto ? "edit" : "add";

  const defaultOrders: any = {
    alpha: "Alphabetical order",
    recent: "Most recent",
  };

  const getOrderLabel = () => {
    const stateOrder = splitCamelCase(state.order).toLowerCase();
    const orderLabel = defaultOrders[state.order] || capitalize(stateOrder);
    const directionLabel = state.orderDirection === "ASC" ? "(ascendent)" : "(descendent)";
    return `${orderLabel} ${directionLabel}`;
  };

  const AutoData = () => (
    <S.DataWrapper data-testid="auto-data-wrapper">
      <S.SourcesWrapper>
        {value &&
          value.source &&
          value.source.map((singleSource: string) => {
            const srcFilters = state.filter.filter((f: any) => f.source === singleSource);
            const srcTitle: any = state.sourceTitles.find((sour: any) => sour.id === singleSource);
            const title = srcTitle ? srcTitle.title : "";
            return (
              <S.TypeContainer key={singleSource}>
                <S.Title>{title}</S.Title>
                {srcFilters.map((f: any) => (
                  <Tag key={f.label} text={f.label} />
                ))}
              </S.TypeContainer>
            );
          })}
      </S.SourcesWrapper>
      <S.ConfigWrapper>
        {value && value.quantity ? `${value.quantity} items` : "All items"} - {getOrderLabel()}
      </S.ConfigWrapper>
    </S.DataWrapper>
  );

  const getPanel = () =>
    isAuto ? (
      <AutoPanel
        structuredData={structuredDataValues}
        categories={categories}
        onChange={handleOnChange}
        site={site}
        validators={validators}
      />
    ) : (
      <ManualPanel
        onChange={handleOnChange}
        hasMaxItems={hasMaxItems}
        handleValidation={handleValidation}
        validators={validators}
      />
    );

  const manualItems = !isAuto && value && Array.isArray(value.fixed) ? value.fixed.length : 0;

  const Asterisk = () => (mandatory ? <S.Asterisk>*</S.Asterisk> : <></>);

  const dataBlock = isAuto ? (
    <AutoData />
  ) : (
    <ItemList items={value?.fixed ? value.fixed : []} handleListDelete={handleListDelete} site={value?.site} />
  );

  return (
    <S.Wrapper data-testid="reference-field-wrapper">
      <S.Label>
        Elements
        <Asterisk />
      </S.Label>
      <S.ModeWrapper>
        {!singleMode && (
          <Select
            name="select"
            options={options}
            onChange={handleMode}
            value={mode}
            type="inline"
            disabled={disabled}
            mandatory={true}
          />
        )}
        {singleMode && !isAuto && value && <S.SingleModeText>{manualItems} items</S.SingleModeText>}
        {singleMode && isAuto && <S.SingleModeText>Configure automatic selection</S.SingleModeText>}
        {!disabled && !hasMaxItems && (
          <S.ActionWrapper>
            <IconAction icon={icon} onClick={handleAddItems} />
          </S.ActionWrapper>
        )}
      </S.ModeWrapper>
      {dataBlock}
      <FloatingPanel title="Configure elements" toggleModal={toggleModal} isOpen={isOpen}>
        {isOpen && getPanel()}
      </FloatingPanel>
    </S.Wrapper>
  );
};

export interface IReferenceFieldProps {
  source: string[];
  value?: any;
  onChange: (value: any) => void;
  disabled?: boolean;
  site: ISite | null;
  selectionType?: string[];
  structuredDataValues: { global: IStructuredData[]; site: IStructuredData[] };
  categories: { global: IStructuredData[]; site: IStructuredData[] };
  validators?: Record<string, unknown>;
  maxItems?: number;
  resetValidation?: () => void;
  handleValidation?: (value: string, validators?: Record<string, unknown>) => void;
  mandatory: boolean;
  editorID: number;
}

const mapStateToProps = (state: IRootState) => ({
  structuredDataValues: state.structuredData.structuredData,
  categories: state.structuredData.categories,
});

export default connect(mapStateToProps, null)(ReferenceFieldWithProvider);
