import React, { useState, useEffect } from "react";

import { CheckGroup, Icon, FloatingMenu, ListTitle } from "@ax/components";
import { checkgroups } from "@ax/api";
import { areEquals, isNumber, isReqOk } from "@ax/helpers";
import { IFilterValue, IPageLiveStatus } from "@ax/types";

import * as S from "./style";

const LiveFilter = (props: ILiveFilterProps): JSX.Element => {
  const { filterItems, value, hasBasicStatus } = props;

  const filters: IFilterValue[] = [
    {
      name: "all",
      value: "all",
      title: "All",
    },
  ];

  const storedValue = value.split(",");
  const parsedValue = storedValue.map((value) => (isNumber(value) ? parseInt(value) : value));
  const [initialState] = useState(parsedValue);
  const selectAllOption = "all";
  const [selectedValue, setSelectedValue] = useState([...initialState]);
  const [options, setOptions] = useState(filters);
  const basicStatus = ["offline", "active"];

  useEffect(() => {
    setSelectedValue([...storedValue]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getLiveStatusValues = async () => {
    try {
      const items = await checkgroups.getLiveStatusItems();
      if (items && isReqOk(items.status)) {
        return items.data;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  useEffect(() => {
    getLiveStatusValues()
      .then((items) => {
        items.length > 0 && items.forEach((item: IPageLiveStatus) => {
          const newFilter = {
            name: item.status,
            value: item.status,
            title: item.title,
            icon: item.status,
          };
          if ((hasBasicStatus && basicStatus.includes(newFilter.value)) || !hasBasicStatus) {
            filters.push(newFilter);
          }
        });
        setOptions(filters);
      })
      .catch((apiError) => console.log(apiError));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setQuery = (selection: any) => {
    if (!selection.length) {
      selection = [selectAllOption];
    }
    setSelectedValue(selection);
    const queryFilters = selection.join(",");
    const pointer = "liveStatus";
    filterItems(pointer, queryFilters);
  };

  const isActive = !areEquals(selectedValue, selectAllOption);

  const Header = () => (
    <S.MenuWrapper>
      <S.LiveOptions isActive={isActive}>
        Live
        <S.IconsWrapper>
          {isActive ? <Icon name="Filter" size="16" /> : <Icon name="DownArrow" size="16" />}
        </S.IconsWrapper>
      </S.LiveOptions>
    </S.MenuWrapper>
  );

  const multipleSelection = options.length > 3;

  return (
    <FloatingMenu Button={Header} position="left" closeOnSelect={false}>
      <ListTitle>Filter by status</ListTitle>
      <S.ChecksWrapper>
        <CheckGroup
          options={options}
          value={selectedValue}
          onChange={setQuery}
          selectAllOption={selectAllOption}
          multipleSelection={multipleSelection}
        />
      </S.ChecksWrapper>
    </FloatingMenu>
  );
};

export interface ILiveFilterProps {
  filterItems(pointer: string, filter: string): void;
  value: string;
  hasBasicStatus?: boolean;
  isSite?: boolean;
}

export default LiveFilter;
