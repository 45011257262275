import { getLang, getToken } from "../functions/utils";
import { FilterItemFromFilterEndPoint } from "../types/global";

type SimpleFilter = Array<unknown>;
type FilterWithSource = Array<{ id: number; source: string; label: string }>;
type FilterFromEndpoint = Record<string, FilterItemFromFilterEndPoint>;

// Return a RequestInit from node types
export function getOptions(
	body: Record<string, unknown>,
	langID?: number
): RequestInit {
	const lang = langID || getLang();

	return {
		method: "POST",
		mode: "cors",
		cache: "no-cache",
		headers: {
			"Content-Type": "application/json",
			lang: lang?.toString(),
			...getToken(),
		},
		redirect: "follow",
		referrerPolicy: "no-referrer",
		body: JSON.stringify(body),
	};
}

// Si es un array de números, es oldFormat
export function isArrayOfNumbers(arr?: Array<unknown>): arr is Array<number> {
	if (!arr) return false;
	return arr.every((item) => typeof item === "number");
}

export function isSimpleFilter(
	someFilter?: SimpleFilter | FilterWithSource | FilterFromEndpoint
): someFilter is Array<number> {
	return Array.isArray(someFilter) && isArrayOfNumbers(someFilter);
}

// TODO: Asegurar más este guard para que analice la estructura de `someFilter`
export function isFilterWithSource(
	someFilter?: SimpleFilter | FilterWithSource | FilterFromEndpoint
): someFilter is FilterWithSource {
	return Array.isArray(someFilter) && !isArrayOfNumbers(someFilter);
}

// TODO: Asegurar más este guard para que analice la estructura de `someFilter`
export function isFilterFromEndpoint(
	someFilter?: SimpleFilter | FilterWithSource | FilterFromEndpoint
): someFilter is FilterFromEndpoint {
	return !isSimpleFilter(someFilter) && !isFilterWithSource(someFilter);
}

export function getComplexFiltersAsURLParam(
	filters?: Record<string, Array<number>>
) {
	if (!filters) return undefined;
	return Object.keys(filters)
		.map((key) => `${key}:${filters[key]}`)
		.join(";");
}

export function parseFilterWithSource(filters?: FilterWithSource) {
	if (!filters) return undefined;

	const filtersReduced = filters
		? filters.reduce((acc, filterItem) => {
				const { source, id } = filterItem;
				// init si no existe...
				if (!acc[source]) acc[source] = [];
				// add...
				acc[source].push(id);
				return acc;
		  }, {} as Record<string, Array<number>>)
		: {};

	return Object.keys(filtersReduced)
		.map((key) => `${key}:${filtersReduced[key]}`)
		.join(";");
}

export function parseSimpleFilter(filters?: Array<number>) {
	return filters?.join(",");
}

export function parseFilterFromEndpoint(filters?: FilterFromEndpoint) {
	const filterWithSource: FilterWithSource = [];

	for (const source in filters) {
		const items = filters[source].items;

		items.forEach((item) => {
			filterWithSource.push({
				id: item.id,
				label: item.label,
				source: source,
			});
		});
	}

	return parseFilterWithSource(filterWithSource);
}

export function parseFilter(
	filter?: SimpleFilter | FilterWithSource | FilterFromEndpoint
) {
	if (isSimpleFilter(filter)) {
		return parseSimpleFilter(filter);
	}
	if (isFilterWithSource(filter)) {
		return parseFilterWithSource(filter);
	}
	if (isFilterFromEndpoint(filter)) {
		return parseFilterFromEndpoint(filter);
	}
	return undefined;
}

/**
 * Compute `related` with legacy support for the number and Array<number> value
 */
export function parseRelated(
	filter?: number | SimpleFilter | FilterFromEndpoint
) {
	if (typeof filter === "number") {
		return filter;
	}
	if (isSimpleFilter(filter)) {
		return parseSimpleFilter(filter);
	}
	if (isFilterWithSource(filter)) {
		return parseFilterWithSource(filter);
	}
	if (isFilterFromEndpoint(filter)) {
		return parseFilterFromEndpoint(filter);
	}
	return undefined;
}

export function isOperator(operator?: string) {
	return !!operator && ["or", "and"].includes(operator.toLowerCase());
}

/**
 * Compute `relations` with legacy support for the boolean value
 */
export function parseRelations(
	relations?: boolean | "off" | "simple" | "full"
) {
	// Boolean value: legacy
	if (typeof relations === "boolean") {
		return relations ? "simple" : "off";
	}

	// New values
	if (relations === "off" || relations === "simple" || relations === "full") {
		return relations;
	}

	// off...
	return "off";
}
