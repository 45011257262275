import React from "react";
import styled from "styled-components";
import ActionMenu from "@ax/components/ActionMenu";

const Field = styled.div`
  display: flex;
  width: 100%;
  height: ${(p) => p.theme.spacing.l};
  background-color: ${(p) => p.theme.color.interactiveBackground};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  border-radius: ${(p) => p.theme.radii.s};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  margin-top: ${(p) => p.theme.spacing.s};
  :hover {
    background-color: ${(p) => p.theme.colors.overlayHoverPrimary};
  }
`;

const IconWrapper = styled.div`
  height: ${(p) => p.theme.spacing.m};
  width: ${(p) => p.theme.spacing.m};
`;

const FileDataWrapper = styled.div`
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const FileName = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
`;

const FileData = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
`;

const TextFieldWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const ComponentIconWrapper = styled.div`
  background-color: ${(p) => p.theme.color.uiBackground03};
  border-radius: 4px;
  height: calc(${(p) => p.theme.spacing.s} * 2);
  width: calc(${(p) => p.theme.spacing.s} * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(p) => p.theme.spacing.xs};
`;

const StyledActionMenu = styled((props) => <ActionMenu {...props} />)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const Component = styled.span`
  color: ${(p) => p.theme.color.textHighEmphasis};
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${(p) => p.theme.spacing.l};
  background: ${(p) => p.theme.color.uiBackground02};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  margin-top: ${(p) => p.theme.spacing.s};
  padding: 0 ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radii.s};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  ${(p) => p.theme.textStyle.fieldLabel};
  text-align: left;
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.overlayHoverPrimary};
    ${StyledActionMenu as any} {
      opacity: 1;
    }
  }
`;

const Label = styled.span`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-left: ${(p) => p.theme.spacing.xs};
`;

const HelpText = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

export {
  Field,
  IconWrapper,
  FileDataWrapper,
  TextFieldWrapper,
  FileName,
  FileData,
  Component,
  ComponentIconWrapper,
  Label,
  StyledActionMenu,
  HelpText,
};
