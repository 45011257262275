// Types
import type {
	CategoryContentType,
	Component,
	DamDefaults,
	DataPack,
	DataPackCategory,
	Footer,
	Header,
	Languages,
	Menu,
	Module,
	ModuleCategories,
	PageContentType,
	SimpleContentType,
	Template,
	Themes,
	Translations,
} from "../../types/schemas";

const createComponentSchema = (schema: Component) => schema;

const createSimpleContentTypeSchema = (schema: SimpleContentType) => schema;
const createPageContentTypeSchema = (schema: PageContentType) => schema;
const createCategoryContentTypeSchema = (schema: CategoryContentType) => schema;

const createDamDefaultsSchema = (schema: DamDefaults) => schema;
const createDataPackCategorySchema = (schema: DataPackCategory) => schema;
const createDataPackSchema = (schema: DataPack) => schema;
const createFooterSchema = (schema: Footer) => schema;
const createHeaderSchema = (schema: Header) => schema;
const createLanguagesSchema = (schema: Languages): Languages => schema;
const createMenuSchema = (schema: Menu) => schema;
const createModuleCategoriesSchema = (schema: ModuleCategories) => schema;
const createModuleSchema = (schema: Module) => schema;
const createTemplateSchema = (schema: Template) => schema;
const createThemesSchema = (schema: Themes) => schema;
const createTranslationsSchema = (schema: Translations) => schema;

export {
	createCategoryContentTypeSchema,
	createComponentSchema,
	createDamDefaultsSchema,
	createDataPackCategorySchema,
	createDataPackSchema,
	createFooterSchema,
	createHeaderSchema,
	createLanguagesSchema,
	createMenuSchema,
	createModuleCategoriesSchema,
	createModuleSchema,
	createPageContentTypeSchema,
	createSimpleContentTypeSchema,
	createTemplateSchema,
	createThemesSchema,
	createTranslationsSchema,
};
