import React from "react";
import { Icon, Loader } from "@ax/components";

import * as S from "./style";

enum buttonStyles {
  SOLID = "solid",
  TEXT = "text",
  LINE = "line",
  INVERSE = "lineInverse",
}

const Button = (props: IButtonProps): JSX.Element => {
  const { children, type, disabled, icon, buttonStyle, onClick, className, loader, backIcon } = props;

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick !== undefined) {
      e.preventDefault();
      onClick(e);
    }
  };

  let iconWrapper = icon ? <Icon name={icon} /> : loader ? <Loader name={loader} /> : <></>;
  let iconBackWrapper = backIcon ? <Icon name={backIcon} /> : <></>;

  const buttonContent = (
    <S.Label icon={icon || loader} backIcon={backIcon}>
      {iconWrapper}
      <span>{children}</span>
      {iconBackWrapper}
    </S.Label>
  );

  const isInverse = buttonStyle === "lineInverse";

  switch (buttonStyle) {
    case buttonStyles.TEXT:
      return (
        <S.TextButton
          data-testid="button-text"
          className={className}
          type={type}
          disabled={disabled}
          onClick={handleOnClick}
        >
          {buttonContent}
        </S.TextButton>
      );
    case buttonStyles.LINE:
    case buttonStyles.INVERSE:
      return (
        <S.LineButton
          data-testid="button-line-inverse"
          className={className}
          type={type}
          disabled={disabled}
          onClick={handleOnClick}
          isInverse={isInverse}
        >
          {buttonContent}
        </S.LineButton>
      );
    default:
      return (
        <S.Button
          data-testid="button-default"
          className={className}
          type={type}
          disabled={disabled}
          onClick={handleOnClick}
        >
          {buttonContent}
        </S.Button>
      );
  }
};

export interface IButtonProps {
  children: JSX.Element | string;
  type: "button" | "submit" | "reset" | undefined;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: string;
  loader?: string;
  disabled?: boolean;
  buttonStyle?: "solid" | "text" | "line" | "lineInverse" | undefined; // TODO: investigar si podemos hacer esto más elegante y aprovechar el enum buttonStyles
  className?: string;
  backIcon?: string;
}

export default Button;
