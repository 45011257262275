import React, { forwardRef } from "react";

import * as S from "./style";

const ErrorToast = (props: IProps, ref: any) => {
  const { size } = props;

  return <S.ErrorWrapper id="error" data-testid="error-wrapper" size={size} ref={ref} />;
};

export interface IProps {
  size?: string;
  ref?: any;
}

export default forwardRef(ErrorToast);
