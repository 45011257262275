import { themes } from "components";

export default {
  schemaType: "page",
  displayName: "Page",
  component: "Page",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "",
          type: "TranslateButton",
          key: "translate",
        },
        {
          title: "Title",
          type: "TextField",
          key: "title",
        },
        {
          title: "Template",
          key: "template",
          type: "template",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          type: "UniqueCheck",
          key: "isHome",
          options: [
            {
              title: "Set as home",
            },
          ],
        },
        {
          title: "Parent",
          type: "AsyncSelect",
          entity: "pages",
          key: "parent",
          options: { excludeDetailPages: true },
        },
        {
          title: "Slug",
          type: "TextField",
          key: "slug",
        },
        {
          title: "Template",
          key: "template",
          type: "template",
        },
        {
          title: "Page Options",
          type: "FieldGroup",
          key: "pageOptions",
          collapsed: false,
          fields: [
            {
              title: "Theme",
              type: "Select",
              key: "theme",
              options: themes,
              helptext: "It affects the whole page: Header, Content, and footer.",
            },
            {
              title: "Customize header and footer themes",
              type: "ConditionalField",
              key: "customizeThemes",
              mandatory: true,
              defaultValue: false,
              options: [
                {
                  value: true,
                  title: "Yes",
                  name: "Yes",
                },
                {
                  value: false,
                  title: "No",
                  name: "No",
                },
              ],
              fields: [
                {
                  title: "Header Theme",
                  type: "Select",
                  key: "headerTheme",
                  options: themes,
                  condition: true,
                },
                {
                  title: "Footer Theme",
                  type: "Select",
                  key: "footerTheme",
                  options: themes,
                  condition: true,
                },
              ],
            },
          ],
        },
        {
          type: "IntegrationsField",
          key: "integrations",
        },
      ],
    },
    {
      title: "SEO & Analytics",
      fields: [
        {
          title: "SEO Data",
          type: "FieldGroup",
          key: "seoData",
          collapsed: true,
          fields: [
            {
              title: "",
              type: "SummaryButton",
              key: "summary",
            },
            {
              title: "Meta title",
              type: "TextField",
              key: "metaTitle",
            },
            {
              title: "Meta description",
              type: "TextArea",
              key: "metaDescription",
            },
            {
              title: "Keywords",
              type: "TagsField",
              key: "metaKeywords",
            },
            {
              title: "Canonical URL",
              type: "TextField",
              key: "canonicalURL",
            },
            {
              title: "Meta robots index",
              type: "RadioGroup",
              key: "isIndexed",
              options: [
                {
                  value: true,
                  title: "Index",
                  name: "index",
                },
                {
                  value: false,
                  title: "No index",
                  name: "noindex",
                },
              ],
            },
            {
              title: "Meta robots follow",
              type: "RadioGroup",
              key: "follow",
              options: [
                {
                  value: true,
                  title: "Follow",
                  name: "follow",
                },
                {
                  value: false,
                  title: "No follow",
                  name: "nofollow",
                },
              ],
            },
            {
              title: "Meta robots advanced",
              type: "CheckGroup",
              key: "metasAdvanced",
              options: [
                {
                  value: "noimageindex",
                  title: "No image index",
                  name: "noimage",
                },
                {
                  value: "nosnippet",
                  title: "No snippet",
                  name: "nosnippet",
                },
                {
                  value: "noodp",
                  title: "No ODP",
                  name: "noodp",
                },
                {
                  value: "noarchive",
                  title: "No archive",
                  name: "noarchive",
                },
                {
                  value: "noTranslate",
                  title: "No translate",
                  name: "noTranslate",
                },
              ],
            },
          ],
        },
        {
          title: "Analytics Data Layer",
          type: "FieldGroup",
          key: "analytics",
          collapsed: true,
          fields: [
            {
              type: "AnalyticsField",
              key: "dimensions",
              collapsed: true,
            },
          ],
        },
        {
          title: "Social Media",
          type: "FieldGroup",
          key: "socialShare",
          collapsed: true,
          fields: [
            {
              title: "Title",
              type: "TextField",
              key: "socialTitle",
            },
            {
              title: "Description",
              type: "TextField",
              key: "socialDescription",
            },
            {
              title: "Image",
              type: "ImageField",
              key: "socialImage",
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "Page",
    isHome: false,
    slug: "new-page",
    title: "New Page",
    headerConfig: "{}",
    footerConfig: "{}",
    liveStatus: { id: 1 },
    template: {},
    metaTitle: "",
    metaDescription: "",
    canonicalURL: "",
    isIndexed: true,
    follow: true,
    metasAdvanced: "",
    socialTitle: "",
    socialDescription: "",
    socialImage: {},
    dimensions: {},
    integrations: null,
    theme: null,
    customizeThemes: false,
    headerTheme: null,
    footerTheme: null,
  },
};
