import { Dispatch } from "redux";
import differenceInSeconds from "date-fns/differenceInSeconds";

import { isReqOk } from "@ax/helpers";
import { languages, global } from "@ax/api";
import { IRootState } from "@ax/types";

import history from "../../routes/history";

import {
  SET_ERROR,
  RESET_ERROR,
  SET_IS_LOADING,
  SET_IS_SAVING,
  IS_LOGGING_IN,
  SET_TOKEN,
  LOGOUT,
  SET_LANGUAGE,
  SET_GLOBAL_LANGUAGES,
  SET_GLOBAL_SETTINGS,
  SET_USER,
  SET_SESSION_STARTED_AT,
  SET_HAS_ANIMATION,
} from "./constants";

import {
  ISetIsLoading,
  ISetIsSaving,
  ISetErrorAction,
  IIsLoggingInAction,
  ISetTokenAction,
  ILogoutAction,
  ISetLanguageAction,
  ISetGlobalLanguages,
  ISetGlobalSettings,
  ISetUserAction,
  ISetSessionStartedAtAction,
  ISetHasAnimation,
} from "./interfaces";
import { IError, IUser } from "./reducer";

function setIsLoading(isLoading: boolean): ISetIsLoading {
  return { type: SET_IS_LOADING, payload: { isLoading } };
}

function setIsSaving(isSaving: boolean): ISetIsSaving {
  return { type: SET_IS_SAVING, payload: { isSaving } };
}

function setHasAnimation(hasAnimation: boolean): ISetHasAnimation {
  return { type: SET_HAS_ANIMATION, payload: { hasAnimation } };
}

const resetStateValues = (dispatch: Dispatch) => {
  dispatch(setIsLoading(true));
  dispatch(setIsSaving(false));
};

function setHistoryPush(path: string, isEditor?: boolean): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      if (isEditor) {
        resetStateValues(dispatch);
      }
      history.push(path);
    } catch (e) {
      console.log("Error", e);
    }
  };
}

function setIsLogging(isLoggingIn: boolean): IIsLoggingInAction {
  return { type: IS_LOGGING_IN, payload: { isLoggingIn } };
}

function setToken(token: string): ISetTokenAction {
  return { type: SET_TOKEN, payload: { token } };
}

function logout(): ILogoutAction {
  const listConfig = localStorage.getItem("sitesConfig") || "";
  localStorage.clear();
  localStorage.setItem("sitesConfig", listConfig);
  return { type: LOGOUT, payload: { token: "" } };
}

function setLanguage(lang: { locale: string; id: number }): ISetLanguageAction {
  localStorage.setItem("langID", JSON.stringify(lang.id));
  return { type: SET_LANGUAGE, payload: { lang } };
}

function setGlobalLanguages(globalLangs: any[]): ISetGlobalLanguages {
  return { type: SET_GLOBAL_LANGUAGES, payload: { globalLangs } };
}

function setGlobalSettings(globalSettings: any[]): ISetGlobalSettings {
  return { type: SET_GLOBAL_SETTINGS, payload: { globalSettings } };
}

function setUser(user: IUser): ISetUserAction {
  return { type: SET_USER, payload: { user } };
}

function setError(error: IError): ISetErrorAction {
  return { type: SET_ERROR, payload: { ...error } };
}

function resetError(): ISetErrorAction {
  (window as any).handleErrorClick = undefined;
  return { type: RESET_ERROR, payload: { code: undefined, text: "" } };
}

function setSessionStartedAt(sessionStartedAt: Date): ISetSessionStartedAtAction {
  return { type: SET_SESSION_STARTED_AT, payload: { sessionStartedAt } };
}

function handleError(response: any, isMultiple = false, msg?: string): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    if (typeof response === "string") {
      dispatch(setError({ text: response }));
      return;
    }

    const {
      data: { code, message },
      btnText,
      actionsBelow,
      text,
      btnAction,
    } = response;

    const firstMsg = Array.isArray(message) && message[0] ? message[0].error : message || text;

    const error = {
      code,
      text: msg ? msg : firstMsg,
      btnText,
      actionsBelow,
      btnAction,
      subErrors: isMultiple ? message : [],
    };

    dispatch(setError(error));
  };
}

function login(email: string, password: string, rememberMe: boolean): (dispatch: Dispatch) => Promise<boolean> {
  return async (dispatch) => {
    dispatch(setIsLogging(true));
    const loginResponse: any = await global.login(email, password);
    dispatch(setIsLogging(false));
    switch (loginResponse?.status) {
      case 200: {
        const {
          data: { token },
        } = loginResponse;
        const langResponse: { status: number; data: any } = await languages.getLanguages(token);
        isReqOk(langResponse.status) && dispatch(setGlobalLanguages(langResponse.data.items));
        dispatch(setToken(loginResponse.data.token));
        dispatch(setSessionStartedAt(new Date()));
        dispatch(setHasAnimation(true));
        if (rememberMe) {
          const user: any = { token: loginResponse.data.token };
          localStorage.setItem("user", JSON.stringify(user));
        }
        return true;
      }
      case 403: {
        const error = {
          ...loginResponse,
          btnText: "Lost your password?",
          actionsBelow: true,
        };
        handleError(error)(dispatch);
        return false;
      }
      case undefined: {
        const error = {
          data: {
            code: 500,
            message: "An error has occurred. Please contact your system administrator.",
          }
        };
        handleError(error)(dispatch);
        return false;
      }
      default:
        handleError(loginResponse)(dispatch);
        return false;
    }
  };
}

function getGlobalSettings(): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const response: { status: number; data: any } = await global.getSettings();

      if (isReqOk(response?.status)) {
        dispatch(setGlobalSettings(response.data));
      } else {
        console.log("Error en getSettings");
      }
    } catch (e) {
      console.log(e);
    }
  };
}

function checkUserSession(): (dispatch: Dispatch, getState: () => IRootState) => Promise<void> {
  return async (dispatch, getState) => {
    const {
      app: { sessionStartedAt },
    } = getState();

    const timeSinceSessionStart = !!sessionStartedAt && differenceInSeconds(new Date(), new Date(sessionStartedAt));
    const eightHoursInSeconds = 8 * 60 * 60;
    const isSessionFinished = !!timeSinceSessionStart && timeSinceSessionStart > eightHoursInSeconds;
    if (!timeSinceSessionStart || isSessionFinished) {
      localStorage.removeItem("persist:root");
      dispatch(setSessionStartedAt(new Date()));
    }
  };
}

export {
  setError,
  resetError,
  handleError,
  setIsLoading,
  setIsSaving,
  setHistoryPush,
  setIsLogging,
  setToken,
  logout,
  setLanguage,
  setGlobalLanguages,
  setGlobalSettings,
  login,
  getGlobalSettings,
  setUser,
  checkUserSession,
  setHasAnimation,
};
