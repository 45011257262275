import React, { memo, useRef, useState } from "react";
import { connect } from "react-redux";

import { galleryActions } from "@ax/containers/Gallery";
import { IImage, IRootState } from "@ax/types";
import { Icon, DragAndDrop, ProgressBar } from "@ax/components";

import * as S from "./style";

const GalleryDragAndDrop = (props: IProps) => {
  const {
    isImageSelected,
    validFormats,
    site,
    allowUpload,
    refreshImages,
    uploadError,
    isUploading,
    isSuccess,
    isError,
    errorMsg,
    uploadImage,
    selectImage,
  } = props;
  const validExtensions = validFormats.map((format) => `.${format}`).join(",");

  const filesInputRef = useRef<HTMLInputElement>(null);
  const filesButtonRef = useRef<HTMLButtonElement>(null);
  const [inDropZone, setInDropZone] = useState(false);
  const [dropDepth, setDropDepth] = useState(0);
  const [uploadingState, setUploadingState] = useState({ total: 0, ready: 0 });

  const handleDragEnter = () => {
    setDropDepth((depth) => depth + 1);
  };

  const handleDragLeave = () => {
    setDropDepth((depth) => depth - 1);
    if (dropDepth > 1) return;
    setInDropZone(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.dropEffect = "copy";
    setInDropZone(true);
  };

  const checkType = (type: string) => {
    for (const i in validFormats) {
      if (type.includes(validFormats[i])) return true;
    }
    return false;
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    const files = Array.from(e.dataTransfer.files);
    e.dataTransfer.clearData();
    await uploadFiles(files);
    setDropDepth(0);
  };

  const handleFilesUpload = (e: any) => {
    const files: File[] = Array.from(e.currentTarget.files);
    uploadFiles(files);
  };

  const uploadFiles = async (files: File[]) => {
    try {
      if (!files.every((file) => checkType(file.type))) {
        uploadError(true, "Invalid format");
        return;
      }

      setUploadingState({ total: files.length, ready: 0 });
      let lastImage;
      while (files.length) {
        const file = files.shift();
        lastImage = file && (await uploadImage(file, site));
        setUploadingState((state) => ({ total: state.total, ready: state.ready + 1 }));
      }

      setInDropZone(false);
      await refreshImages();
      lastImage && selectImage(lastImage);
      setUploadingState({ total: 0, ready: 0 });
    } catch (error) {
      console.log(error);
    }
  };

  const uploading = isUploading || uploadingState.total > uploadingState.ready;
  const success = isSuccess && uploadingState.total === uploadingState.ready;
  const uploadPercentage = (uploadingState.ready * 100) / uploadingState.total;

  const handleTryAgain = () => {
    uploadError(false);
    setInDropZone(false);
  };

  const handleFileClick = () => {
    filesInputRef && filesInputRef.current && filesInputRef.current.click();
  };

  const errorWrapper = errorMsg ? <S.ErrorMsg>{errorMsg}</S.ErrorMsg> : null;

  const renderDragAndDrop = () => (
    <DragAndDrop
      data-testid="drag-drop-wrapper"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      validFormats={validFormats}
    >
      <S.StatusWrapper onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
        <S.DragStatus onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
          <S.DragIcon>
            <Icon name="image" size="36" />
          </S.DragIcon>
          <S.DragTitle>Drag your file here</S.DragTitle>
          <S.DragSubtitle>or</S.DragSubtitle>
          <S.FilesInput type="file" ref={filesInputRef} multiple accept={validExtensions} onInput={handleFilesUpload} />
          <S.FilesButton ref={filesButtonRef} type="button" buttonStyle="line" onClick={handleFileClick}>
            Select files
          </S.FilesButton>
          <S.DragSubtitle>Valid formats: {validFormats.join(", ")}</S.DragSubtitle>
          <S.DragSubtitle>Max. size: 10MB</S.DragSubtitle>
        </S.DragStatus>
        <S.DragOverStatus onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
          <S.DragIcon>
            <Icon name="success" size="36" />
          </S.DragIcon>
          <S.DragTitle>Drop your file</S.DragTitle>
          <S.DragSubtitle>Valid formats: {validFormats.join(", ")}</S.DragSubtitle>
          <S.DragSubtitle>Max. size: 10MB</S.DragSubtitle>
        </S.DragOverStatus>
      </S.StatusWrapper>
    </DragAndDrop>
  );

  const renderPlaceholder = () => (
    <S.StatusWrapper>
      <S.DragIcon>
        <Icon name="image" size="36" />
      </S.DragIcon>
      <S.DragTitle>Select an image to see details</S.DragTitle>
    </S.StatusWrapper>
  );

  return (
    <S.Wrapper hidden={isImageSelected} data-testid="gallery-drag-drop-wrapper">
      <S.DragAndDropWrapper inDropZone={inDropZone} uploading={uploading} success={success} error={isError}>
        {allowUpload ? renderDragAndDrop() : renderPlaceholder()}
      </S.DragAndDropWrapper>
      <S.UploadingWrapper inDropZone={inDropZone} uploading={uploading} success={success} error={isError}>
        <S.StatusWrapper>
          <S.UploadingStatus>
            <S.DragIcon>
              <Icon name="image" size="36" />
            </S.DragIcon>
            <S.ProgressBar>
              <ProgressBar percentage={uploadPercentage} />
            </S.ProgressBar>
            <S.DragTitle>Uploading...</S.DragTitle>
          </S.UploadingStatus>
          <S.SuccessStatus>
            <S.DragIcon>
              <Icon name="success" size="36" />
            </S.DragIcon>
            <S.DragTitle>Image loaded!</S.DragTitle>
          </S.SuccessStatus>
          <S.ErrorStatus>
            <S.DragIcon>
              <Icon name="alert" size="36" />
            </S.DragIcon>
            <S.DragTitle>Error uploading image</S.DragTitle>
            {errorWrapper}
            <S.StyledButton type="button" buttonStyle="text" onClick={handleTryAgain}>
              TRY AGAIN
            </S.StyledButton>
          </S.ErrorStatus>
        </S.StatusWrapper>
      </S.UploadingWrapper>
    </S.Wrapper>
  );
};

export interface IGalleryDragAndDropProps {
  isImageSelected: boolean;
  validFormats: string[];
  site: number | "global";
  allowUpload: boolean;
  refreshImages: () => Promise<void>;
  isUploading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMsg: string;
}

const mapStateToProps = (state: IRootState) => ({
  isUploading: state.gallery.isUploading,
  isSuccess: state.gallery.isSuccess,
  isError: state.gallery.isError,
  errorMsg: state.gallery.errorMsg,
});

export interface IDispatchProps {
  selectImage(item: IImage | null): void;
  uploadError: (error: boolean, msg?: string) => Promise<void>;
  uploadImage: (imageFiles: File | File[], site: number | string) => Promise<IImage>;
}

const mapDispatchToProps = {
  uploadError: galleryActions.uploadError,
  uploadImage: galleryActions.uploadImage,
};

type IProps = IGalleryDragAndDropProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(memo(GalleryDragAndDrop));
