import styled from "styled-components";
import { ActionMenu } from "@ax/components";

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.p`
  ${(p) => p.theme.textStyle.headingS};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const StyledActionMenu = styled(ActionMenu)`
  width: 32px;
  position: absolute;
  right: 0;
  top: 0;
`;

export { HeaderWrapper, Title, StyledActionMenu };
