import { IFilesFolder, IFolderTree } from "@ax/types";
import {
  SET_BREADCRUMB,
  SET_CURRENT_FOLDER,
  SET_CURRENT_FOLDER_CONTENT,
  SET_DISPLAY_MODE,
  SET_FOLDERS_TREE,
  SET_IS_UPLOADING,
  SET_SELECTED_TAB,
  SET_UPLOAD_ERROR,
  SET_UPLOAD_SUCCESS,
} from "./constants";

export interface IFileDriveState {
  currentFolderContent: IFilesFolder | null;
  currentFolderID: number | null;
  breadcrumb: IFolderTree[];
  foldersTree: IFolderTree[];
  isUploading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMsg: string;
  displayMode: "grid" | "list";
  selectedTab: "local" | "global";
}

export const initialState: IFileDriveState = {
  currentFolderContent: null,
  currentFolderID: null,
  breadcrumb: [],
  foldersTree: [],
  isUploading: false,
  isSuccess: false,
  isError: false,
  errorMsg: "",
  displayMode: "grid",
  selectedTab: "local",
};

export function reducer(state = initialState, action: any): IFileDriveState {
  switch (action.type) {
    case SET_CURRENT_FOLDER_CONTENT:
    case SET_CURRENT_FOLDER:
    case SET_FOLDERS_TREE:
    case SET_BREADCRUMB:
    case SET_IS_UPLOADING:
    case SET_UPLOAD_SUCCESS:
    case SET_UPLOAD_ERROR:
    case SET_DISPLAY_MODE:
    case SET_SELECTED_TAB:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as fileDriveInitialState, reducer as fileDriveReducer };
