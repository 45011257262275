import React from "react";

import { Icon, FloatingMenu, ListTitle, ListItem } from "@ax/components";

import * as S from "./style";

const DateFilter = (props: IDateFilterProps): JSX.Element => {
  const { sortItems, sortedState } = props;
  const { isAscending, sortedByDate } = sortedState;
  const sortByModifiedDate = (isAscending: boolean) => sortItems("date", isAscending);

  const sortOldestModifiedDate = () => sortByModifiedDate(true);
  const sortMostRecentModifiedDate = () => sortByModifiedDate(false);

  const SortedStateArrow = () =>
    isAscending ? <Icon name="FullArrowUp" size="16" /> : <Icon name="FullArrowDown" size="16" />;

  const Header = () => (
    <S.Date isActive={sortedByDate} data-testid="date-filter">
      Date
      <S.IconsWrapper>
        {sortedByDate ? (
          <SortedStateArrow />
        ) : (
          <S.InteractiveArrow data-testid="date-filter-interactive-arrow">
            <Icon name="DownArrow" size="16" />
          </S.InteractiveArrow>
        )}
      </S.IconsWrapper>
    </S.Date>
  );

  return (
    <FloatingMenu Button={Header} position="center">
      <ListTitle>Sort by creation date</ListTitle>
      <ListItem isSelected={sortedByDate && !isAscending} onClick={sortMostRecentModifiedDate}>
        Most recent
      </ListItem>
      <ListItem isSelected={sortedByDate && isAscending} onClick={sortOldestModifiedDate}>
        Oldest
      </ListItem>
    </FloatingMenu>
  );
};

export interface IDateFilterProps {
  sortedState: any;
  sortItems(orderPointer: string, isAscendent: boolean): any;
}

export default DateFilter;
