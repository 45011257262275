import React from "react";
import { BulkSelectionOptions } from "@ax/components";
import TableHeader from "./TableHeader";

const BulkHeader = (props: IProps): JSX.Element => {
  const {
    showBulk,
    checkState,
    selectItems,
    selectAllItems,
    totalItems,
    isScrolling,
    filterItems,
    filterValues,
    sortItems,
    sortedListStatus,
    bulkActions,
  } = props;

  return showBulk ? (
    <BulkSelectionOptions
      checkState={checkState}
      actions={bulkActions}
      selectItems={selectItems}
      totalItems={totalItems}
    />
  ) : (
    <TableHeader
      totalItems={totalItems}
      selectAllItems={selectAllItems}
      isScrolling={isScrolling}
      filterItems={filterItems}
      filterValues={filterValues}
      sortItems={sortItems}
      sortedListStatus={sortedListStatus}
    />
  );
};

interface IProps {
  showBulk: boolean;
  checkState: any;
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  isScrolling: boolean;
  filterItems: (filterPointer: string, filtersSelected: string) => void;
  filterValues: any;
  sortItems: (orderPointer: string, isAscending: boolean) => void;
  sortedListStatus: { isAscending: boolean; sortedByTitle: boolean };
  bulkActions: { icon: string; text: string; action: () => void }[];
}

export default BulkHeader;
