import React from "react";

import { Icon, FloatingMenu, ListTitle, ListItem } from "@ax/components";

import * as S from "./style";

const SortBy = ({ sortItems, sortedState }: ISortByProps): JSX.Element => {
  const { isAscending, sortedByName, sortedByDate, sortedBySize } = sortedState;
  const sortBy = (pointer: string, isAscending: boolean) => sortItems(pointer, isAscending);
  const sortAscendingName = () => sortBy("name", true);
  const sortDescendingName = () => sortBy("name", false);
  const sortAscendingDate = () => sortBy("date", true);
  const sortDescendingDate = () => sortBy("date", false);
  const sortAscendingSize = () => sortBy("size", true);
  const sortDescendingSize = () => sortBy("size", false);

  const SortedStateArrow = () =>
    isAscending ? <Icon name="FullArrowUp" size="16" /> : <Icon name="FullArrowDown" size="16" />;
  const isActive = sortedByName || sortedByDate || sortedBySize;

  const Header = () => (
    <S.SortBy data-testid="sortby-wrapper" isActive={isActive}>
      Sort by
      <S.IconsWrapper>
        {isActive && <SortedStateArrow />}
        <S.InteractiveArrow>
          <Icon name="DownArrow" size="16" />
        </S.InteractiveArrow>
      </S.IconsWrapper>
    </S.SortBy>
  );

  return (
    <FloatingMenu Button={Header} position="center">
      <ListTitle>Name Sorting</ListTitle>
      <ListItem isSelected={sortedByName && isAscending} onClick={sortAscendingName}>Ascendent</ListItem>
      <ListItem isSelected={sortedByName && !isAscending} onClick={sortDescendingName}>Descendent</ListItem>
      <ListTitle>Date sorting</ListTitle>
      <ListItem isSelected={sortedByDate && !isAscending} onClick={sortDescendingDate}>Newest first</ListItem>
      <ListItem isSelected={sortedByDate && isAscending} onClick={sortAscendingDate}>Oldest first</ListItem>
      <ListTitle>Size sorting</ListTitle>
      <ListItem isSelected={sortedBySize && isAscending} onClick={sortAscendingSize}>Ascendent</ListItem>
      <ListItem isSelected={sortedBySize && !isAscending} onClick={sortDescendingSize}>Descendent</ListItem>
    </FloatingMenu>
  );
};

export interface ISortByProps {
  sortedState: ISortedListStatus;
  sortItems(orderPointer: string, isAscendent: boolean): void;
}

interface ISortedListStatus {
  isAscending: boolean;
  sortedByName: boolean;
  sortedByDate: boolean;
  sortedBySize: boolean;
}

export default SortBy;
