import styled from "styled-components";

import { Cell, Row } from "@ax/components/TableList/TableItem/style";
import { ActionMenu } from "@ax/components";

const CheckCell = styled(Cell)`
  padding-left: ${(p) => p.theme.spacing.m};
  width: 32px;
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
  }
`;

const NameCell = styled(Cell)<{ clickable: boolean }>`
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
  width: 40%;
`;

const ActionsCell = styled(Cell)`
  width: 125px;
`;

const TransCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiXS};
  width: 10%;
  align-items: center;
`;

const CodeCell = styled(Cell)<{ clickable: boolean }>`
  ${(p) => p.theme.textStyle.uiXS};
  pointer-events: ${(p) => (p.clickable ? "auto" : "none")};
  width: 40%;
`;

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const CategoryRow = styled(Row)`
  &:hover {
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
`;

const FlagsWrapper = styled.div`
  svg {
    margin-right: 2px;
    margin-bottom: -3px; // TODO
  }
`;

export { CheckCell, NameCell, ActionsCell, TransCell, CodeCell, StyledActionMenu, CategoryRow, FlagsWrapper };
